<template>
    <VModal name="playlistMaximumModal" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
        <div class="vm-header">
            <div class="vm-header-left">
            </div>
            <div class="vm-header-center">
            </div>
            <div class="vm-header-right">
                <img src="@/assets/ic_nav_close.svg" alt="" @click="dismissDialog">
            </div>
        </div>
        <div class="vm-content">
            <img src="@/assets/notification/ic_notification_warning.svg" alt="">
            <h3>Maximale Anzahl an Playlists erreicht!</h3>
            <p>Ersetze deine bestehende Playlist oder werde Premium-Mitglied, um unbegrenzt viele Playlists speichern zu können.</p>
            <v-btn type="button" class="button button--primary button--small">Playlist ersetzen</v-btn>
            <v-btn type="button" class="button button--secondary button--small" @click="buyPremiumPackage">Premium kostenfrei testen</v-btn>
        </div>
    </VModal>
</template>

<script>
export default {
    name: 'PlaylistMaximumModal',
    methods: {
        dismissDialog() {
            this.$modal.hide('playlistMaximumModal');
        },
        buyPremiumPackage() {
            this.$modal.hide('premiumHint');
            this.$router.push("/settings/premium");
        },
    }
}
</script>

<style lang="scss" scoped>
    .vm-content {
        padding-top: 0;

        img {
            margin-top: 0;
        }
    }
</style>
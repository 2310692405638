<template>
  <div class="playlist__countdown">
    <div class="deckslide__header">
      <div class="deckslide__header__left">
        <img src="@/assets/ic_nav_info_white.svg" alt="" @click="openInfoModal">
      </div>
      <div class="deckslide__header__center">
        Workout
      </div>
      <div class="deckslide__header__right">
        <img @click="cancelPlaylist"  src="@/assets/ic_nav_close_white.svg" alt="">
      </div>
    </div>

    <div class="playlist__countdown__countdown">
      <div class="playlist__countdown__countdown__time">
        {{ countdown }}
      </div>
    </div>

    <div v-if="firstExercise" class="playlist__countdown__teaser">
      Mach dich bereit! Dein Workout startet mit:
      <div class="playlist__countdown__teaser__title">
        {{ firstExercise.title }}
      </div>
      <img v-show="firstExercise.preview_image_url" :src="'https://app.sportlerplus.com/scaled/800/' + firstExercise.preview_image_url" alt="">
    </div>

    <!-- BG IMAGE -->
    <div v-if="(homePageInfo && homePageInfo.image_url)"  class="deckslide__background">
        <img :src="'https://app.sportlerplus.com/scaled/800/'+ homePageInfo.image_url" />
    </div>
    
    <ExerciseInfoModal :exercise="firstExercise" @closeInfoModal=closeInfoModal></ExerciseInfoModal>
  </div>
</template>

<script>
import ExerciseInfoModal from "@/components/ExerciseInfoModal.vue";

export default {
    name: 'PlaylistCountdown',
    components: { ExerciseInfoModal },
    data() {
        return {
            countdown: 5,
            infoModalOpen: false,
            firstExercise: null,
            countdownInterval: null,
            homePageInfo: null
        }
    },
    methods: {
        init() {
            this.$store.dispatch('fetchHomePage').then( () => {
                this.homePageInfo = this.$store.getters.getHomePageInfo;
            });
            let workoutSource = this.$store.getters.getWorkoutSource;
            this.firstExercise = workoutSource.generatedPlaylist[0];
            this.playlistCountdown(() => {
                this.$router.push("/playlist/play");
            });
        },
        openInfoModal() {
            this.infoModalOpen = true;
            this.$modal.show('exerciseInfo');
        },
        closeInfoModal() {
            this.infoModalOpen = false;
        },
        playlistCountdown(callback) {
            this.countdownInterval = setInterval(() => {
                if (!this.infoModalOpen) {
                    if (this.countdown-- <= 1) {
                        clearInterval(this.countdownInterval);
                        callback();
                    }
                }
            }, 1000);
        },
        cancelPlaylist() {
            if (this.countdownInterval) {
                clearInterval(this.countdownInterval);
            }
            this.$router.go(-1);
        }
    },
    mounted() {
        this.init();
    },
    beforeDestroy() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }
    }
}
</script>

<style lang="scss">
  .playlist__countdown {
    position: relative;
    display: flex;
    flex-direction: column;
    background: $colorBlack;
    z-index: 1;
    flex: 1;
    padding: 0 20px 4px;

    &__countdown {
      text-align: center;
      margin: auto 0;

      &__time {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
        padding-bottom: 8px;
        font-size: 64px;
        letter-spacing: -1.33px;
        font-weight: 900;
        color: $colorWhite;
        background: url('../assets/countdown.png');
        background-size: cover;
        margin: 16px 0 6px;
        animation: pulse infinite 1s ;

        @media screen and (orientation: landscape) and (max-width: 767px) {
          width: 100px;
          height: 100px;
          padding-bottom: 4px;
          font-size: 32px;
          margin: 0;
        }
      }

      h1 {
        color: $colorWhite;
        margin-bottom: 32px;

        @media screen and (orientation: landscape) and (max-width: 767px) {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 8px;
        }
      }
    }

    &__teaser {
      font-size: 11px;
      letter-spacing: -0.23px;
      font-weight: 700;
      color: rgba($colorWhite, .24);
      text-align: center;

      &__title {
        font-size: 24px;
        letter-spacing: -0.42px;
        font-weight: 700;
        color: $colorWhite;
        margin-top: 4px;

        @media screen and (orientation: landscape) and (max-width: 767px) {
          margin-top: 0;
          font-size: 18px;
        }
      }

      img {
        width: 100%;
        max-width: 260px;
        height: 140px;
        object-fit: cover;
        border-radius: 4px;
        margin: 16px 0;

        @media screen and (orientation: landscape) and (max-width: 767px) {
          max-width: 130px;
          height: 70px;
          margin: 8px 0;
        }

        @media screen and (min-width: 768px) {
          max-width: 400px;
          max-height: 200px;
          height: auto;
        }
      }
    }
  }

  @keyframes pulse {
    0%   { transform: scale(1) }
    50%  { transform: scale(1.08) }
    100% { transform: scale(1) }
  }
</style>
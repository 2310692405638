<template>
  <div class="fitnessplan">

    <!-- TABS -->
    <div class="tabs tabs--fullwidth">
      <div class="tabs__content">
        <v-btn v-if="hasDateFilter" @click="removeDateFilter" text v-ripple="false">Als Nächstes</v-btn>
        <v-btn v-else class="is-active" text v-ripple="false">Als Nächstes</v-btn>
        <v-btn to="/fitnessplan/calendar" text v-ripple="false">Kalender</v-btn>
        <v-btn to="/fitnessplan/challenges" text v-ripple="false">Challenges</v-btn>
      </div>
    </div>

    <!-- Calendar Navigation -->
    <div v-if="hasDateFilter" class="calendar-navigation">
        <div class="calendar-navigation__content">
            <router-link :to="'/fitnessplan?date=' + prevDate"><div class="calendar-navigation__left">
                <img src="@/assets/ic_nav_back.svg">
            </div></router-link> 
            <div class="calendar-navigation__center">
                <span>{{ displayDate }}</span>
            </div>
            <router-link :to="'/fitnessplan?date=' + nextDate"><div class="calendar-navigation__right">
                <img src="@/assets/ic_nav_next.svg">
            </div></router-link>
        </div>
    </div>

    <!-- Empty State -->
    <div v-if="filteredCalendar == 0" class="empty-content">
        <div class="empty-content__title">Kein Training vorhanden</div>
        <p>Worauf wartest du noch? Füge jetzt deine nächste Trainingseinheit hinzu.</p>
        <v-btn type="button" :to='"/fitnessplan/create?initialDate=" + selectedDate' class="button button--primary button--small button--icon"><img src="@/assets/ic_plus_white.svg">Training hinzufügen</v-btn>
    </div>

    <!-- CTA + Entries -->
    <v-btn type="button" v-else :to="'/fitnessplan/create?initialDate=' + selectedDate" class="button button--primary button--small button--icon"><img src="@/assets/ic_plus_white.svg">Training hinzufügen</v-btn>
        <VueLoading :active.sync="showLoadingIndicator" :can-cancel="true"></VueLoading>
    <div v-for="(event, i) in filteredCalendar" v-bind:key="event.id">
        <div class="section__title">
            <h4 v-if="i == 0 || (i > 0 && (dateOnly(event.startDate) != dateOnly(filteredCalendar[i - 1].startDate)))" >{{ activityCalendarDate(event) }}</h4>
            <h4 v-else></h4>
            <span v-if="!event.activity.media_type_names && event.activityState == 'cancelled'" class="fitnessplan--error">
                Abgebrochen
                <img src="@/assets/ic_16_fitnessplan-error.svg">
            </span>
            <span v-else-if="!event.activity.media_type_names && event.activityState == 'finished'" class="fitnessplan--success">
                Erledigt
                <img src="@/assets/ic_16_fitnessplan-success.svg">
            </span>
            <span v-else-if="(!event.activity.media_type_names && eventStartMissed(event))" class="fitnessplan--error">
                Verpasst
                <img src="@/assets/ic_16_fitnessplan-error.svg">
            </span>
            <span v-else-if="event.isOptionalExercise">Optional・{{ event.activityTime }} Uhr </span>
            <span v-else>{{ event.activityTime }} Uhr </span>
        </div>
        <div v-if="!event.activity.media_type_names" :class="activityClasses(event)" @click="selectFitnessPlanItem(event)">
            <div class="fitnessplan__item__content">
                <img :src="activityImageUrl(event)" alt="" class="fitnessplan__item__image">
                <div class="fitnessplan__item__date">
                    {{ activityDay(event) }}<span>{{ activityMonth(event) }}</span>
                </div>
                <div class="fitnessplan__item__icons">
                    
                    <!-- Equipment -->
                    <div v-if="event.activity.requires_equipment" class="fitnessplan__item__icons__icon">
                        <img src="@/assets/ic_32_workout_item_equipment.svg" alt="" />
                    </div>

                    <!-- Intensität -->
                    <div v-if="event.activity.difficulty" class="fitnessplan__item__icons__icon">
                        <img v-if="event.activity.difficulty == 3" src="@/assets/ic_32_workout_item_intensity-high.svg" alt="" />
                        <img v-else-if="event.activity.difficulty == 1" src="@/assets/ic_32_workout_item_intensity-low.svg" alt="" />
                        <img v-else-if="event.activity.difficulty == 2" src="@/assets/ic_32_workout_item_intensity-medium.svg" alt="" />
                    </div>

                    <!-- Dauer -->
                    <div v-if="event.activity.duration" class="fitnessplan__item__icons__icon">
                        <span>{{ event.activity.duration }}'</span>
                        <img src="@/assets/ic_32_workout_item_time.svg" alt="" />
                    </div>

                </div>
                <div v-if="event.uniqueChallengeId" class="fitnessplan__item__badge">
                    <span>Challenge</span>
                </div>
                <h3 class="fitnessplan__item__title">
                    {{ activityTitle(event) }}
                </h3>
            </div>
        </div>
        <div v-else class="fitnessplan-additionalContentItem"  @click="selectFitnessPlanItem(event)">
            <div v-if="event.activity.media_type_names === 'Podcast' " class="fitnessplan-additionalContentItem__icon">
                <img src="@/assets/medium/ic_medium_podcast.svg" alt="">
            </div>
            <div v-if="event.activity.media_type_names === 'Artikel' " class="fitnessplan-additionalContentItem__icon">
                <img src="@/assets/medium/ic_medium_artikel.svg" alt="">
            </div>
            <div v-if="event.activity.media_type_names === 'Interview' " class="fitnessplan-additionalContentItem__icon">
                <img src="@/assets/medium/ic_medium_interview.svg" alt="">
            </div>
            <div class="fitnessplan-additionalContentItem__text">
                <div class="fitnessplan-additionalContentItem__title">
                    {{ event.activity.title }}
                </div>
                <div v-if="event.activity.category_names != 'Rezepte'" class="fitnessplan-additionalContentItem__meta">
                    {{ event.activity.media_type_names }}<span> {{ event.activity.readtime }} min.</span>
                </div>
                <div v-else class="fitnessplan-additionalContentItem__meta">
                    Rezept <span> {{ event.activity.readtime }} min.</span>
                </div>
            </div>
        </div>
    </div>

      <EditFitnessPlanItemModal @startFitnessPlanEvent="startFitnessPlanItem"
                                @confirmDeleteChallenge="confirmDeleteChallenge"
                                @deleteSingleWorkoutFromFitnessPlanEvent="deleteSingleWorkoutFromFitnessPlanEvent"
                                @deleteFitnessPlanEvent="deleteFitnessPlanItem"
                                :fitnessPlanEvent="currentFitnessPlanItem"
                                name="editFitnessPlanItemModal"></EditFitnessPlanItemModal>

      <ConfirmDeleteChallengeModal @deleteFitnessPlanChallenge="deleteFitnessPlanChallenge"
                                   :fitnessPlanEvent="currentFitnessPlanItem"
                                   name="confirmDeleteChallengeModal"></ConfirmDeleteChallengeModal>

      <PremiumHintModal :noTrialButton="true"></PremiumHintModal>

  </div>
</template>

<script>
import EditFitnessPlanItemModal from "@/components/EditFitnessPlanItemModal.vue";
import PremiumHintModal from '@/components/PremiumHintModal.vue';
import ConfirmDeleteChallengeModal from "@/components/ConfirmDeleteChallengeModal";
import moment from 'moment';
import {analytics} from "@/firebase";
import {addDays} from '@/sportlerplus-helper.js';
import VueLoading from 'vue-loading-overlay/dist/vue-loading';
// Import stylesheet
import 'vue-loading-overlay/src/css/index.css';

export default {
    name: 'Fitnessplan',
    data() {
        return {
            fitnessPlan: null,
            challenges: [],
            calendar: [],
            filteredCalendar: [],
            currentFitnessPlanItem: null,
            hasDateFilter: false,
            selectedDate: null,
            showLoadingIndicator: false,
        }
    },
    mounted() {
        this.init();
    },
    components: {EditFitnessPlanItemModal, PremiumHintModal, ConfirmDeleteChallengeModal, VueLoading},
    computed: {
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
        displayDate() {
            if (this.selectedDate) {
                return moment(this.selectedDate).locale("de").format('DD.MM.YYYY')
            }
            return null;
        },
        nextDate() {
            if (this.selectedDate) {
                return addDays(this.selectedDate, 1).toISOString().split("T")[0];
            }
            return null;
        },
        prevDate() {
            if (this.selectedDate) {
                return addDays(this.selectedDate, -1).toISOString().split("T")[0];
            }
            return null;
        }
    },
    beforeRouteUpdate (to, from, next) {
        next();
        this.init();
    },
    methods: {
        async init() {
            this.showLoadingIndicator = true;
            this.selectedDate = new Date().toISOString().split("T")[0];
            if (this.$route.query.date) {
                this.hasDateFilter = true;
                this.selectedDate = this.$route.query.date;
            }
            else {
                this.hasDateFilter = false;
            }
            await this.$store.dispatch('fetchFitnessPlan').then( (res) => {
				if (res.length != 0) {
					this.fitnessPlan = res[0];
                    this.unrollCalendar();
                    if (this.fitnessPlan && this.fitnessPlan.startedChallenges) {
                        this.checkCompletedChallenges();
                    }
				}
			});
            this.showLoadingIndicator = false;
        },
        removeDateFilter() {
            this.$router.push("/fitnessplan?");
            //this.$router.replace({'query': null});
            //this.hasDateFilter = false;
            //this.filterCalendar();
        },
        unrollCalendar() {
            this.$store.dispatch('unrollCalendar').then( (res) => {
                this.calendar = res;
                this.filterCalendar();
            });
        },
        async checkCompletedChallenges() {
            await this.$store.dispatch("fetchChallenges").then(() => {
                this.challenges = this.$store.getters.getChallenges;
            });
            for (let challenge of this.fitnessPlan.startedChallenges) {
                //check challenges in progress only
                if (challenge.inProgress === true) {
                    let uniqueChallengeId = challenge.uniqueChallengeId;
                    let challengeItems = this.fitnessPlan.items.filter(it => it.uniqueChallengeId === uniqueChallengeId);
                    let nonOptionalChallengeItems = challengeItems.filter(it => it.isOptionalExercise === false);

                    //if (nonOptionalChallengeItems.every(cIt => cIt.activityState === "finished")) {
                    if (nonOptionalChallengeItems.length !== 0 && nonOptionalChallengeItems.every(cIt => cIt.activityState === "finished")) {
                        challenge.inProgress = false;
                        await this.updateFitnessPlan();
                        this.$store.commit('clearMotivationTextAsDisplayed', challenge.uniqueChallengeId);
                        analytics.logEvent('complete_challenge', {challenge_id: challenge.id});
                        await this.$router.push("/fitnessplan/challenge/success?unique_challenge_id=" + uniqueChallengeId);
                    }
                    //if not all units are finished: get challenges
                    else if (nonOptionalChallengeItems.length !== 0) {
                        let challengeId = parseInt(challenge.id);
                        let numberOfExercises = nonOptionalChallengeItems.length;
                        let numberOfFinishedExercises = nonOptionalChallengeItems.filter(item => item.activityState === "finished").length;
                        let displayedMotivationTextList = this.$store.getters.getDisplayedMotivationTextList;
                        let showAfter = [];
                        let currentChallenge = this.challenges.find(c => c.id === challengeId && c.motivationtexts);

                        if (currentChallenge) {
                            currentChallenge.motivationtexts.forEach(text => {
                                let converted;
                                if (text.unit === 1) {
                                    converted = Math.round(text.show_after / 100 * numberOfExercises);
                                } else {
                                    converted = text.show_after;
                                }
                                showAfter.push({showAfter: converted, motivationId: text.id});
                            });
                            showAfter.forEach(motivation => {
                                if (numberOfFinishedExercises === motivation.showAfter &&
                                    !displayedMotivationTextList.filter(text => text.uniqueChallengeId === uniqueChallengeId)
                                        .some(text => text.motivationId === motivation.motivationId)) {
                                    this.$router.push("/fitnessplan/challenge/intermediate?unique_challenge_id=" + uniqueChallengeId + "&motivationId=" + motivation.motivationId);
                                }
                            });
                        }
                    }
                }
            }
        },
        filterCalendar() {
            if (this.hasDateFilter) {
                let filterDay = this.moment(this.$route.query.date);
                this.filteredCalendar = this.calendar.filter(c => {
                    return this.moment(filterDay).isSame(c.startDate, 'day');
                });
            }
            else {
                let thresDate = new Date();
                thresDate.setDate(thresDate.getDate() - 3);
                this.filteredCalendar = this.calendar.filter(c => c.startDate > thresDate).slice(0, 32);
            }
        },
        selectFitnessPlanItem(fitnessPlanEvent) {
            this.currentFitnessPlanItem = fitnessPlanEvent;
            this.$modal.show("editFitnessPlanItemModal");
        },
        deleteFitnessPlanItem(fitnessPlanEvent) {
            if (fitnessPlanEvent.historicalEvent) {
                this.fitnessPlan.startedActivities = this.fitnessPlan.startedActivities.filter(fpi => fpi.guid != fitnessPlanEvent.guid);
            }
            else {
                this.fitnessPlan.items = this.fitnessPlan.items.filter(fpi => fpi.guid != fitnessPlanEvent.guid);
                if (!fitnessPlanEvent.repeating) {
                    this.fitnessPlan.startedActivities = this.fitnessPlan.startedActivities.filter(fpi => fpi.guid != fitnessPlanEvent.guid);
                }
            }
            this.updateFitnessPlan();
            this.filteredCalendar = [];
            this.calendar = [];
            this.unrollCalendar();
            this.$modal.hide("editFitnessPlanItemModal");
        },
        confirmDeleteChallenge() {
            this.$modal.show("confirmDeleteChallengeModal");
        },
        deleteFitnessPlanChallenge(fitnessPlanEvent) {
            this.fitnessPlan.items = this.fitnessPlan.items.filter(fpi => !fpi.uniqueChallengeId || fpi.uniqueChallengeId !== fitnessPlanEvent.uniqueChallengeId);
            this.fitnessPlan.startedChallenges = this.fitnessPlan.startedChallenges.filter(sc => sc.uniqueChallengeId !== fitnessPlanEvent.uniqueChallengeId);
            this.fitnessPlan.startedActivities = this.fitnessPlan.startedActivities.filter(sa => !sa._uniqueChallengeId || !(sa.activityState == "started" && sa._uniqueChallengeId === fitnessPlanEvent.uniqueChallengeId));
            this.$store.commit('clearMotivationTextAsDisplayed', fitnessPlanEvent.uniqueChallengeId);
            analytics.logEvent('cancel_challenge', { challenge_id: fitnessPlanEvent.challengeId });
            this.updateFitnessPlan();
            this.filteredCalendar = [];
            this.calendar = [];
            this.unrollCalendar();
            this.$modal.hide("confirmDeleteChallengeModal");
        },
        deleteSingleWorkoutFromFitnessPlanEvent(fitnessPlanSingleEvent) {
            const cfp = this.fitnessPlan.items.find(fpi => fpi.guid == fitnessPlanSingleEvent.guid);
            cfp.exceptionDates = cfp.exceptionDates || [];
            cfp.exceptionDates.push(fitnessPlanSingleEvent.activityDate);
            this.updateFitnessPlan();
            this.filteredCalendar = [];
            this.calendar = [];
            this.unrollCalendar();
            this.$modal.hide("editFitnessPlanItemModal");
        },
        dateOnly(d) {
            return d.toISOString().split("T")[0];
        },
        activityClasses(event) {
            if (event.activityState == "cancelled") {
                return "fitnessplan__item fitnessplan__item--error";
            }
            else if (event.activityState == "finished") {
                return "fitnessplan__item fitnessplan__item--success";
            }
            else if (this.eventStartMissed(event)) {
                return "fitnessplan__item fitnessplan__item--error";
            }
            return "fitnessplan__item";
        },
        activityType(activity) {
			if (!activity) { return null; }
			if (activity.meta === undefined) { return "Category"; }
			else { return activity.meta.type; }
		},
        activityMonth(event) {
            if (!event) { return ""; }
            let d = event.startDate;
            let locale = "de-de";
            let month = d.toLocaleString(locale, { month: "short" });
            return month; 
        },
        activityDay(event) {
            if (!event) { return ""; }
            let d = event.startDate;
            return d.getDate();
        },
        activityTitle(event) {
            const at = this.activityType(event.activity);
            if (at == 'Category' || at == 'Custom/SportsType' || at == 'Custom/UserPlaylist') {
                return event.activity.name;
            }
            return event.activity.title;
        },
        activityImageUrl(event) {
            if (this.activityType(event.activity) == 'Custom/UserPlaylist') {
                return 'https://app.sportlerplus.com/scaled/800/bruce-mars-gJtDg6WfMlQ-unsplash.jpg';
            }
            if (this.activityType(event.activity) == 'workout.WorkoutPage') {
                return 'https://app.sportlerplus.com/scaled/800/' + event.activity.preview_image_url;
            }
            else return 'https://app.sportlerplus.com/scaled/800/' + event.activity.image_url;
        },
        activityCalendarDate(event) {
            if (!event) { return ""; }
            return this.moment(event.startDate).locale("de").calendar(null, {
                sameDay: '[Heute]',
                nextDay: '[Morgen]',
                nextWeek: 'dddd',
                lastDay: '[Gestern]',
                lastWeek: '[letzten] dddd',
                sameElse: 'DD.MM.YYYY'
            });
        },
        eventStartMissed(c) {
            let cal_start_date = moment(c.startDate);
            let now = moment();
            if (cal_start_date < now && !moment(cal_start_date).isSame(now, 'day')) {
                return true;
            }
            return false;
        },
        async startFitnessPlanItem(fitnessPlanEvent) {
            if (fitnessPlanEvent.activityState === "finished") {
                await this.$router.push("/fitnessplan/detailsActivity?" + "guid=" + fitnessPlanEvent.guid + "&activityDate=" + fitnessPlanEvent.activityDate + "&activityTime=" + fitnessPlanEvent.activityTime);
                return;
            }
            let activityType = this.activityType(fitnessPlanEvent.activity);
            /* do not create an entry in startedActivities for articles */
            if (activityType == "blog.BlogPage") {
                await this.$router.push("/articles/" + fitnessPlanEvent.activity.id);
                return;
            }

            if (fitnessPlanEvent.activity.premium_content && Object.keys(this.premiumStatus).length === 0) {
                this.$modal.show('premiumHint');
                return;
            }

            // copy event to extra array "startedActivities"
            let activityGuid = fitnessPlanEvent.guid;
            let activityDate = fitnessPlanEvent.activityDate;
            let activityTime = fitnessPlanEvent.activityTime;
            let existingStartedActivity = null;
            if (this.fitnessPlan.startedActivities) {
                existingStartedActivity = this.fitnessPlan.startedActivities.find(el => el.guid == activityGuid && el.activityDate == activityDate && el.activityTime == activityTime);
            }
            else {
                this.fitnessPlan.startedActivities = [];
            }

            if (existingStartedActivity == null && activityGuid != null) {
                let newActivity = Object.assign({}, fitnessPlanEvent);
                newActivity.activityState = "started";
                this.fitnessPlan.startedActivities.push(newActivity);
            }

            await this.updateFitnessPlan();
            this.$modal.hide("editFitnessPlanItemModal");

            if (activityType == "Category") {
                await this.$router.push("/playlist/start?category_id=" + fitnessPlanEvent.activity.id +
                    "&guid=" + activityGuid + "&activityDate=" + activityDate + "&activityTime=" + activityTime);
            }
            else if (activityType == "Custom/UserPlaylist") {
                await this.$router.push("/playlist/start?user_playlist_id=" + fitnessPlanEvent.activity.uid +
                    "&guid=" + activityGuid + "&activityDate=" + activityDate + "&activityTime=" + activityTime);
            }
            else if (activityType == "exercise.PlaylistPage") {
                await this.$router.push("/playlist/start?playlist_id=" + fitnessPlanEvent.activity.id+
                    "&guid=" + activityGuid + "&activityDate=" + activityDate + "&activityTime=" + activityTime);
            }
            else if (activityType == "workout.WorkoutPage") {
                await this.$router.push("/workout/" + fitnessPlanEvent.activity.id +
                    "?guid=" + activityGuid + "&activityDate=" + activityDate + "&activityTime=" + activityTime);
            }
            else if (activityType == "Custom/SportsType") {
                await this.$router.push("/fitnessplan/customActivity?sportsType_id=" + fitnessPlanEvent.activity.id +
                    "&guid=" + activityGuid + "&activityDate=" + activityDate + "&activityTime=" + activityTime +
                    "&navbartitle=" + fitnessPlanEvent.activity.name);
            }
            else if (activityType == "fitnessplan.FitnessPlanExercisePage") {
                await this.$router.push("/fitnessplan/customActivity?"  +
                    "&guid=" + activityGuid + "&activityDate=" + activityDate + "&activityTime=" + activityTime +
                    "&navbartitle=" + fitnessPlanEvent.activity.title);
            }
        },
        /*selectAdditionalContentItem(event) {
            this.$router.push("/articles/" + event.activity.id);
        },*/
        async updateFitnessPlan() {
            await this.$store.dispatch('saveFitnessPlan', this.fitnessPlan);
        }
    },
}
</script>

<style lang="scss">
  .fitnessplan {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    .tabs {
        margin-top: -24px;
    }

    > .button--primary {
        margin-top: 4px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      min-height: 160px;
      border-radius: 4px;
      overflow: hidden;
      text-decoration: none;
      width: 100%;
      background: rgba($colorBlack, .08);
      margin-bottom: 16px;

      .section & {
        margin-bottom: 0;
      }

      &--success {
        box-shadow: 0 0 0 2px $colorSuccess;
      }

      &--error {
        box-shadow: 0 0 0 2px $colorDanger;
      }

      &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        width: 100%;
        min-height: 160px;
        padding-bottom: 9px;
      }

      &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__date {
        display: flex;
        flex-direction: column;
        text-align: center;
        border-radius: 3px;
        background: $colorWhite;
        margin: 16px;
        font-size: 17px;
        font-weight: 700;
        color: $colorBlack;
        width: 40px;
        padding: 2px 0 4px;
        text-transform: uppercase;
        z-index: 1;

        span {
          font-size: 11px;
          color: rgba($colorBlack,.48);
          margin-top: -7px;
        }
      }

      &__badge {
        z-index: 1;
        margin-top: auto;
        margin-bottom: 5px;
        padding: 0 16px;
        font-size: 11px;
        font-weight: 700;
        color: $colorWhite;
        letter-spacing: -0.23px;
        text-shadow: 0 2px 12px rgba($colorBlack, .64);

        ~ .fitnessplan__item__title {
            margin-top: 4px;
        }

        span {
          display: inline-flex;
          font-size: 11px;
          line-height: 11px;
          font-weight: 700;
          letter-spacing: -0.13px;
          padding: 4px 5px 3px 6px;
          border-radius: 2px;
          color: $colorWhite;
          background: $colorPrimary;
          text-shadow: none;

          .fitnessplan__item--active & {
            background: $colorSuccess;
          }
        }
      }

      &__title {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: auto;
        padding: 0 16px;
        color: $colorWhite;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 24px;
        text-shadow: 0 2px 16px rgba($colorBlack ,0.48);
        margin-bottom: 4px;

        .slider-5050 & {
            font-size: 17px;
            line-height: 20px;
            letter-spacing: -0.35px;
            margin-bottom: 1px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: calc(100% + 8px);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
      }

      &__meta {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.29px;
        color: $colorWhite;
        padding: 0 16px;
        margin-top: 1px;
        z-index: 10;

        img {
          margin-right: 3px;
        }

        .fitnessplan__item--success & {
          color: $colorSuccess;
        }

        .fitnessplan__item--error & {
          color: $colorDanger;
        }
      }

      &__icons {
        position: absolute;
        top: 16px;
        right: 14px;
        display: flex;
        justify-content: flex-end;

        &__icon {
          position: relative;
          width: 32px;
          height: 32px;
          margin-left: 4px;

          span {
            position: absolute;
            left: 0;
            top: 6px;
            width: 100%;
            text-align: center;
            padding-left: 2px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            color: $colorPrimary;
            letter-spacing: -0.29px;
          }
        }
      }

      &__footer {
        display: flex;
        width: 100%;
        background: $colorWhite;
        box-shadow: inset 0 0 0 1px rgba($colorBlack, .08);
        border-radius: 0 0 4px 4px;
        margin-top: -1px;

        &__button {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: -0.21px;
            padding: 10px 16px;
            color: $colorBlack;

            &:first-child {
                &:before {
                    content: '';
                    position: absolute;
                    height: 20px;
                    width: 1px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba($colorBlack, .16);
                }
            }
        }

        img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
      }
    }

    .section__title {
      span {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        color: rgba($colorBlack, .48);

        img {
          margin-left: 7px;
        }

        &.fitnessplan--success {
          color: $colorSuccess;
        }

        &.fitnessplan--error {
          color: $colorDanger;
        }
      }
    }

    &-additionalContentItem {
        display: flex;
        align-items: center;
        background: rgba($colorBlack, .04);
        box-shadow: inset 0 0 0 2px rgba($colorBlack, .08);
        border-radius: 4px;
        padding: 10px 16px;
        margin-bottom: 16px;

        &__icon {
            margin-right: 12px;
            display: flex;
        }

        &__text {
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }

        &__title {
            font-size: 17px;
            line-height: 24px;
            font-weight: 600;
            letter-spacing: -0.25px;
            color: $colorBlack;
            margin-bottom: -1px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &__meta {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: -0.21px;
            color: rgba($colorBlack, .48);
        }
    }
  }

  .calendar-navigation {
    position: sticky;
    z-index: 10;
    top: 88px;
    margin: -20px -20px 20px;
    width: calc(100% + 40px);
    background: $colorWhite;
    box-shadow: 0 -1px 0 0 rgba($colorBlack, .08);

    @media screen and (min-width: 560px) {
        width: 100vw;
        margin-left: calc((100vw - 480px) / -2);
    }

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 26px;
        border-radius: 50%;
        box-shadow: 0 0 16px 0 rgba(0,0,0,0.08);
        transform: translateZ(-1px);
    }

    &__content {
        position: relative;
        z-index: 10;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $colorWhite;

        @media screen and (min-width: 560px) {
            margin: 0 auto;
            padding: 0;
            width: 100%;
        }
    }

    &__left, &__right {
        cursor: pointer;
        display: flex;
        padding: 8px;
    }

    &__center {
        display: flex;
        justify-content: center;
        flex: 1;
        font-size: 14px;
        font-weight: 700;
        color: $colorBlack;
        max-width: 416px;

        span {
            cursor: pointer;
        }
    }
  }
</style>
<template>
  <div class="magazin">
    <!-- TOP SLIDER -->
    <div class="home__slider noselect">
      <SliderHome :slides="featuredArticles">
        <splide-slide v-for="feature in featuredArticles" v-bind:key="feature.id">
          <span @click="handleFeatureClick(feature)">
            <div v-if="feature.title" class="home__slider__title text-that-needs-wrapping">
              {{ feature.title }}
            </div>
            <div v-if="feature.subtitle" class="home__slider__subtitle">
              {{ feature.subtitle }}
            </div>
          <img :src="'https://app.sportlerplus.com/scaled/800/' + feature.image_url">
          </span>
        </splide-slide>
      </SliderHome>
    </div>
    <div class="magazin__filter">
        <div class="magazin__filter__search">
            <input v-model="searchWord" v-on:input="startSearch" type="text" placeholder="Artikel suchen">
            <div class="magazin__filter__search__icon"></div>
            <div class="magazin__filter__search__close" @click="dismissSearch"></div>
        </div>
        <div @click="openCategoryFilter" ref="categoryFilter" class="magazin__filter__item">
            <img src="@/assets/ic_16_filter.svg" alt="Thema filtern">
        </div>
    </div>

      <div v-for="(article, i) in filteredArticles" :key="i" class="magazin__item noselect">
          <span @click="handleArticleClick(article)">
        <img :src="'https://app.sportlerplus.com/scaled/800/' + article.main_image_url"
             class="magazin__item__image">
        <div v-if="article.series_subtitle" class="magazin__item__badge">{{ article.series_subtitle }}</div>
        <div v-else class="magazin__item__badge">{{ article.media_type_names }}</div>
        <div class="magazin__item__text">
          <div class="magazin__item__title">{{ article.title }}</div>
          <div class="magazin__item__copy">{{ article.intro }}</div>
          <div class="magazin__item__meta">{{ articleReadTime(article) }}</div>
        </div>
      </span>
    </div>

    <!-- Filter Modal "Thema" -->
    <VModal @closed="applyCategoryFilter" name="categoryFilter" width="100%" height="auto" :shiftY="1" :scrollable="true">

      <div class="vm-header">
        <div class="vm-header-left">
          <img @click="resetCategorySelection" v-show="categorySelectionChanged" src="@/assets/ic_nav_reset.svg" alt="">
        </div>
        <div class="vm-header-center">
          <span>Thema/Themen wählen</span>
        </div>
        <div class="vm-header-right">
          <img src="@/assets/ic_nav_close.svg" alt="" @click="dismissCategoryFilter">
        </div>
      </div>

      <div class="vm-content">

        <div>
          <div @click="toggleCategorySelect(category)" :class="category.selected ? 'list__item large selected' : 'list__item large'" v-for="category in articleCategories" :key="category.id">
            <div class="list__item__icon">
              <img v-if="category.name=='Training' && category.selected" src="@/assets/topic/ic_topic_training-active.svg" alt="">
              <img v-if="category.name=='Training' && !category.selected" src="@/assets/topic/ic_topic_training.svg" alt="">
              <img v-if="category.name=='Ernährung' && category.selected" src="@/assets/topic/ic_topic_food-active.svg" alt="">
              <img v-if="category.name=='Ernährung' && !category.selected" src="@/assets/topic/ic_topic_food.svg" alt="">
              <img v-if="category.name=='Rezepte' && category.selected" src="@/assets/topic/ic_topic_recipe-active.svg" alt="">
              <img v-if="category.name=='Rezepte' && !category.selected" src="@/assets/topic/ic_topic_recipe.svg" alt="">
              <img v-if="category.name=='Trends' && category.selected" src="@/assets/topic/ic_topic_trends-active.svg" alt="">
              <img v-if="category.name=='Trends' && !category.selected" src="@/assets/topic/ic_topic_trends.svg" alt="">
              <img v-if="category.name=='Produkte & Supplements' && category.selected" src="@/assets/topic/ic_topic_products-active.svg" alt="">
              <img v-if="category.name=='Produkte & Supplements' && !category.selected" src="@/assets/topic/ic_topic_products.svg" alt="">
              <img v-if="category.name=='News' && category.selected" src="@/assets/topic/ic_topic_news-active.svg" alt="">
              <img v-if="category.name=='News' && !category.selected" src="@/assets/topic/ic_topic_news.svg" alt="">
              <img v-if="category.name=='Podcast' && category.selected" src="@/assets/medium/ic_medium_podcast-active.svg" alt="">
              <img v-if="category.name=='Podcast' && !category.selected" src="@/assets/medium/ic_medium_podcast.svg" alt="">
              <img v-if="category.name=='Interview' && category.selected" src="@/assets/medium/ic_medium_interview-active.svg" alt="">
              <img v-if="category.name=='Interview' && !category.selected" src="@/assets/medium/ic_medium_interview.svg" alt="">
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                {{ category.name }}
              </div>
            </div>
          </div>
        </div>

        <v-btn @click="applyCategoryFilter" class="button button--primary filterButton">Auswahl bestätigen</v-btn>

      </div>

  </VModal>

    <PremiumHintModal></PremiumHintModal>
  </div>
</template>

<script>
import SliderHome from "@/components/SliderHome.vue";
import { SplideSlide } from '@splidejs/vue-splide';
import PremiumHintModal from '@/components/PremiumHintModal.vue';
import {analytics} from '@/firebase/index.js';

export default {
    name: 'Articles',
    methods: {
        articleReadTime(article) {
            if (this.readArticleList.indexOf(article.id) != -1) {
                return "Bereits gelesen";
            }
            return article.readtime + " Min.";
        },
        init() {
            this.readArticleList = this.$store.getters.getReadArticleList;
            this.featuredArticles = [...this.$store.getters.getFeaturedMagazineArticles];
            this.$store.dispatch('fetchArticles').then( () => this.articles = this.$store.getters.getArticles );
            this.$store.dispatch('fetchArticleCategories').then(() => {
                this.articleCategories = this.$store.getters.getArticleCategories;
                this.applyCategoryFilter();
            });
            analytics.logEvent('view_article_overview');

            if (window.localStorage.getItem('searchWord') !== null) {
                this.searchWord = window.localStorage.getItem('searchWord');
                this.startSearch();
            }
        },
        openCategoryFilter() {
            this.categorySelectionChanged = this.selectedCategories.length > 0;
            this.previousSelectedCategories = JSON.parse(JSON.stringify(this.articleCategories));
            this.$modal.show("categoryFilter");
        },
        toggleCategorySelect(cat) {
            cat.selected = !cat.selected;
            if (this.articleCategories.find(ac => ac.selected)) {
              this.categorySelectionChanged = true;
            }
            else {
              this.categorySelectionChanged = false;
            }
            this.$forceUpdate();
        },
        resetCategorySelection() {
            this.categorySelectionChanged = false;
            this.articleCategories.forEach( ac => ac.selected = false );
            this.$forceUpdate();
        },
        applyCategoryFilter() {
            this.$modal.hide('categoryFilter');
            this.selectedCategories = this.articleCategories.filter( ac => ac.selected );
            this.selectedCategoryNames = this.selectedCategories.map( ac => ac.name ).join(", ");
            if (this.selectedCategoryNames.length == 0) {
                this.selectedCategoryNames = "Thema";
            }
            if (this.selectedCategories.length == 0) {
                this.$refs['categoryFilter'].classList.remove("magazin__filter__item--active");
            }
            else {
                this.$refs['categoryFilter'].classList.add("magazin__filter__item--active");
            }
        },
        dismissCategoryFilter() {
          this.$modal.hide('categoryFilter');
          this.articleCategories = JSON.parse(JSON.stringify(this.previousSelectedCategories));
        },
        handleArticleClick(article) {
            if (article.premium_content && Object.keys(this.premiumStatus).length === 0) {
                this.$modal.show('premiumHint');
            }
            else {
                window.localStorage.setItem('searchWord', this.searchWord);
                this.$router.push("/articles/" + article.id);
            }
        },
        handleFeatureClick(feature) {
            // features are pre-filtered (only type blog.BlogPage)
            let article = this.getArticles.find(a => a.id == feature.feature.id);
            if (article) this.handleArticleClick(article);
        },
        async startSearch() {
            if (this.searchWord === "") {
                this.articles = this.getArticles;
            }
            else if (this.searchWord && this.searchWord.length >= 3) {
                await this.$store.dispatch('searchArticlesByInput', {search_word: this.searchWord}).then((res) => {
                    this.articles = res.items;
                });
            }
        },
        dismissSearch() {
            this.articles = this.getArticles;
            window.localStorage.removeItem('searchWord');
            this.searchWord = "";
            this.selectedCategories.forEach(art => art.selected = false);
            this.selectedCategories = [];
            this.$refs['categoryFilter'].classList.remove("magazin__filter__item--active");
        },
    },
    components: {
        SliderHome, SplideSlide, PremiumHintModal,
    },
    computed: {
        getArticles() {
            return this.$store.getters.getArticles;
        },
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
        filteredArticles() {
            let searchCategories = this.selectedCategories.map(c => c.id);

            let filtered = [];
            if (!this.articles) { return filtered; }

            this.articles.forEach(function(el) {
                let categoryMatch = false;

                if (searchCategories.length === 0) {
                    categoryMatch = true;
                }
                else {
                    let currentCategories = el.categories.map(ac => ac.id);
                    if (currentCategories.filter(ac => searchCategories.includes(ac)).length > 0) {
                        categoryMatch = true;
                    }
                }

                if (categoryMatch) {
                    filtered.push(el);
                }
            });
            return filtered;
        }
    },
    mounted() {
        this.init();
    },
    data() {
        return {
            featuredArticles: [],
            articleCategories: [],
            mediaTypes: [],
            categorySelectionChanged: false,
            mediaTypeSelectionChanged: false,
            selectedMediaTypes: [],
            previousSelectedMediaTypes: [],
            selectedCategories: [],
            previousSelectedCategories: [],
            selectedMediaTypeNames: "Medium",
            selectedCategoryNames: "Kategorien wählen",
            readArticleList: [],
            searchWord: "",
            articles: [],
        }
    }
}
</script>

<style lang="scss">
  .magazin {
    position: relative;

    &__item {
      border-radius: 4px;
      overflow: hidden;
      line-height: 0;
      box-shadow: 0 8px 16px 0 rgba($colorBlack, .04), 0 0 0 1px rgba($colorBlack, .08);

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      a {
        text-decoration: none;
      }

      &__image {
        width: 100%;
        height: 160px;
        object-fit: cover;
      }

      &__badge {
        display: inline-flex;
        position: relative;
        z-index: 2;
        font-size: 11px;
        line-height: 11px;
        font-weight: 700;
        letter-spacing: -0.23px;
        text-transform: uppercase;
        padding: 7px 7px 6px 8px;
        margin-left: 16px;
        margin-top: -12px;
        border-radius: 4px;
        color: $colorWhite;
        background: $colorPrimary;
      }

      &__text {
        padding: 8px 16px 20px;
      }

      &__title {
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        letter-spacing: -0.29px;
        color: $colorBlack;
        margin-bottom: 3px;
        /*white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;*/
      }

      &__copy {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: -0.21px;
        color: rgba($colorBlack, .48);
      }

      &__meta {
        font-size: 11px;
        font-weight: 600;
        letter-spacing: -0.16px;
        color: rgba($colorBlack, .48);
        margin-right: auto;
        margin-top: 15px;
        margin-bottom: 8px;
      }
    }

    &__filter {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      padding: 0;
      position: sticky;
      z-index: 3;
      top: 56px;

      &:before {
        content: '';
        position: absolute;
        width: calc(100% + 40px);
        height: 64px;
        left: -20px;
        top: -8px;
        background-image: linear-gradient(180deg, rgba($colorBackground, 1) 0%, rgba($colorBackground, 1) 50%, rgba($colorBackground, 0) 100%);
      }

      &__search {
        position: relative;
        display: flex;
        flex: 1;

        &__icon {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 12px;
            left: 12px;
            background: url('../assets/ic_16_search_light.svg');
            opacity: .48;
            transition: all .2s ease-in-out;
        }

        &__close {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 0;
            right: 0;
            background: url('../assets/ic_16_close.svg');
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 0;
            visibility: hidden;
            cursor: pointer;
            z-index: -1;
            transition: all .2s ease-in-out;
        }

        input {
            font-size: 14px;
            padding: 8px 36px !important;
            width: 100%;
            text-align: left;
            max-width: inherit;
            border-radius: 3px;
            box-shadow: 0 0 0 1px rgba($colorBlack, .08) !important;
            background: $colorWhite;
            transition: all .2s ease-in-out;

            &:hover {
                box-shadow: 0 0 0 1px rgba($colorBlack, .12) !important;
            }

            &:focus {
                box-shadow: 0 0 0 1px $colorBlack !important;
            }

            &:focus + .magazin__filter__search__icon {
                opacity: 1;
            }

            &:not(:placeholder-shown) ~ .magazin__filter__search__close {
                visibility: visible;
                z-index: 1;
                opacity: .48;

                &:hover {
                    opacity: 1;
                }
            }
        }
      }

      &__item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        box-shadow: 0 0 0 1px rgba($colorBlack, .08);
        background: $colorWhite;
        width: 40px;
        height: 40px;
        cursor: pointer;
        margin-left: 8px;
        transition: all .2s ease-in-out;

        &:hover {
            box-shadow: 0 0 0 1px rgba($colorBlack, .12);
        }

        &:before {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            top: 10px;
            right: 10px;
            border-radius: 50%;
            background: $colorPrimary;
            box-shadow: 0 0 0 2px $colorWhite;
            opacity: 0;
        }

        &--active {
            &:before {
                opacity: 1;
            }
        }
      }
    }

    .button.filterButton {
      margin-top: 16px;
    }
  }
</style>
<template>
      <VModal name="sharedPlaylist" width="100%" height="auto" :shiftY="1" :scrollable="true" class="exercise-modal">
        <div class="vm-header">
          <div class="vm-header-left">
          </div>
          <div class="vm-header-center">
          </div>
          <div class="vm-header-right">
            <img src="@/assets/ic_nav_close.svg" @click="dismissDialog" alt="">
          </div>
        </div>
        <div v-if="playlistData" class="vm-content">
            <img src="@/assets/notification/ic_notification_play.svg" alt="">
            <h3>"{{ playlistData.name }}"</h3>
            <p v-if="playlistData.userDisplayName">Workout von {{ playlistData.userDisplayName }}</p>
            <div v-for="item in playlistData.items" v-bind:key="item.id" class="list__item">
                <div class="list__item__content">
                    <div class="list__item__label">
                         {{ item.title }}
                    </div>
                    <img v-if="item.requires_equipment" src="@/assets/ic_equipment.svg" class="equipment-icon">
                </div>
                <img class="play-icon" @click="previewExercise(item)" src="@/assets/ic_24_play.svg">
            </div>
        </div>
        <div v-else class="vm-content">
            Dieses Workout konnte nicht gefunden werden
        </div>
        <v-btn v-if="playlistData" @click=importPlaylist class="button button--primary">Workout speichern</v-btn>
        <v-btn v-else @click="dismissDialog" class="button" >Abbrechen</v-btn>
      </VModal>
</template>

<script>
export default {
    name: 'SharedPlaylistModal',
    props: {
        playlistId: {
            type: String,
            default() { return null; } 
        }
    },
    methods: {
        dismissDialog() {
            this.$modal.hide('sharedPlaylist');
        },
        previewExercise(exercise) {
            this.$router.push("/exercise/preview/" + exercise.id);
        },
        init() {
            this.$store.dispatch('fetchSharedPlaylist', this.playlistId).then((res) => {
                this.playlistData = res
                if (this.playlistData) {
                    this.playlistData._originalPlaylistUid = this.playlistId;
                    this.playlistData._originalUserName = this.playlistData.userDisplayName;
                    delete this.playlistData["uid"];
                }
            });
        },
        importPlaylist() {
            this.$store.dispatch('saveUserPlaylist', this.playlistData).then(() => {
                this.dismissDialog();
                this.$router.replace({'query': null});
                this.$router.push("/playlists/personal/");
            });
        }
    },
    watch: {
        playlistId: {
            immediate: true,
            handler: function(newVal) {
                if (newVal == null) { return; }
                this.init();
            }
        }
    },
    data() {
        return {
            playlistData: null,
        }
    }
}
</script>

<style lang="scss" scoped>
    .exercise-modal {
        .equipment-icon {
            margin: -7px 0px -7px 7px;
        }
        .play-icon {
            padding: 8px;
            margin: -10px -8px -10px auto;
            z-index: 10;
        }
        .list__item__content {
            overflow: hidden;

            &:after {
                display: none !important;
            }
        }
        .list__item__label {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .vm-content{
            margin-bottom: 80px !important; 

            > img {
                width: 108px;
                margin: 0px auto 25px;
            }

            h3 {
                text-align: center;
            }

            p {
                text-align: center;
                margin-bottom: 30px;
            }
        }
        .button {
            position: fixed;
            z-index: 11;
            bottom: 16px;
            left: 0;
            right: 0;
            width: calc(100% - 32px);
        }
    }
</style>
<template>
    <div v-if="showModal" class="modal-route">
      <div class="modal-content">
        <router-view></router-view>
      </div>
    </div>
</template>
<script>
export default {
    name: "ModalRoute",
    data() {
		return {
			showModal: false,
		}
	},
	watch: {
		$route: {
		/* eslint-disable no-unused-vars */
		immediate: true,
		handler: function(newVal, oldVal) {
			this.showModal = newVal.meta && newVal.meta.showModal;
		}
		}
	},
}
</script>
<style lang="scss" scoped>
  /* MODAL */
  .modal-route {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba($color: #000000, $alpha: 0.5);

    .modal-content {
      display: flex;
      min-height: 100%;
      overflow-y: scroll;
    }
  }
</style>

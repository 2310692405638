<template>
    <splide :options="options" :slides="slides">
        <slot></slot>
    </splide>
</template>

<script>
    import { Splide } from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';

    export default {
        name: "SliderSmall",
        props:  ["slides"],
        components: { Splide },
        data() {
            return {
                options: {
                    arrows: false,
                    pagination: false,
                    perPage: 2,
                    gap: '16px',
                    padding: '64px',

                    breakpoints: {
                        767: {
                            drag: true,
                            gap: '8px',
                            padding: '20px',
                        }
                    }
                },
            };
        },
    }
</script>


<style lang="scss">
    .splide__slide  {
        opacity: .48;
        transition: all .2s ease-in-out;

        .home__slider & {
            opacity: 1;
        }
    }
    .splide__slide.is-active, .splide__slide.is-visible {
        opacity: 1;
    }
</style>
<template>
  <div class="home__wrapper noselect">

    <div v-if="showModal" class="modal-overlay">
        <div class="modal-content">
            <div class="modal-header">
            <div class="modal-header__left">
                <!--<img @click="modalGoBack" src="@/assets/ic_nav_back.svg" alt="">-->
            </div>
            <div class="modal-header__center">
                {{ modalTitle }}
            </div>
            <div class="modal-header__right">
                <img class="modal-header__close" @click="closeModalStack" src="@/assets/ic_nav_close.svg" alt="">
            </div>
            </div>
            <router-view @setModalTitle="setModalTitle"></router-view>
        </div>
    </div>

    <div v-if="isAuthenticated" class="home">

      <!-- TOP SLIDER -->
      <div class="home__slider">
        <SliderHome :slides="featuredArticles">
          <splide-slide v-for="(feature, index) in featuredArticles" v-bind:key="feature.id">
            <a v-if="feature.link" class="home__slider__link" :href="convertTrackingLink(feature.link)" target="_blank">
              <div v-if="feature.title" class="home__slider__title text-that-needs-wrapping">
                {{ feature.title }}
              </div>
              <div v-if="feature.subtitle" class="home__slider__subtitle">
                {{ feature.subtitle }}
              </div>
            <img :src="'https://app.sportlerplus.com/scaled/800/' + feature.image_url">
            </a>
            <span v-else class="home__slider__link" @click="handleFeatureClick(feature, index)">
              <div v-if="feature.title" class="home__slider__title text-that-needs-wrapping">
                {{ feature.title }}
              </div>
              <div v-if="feature.subtitle" class="home__slider__subtitle">
                {{ feature.subtitle }}
              </div>
            <img :src="'https://app.sportlerplus.com/scaled/800/' + feature.image_url">
            </span>
          </splide-slide>
        </SliderHome>
      </div>

      <!-- PROBETRAINING -->
      <router-link v-show="!premiumStatus.validTo" to="/probetraining" class="freeContent">
        <div class="freeContent__icon">
          <img src="@/assets/ic_badge_probetraining.svg" alt="" />
        </div>
        <div class="freeContent__title">
          Kostenfreie Inhalte
        </div>
        <div class="freeContent__subtitle">
          Lerne SportlerPlus kennen & probiere alle kostenfreien Inhalte zeitlich unbegrenzt aus.
        </div>
        <div class="freeContent__button">
          Los geht's <img src="@/assets/freeContent-arrow.svg">
        </div>
      </router-link>
      <small v-show="!premiumStatus.validTo" class="home__premium-hint">…oder lass dich von allen Inhalten inspirieren und erhalte Einblicke in unsere Premium-Features:</small>


      <!-- FITNESSPLAN -->
      <div class="section">
        <div class="section__title">
          <h4>Mein Trainingsplan</h4>
          <a @click="handleLink('/fitnessplan')">Alles ansehen</a>
        </div>
        <div class="section__content slider-5050">
          <SliderSmall :slides="calendar">
            <splide-slide v-for="event in calendar" v-bind:key="event.id">
              <div :class="activityClasses(event)" @click="openFitnessPlan(event)">
                <div class="fitnessplan__item__content">
                  <img :src="activityImageUrl(event)" alt="" class="fitnessplan__item__image">
                  <div class="fitnessplan__item__date">
                    {{ activityDay(event) }}<span>{{ activityMonth(event) }}</span>
                  </div>
                  <h3 class="fitnessplan__item__title">
                    {{ activityTitle(event) }}
                  </h3>
                  <div v-if="event.activityState == 'finished'" class="fitnessplan__item__meta">
                    <img src="@/assets/ic_16_fitnessplan-success.svg">
                    Erledigt
                  </div>
                  <div v-else-if="event.activityState == 'cancelled'" class="fitnessplan__item__meta">
                    <img src="@/assets/ic_16_fitnessplan-error.svg">
                    Abgebrochen
                  </div>
                  <div v-else-if="dateDiffFromToday(event) >= 1" class="fitnessplan__item__meta">
                    <img src="@/assets/ic_16_fitnessplan-error.svg">
                    Verpasst
                  </div>
                  <div v-else class="fitnessplan__item__meta">
                  {{ event.activityTime }} Uhr
                  </div>
                </div>
              </div>
            </splide-slide>
          </SliderSmall>

          <!-- EMPTY-STATE -->
          <div v-if="calendar && calendar.length == 0" class="section__empty">
            <img src="@/assets/ic_fitnessplan_empty.svg" alt="">
            <div class="section__empty__text">
              Strukturiere dein Training und erstelle einen eigenen Trainingsplan!
            </div>
            <a @click="handleLink('/fitnessplan/create')" class="button button--primary button--small">Jetzt loslegen</a>
          </div>
        </div>
      </div>

      <!-- Kategorien -->
      <div class="section">
        <div class="section__title">
          <h4>Workouts</h4>
          <router-link to="/trainieren">Alle ansehen</router-link>
        </div>
        <div class="section__content slider-5050">
          <SliderSmall :slides="categories">
            <splide-slide v-for="category in categories" v-bind:key="category.id">
              <span @click="handleCategoryClick(category)">
                <div class="fitnessplan__item">
                  <div class="fitnessplan__item__content">
                    <img class="fitnessplan__item__image" :src="'https://app.sportlerplus.com' + category.square_tile_image_url" alt="" />
                    <div v-if="displayPremiumBadge(category)" class="card__premium"><img src="@/assets/ic_20_premium.svg" alt=""></div>
                    <div class="fitnessplan__item__title">{{ category.name }}</div>
                  </div>
                </div>
              </span>
            </splide-slide>  
          </SliderSmall>
        </div>
      </div>

      <!-- CREATE PLAYLIST (ONLY FOR PREMIUM-USERS) -->
      <cta-button v-if="premiumStatus.validTo && userPlaylists.length == 0" path="/playlists/create/start" first-line="Erstelle aus 300+ Übungen" second-line="dein eigenes Workout!" icon-source="ic_badge_plus.svg"></cta-button>
<!--      <router-link v-if="premiumStatus.validTo && userPlaylists.length == 0" to="/playlists/create/start" class="grab grab&#45;&#45;small">-->
<!--        <div class="grab__icon">-->
<!--          <img src="@/assets/ic_badge_plus.svg" alt="" />-->
<!--        </div>-->
<!--        <div class="grab__text">-->
<!--          Erstelle aus 300+ Übungen <span>dein eigenes Workout!</span>-->
<!--        </div>-->
<!--      </router-link>-->

      <!-- SPORTARTEN -->
      <div class="section">
        <div class="section__title">
          <h4>Meine Sportarten</h4>
          <router-link to="/playlists/sportstype/-1">Alle ansehen</router-link>
        </div>
        <div class="section__content">
          <Slider v-if="userSportsTypes.length > 1" :slides="userSportsTypes">
            <splide-slide v-for="sportsType in userSportsTypes" v-bind:key="sportsType.id">
              <router-link :to="'/playlists/sportstype/' + sportsType.id + '?navbartitle=' + sportsType.name">
                <div class="card">
                  <div class="card__title">{{ sportsType.name }}</div>
                  <div class="card__subtitle"></div>
                  <img v-if="sportsType.image_url" class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + sportsType.image_url" alt="" />
                </div>
              </router-link>
            </splide-slide>  
          </Slider>

          <!-- EMPTY-STATE -->
          <div v-else class="section__empty">
            <img src="@/assets/ic_sporttypes_empty.svg" alt="">
            <div class="section__empty__text">
              Wir stellen dir auf deine Sportarten optimierte Playlists zusammen.
            </div>
            <a href="/settings/sportstypes" class="button button--primary button--small">Sportart wählen</a>
          </div>
        </div>
      </div>

      <div v-show="!premiumStatus.validTo" @click="$router.push('/settings/premium')" class="grab">
        <div class="grab__icon">
          <img src="@/assets/ic_badge_premium.svg" alt="" />
        </div>
        <div class="grab__text">
          Nutze alle Vorteile als<br>
          <span>Premium-User!</span>
        </div>
      </div>

      <!-- CHALLENGES -->
      <div v-if="challenges" class="section">
        <div class="section__title">
          <h4>Challenges</h4>
          <router-link to="/fitnessplan/challenges">Alle ansehen</router-link>
        </div>
        <div class="section__content">
            <Slider :slides="challenges">
                <splide-slide v-for="challenge in challenges.slice(0, 5)" v-bind:key="challenge.id">
                    <router-link :to="challenge._status == 'new' ? '/fitnessplan/challenges' + '?challengeId=' + challenge.id : '/fitnessplan/challenges'">
                        <div :class="challenge._status == 'active' ? 'fitnessplan__item fitnessplan__item--active' : 'fitnessplan__item'">
                            <div class="fitnessplan__item__content">
                                <div class="fitnessplan__item__badge">
                                    <span>{{ challenge._status_text }}</span>・{{ challenge.badge }}
                                </div>
                                <h3 class="fitnessplan__item__title">{{ challenge.title }}</h3>
                                <img v-if="challenge.image_url" :src="'https://app.sportlerplus.com/scaled/800/' + challenge.image_url"
                                    class="fitnessplan__item__image" />
                            </div>
                        </div>
                    </router-link>
                </splide-slide>
            </Slider>
        </div>
      </div>

      <!-- SHOP -->
      <div v-if="homePageInfo && homePageInfo.shop_image && homePageInfo.shop_image.meta" class="section">
        <div class="section__title">
          <h4>Nutrition</h4>
          <a href="https://shop.sportlerplus.de/?utm_source=app&utm_medium=overview&utm_campaign=sportlerplus+referrals" target="_blank">Alle Produkte</a>
        </div>

        <div class="section__content">
          <SliderSmall :slides="homePageInfo.shop_tiles">
            <splide-slide v-for="tile in homePageInfo.shop_tiles" v-bind:key="tile.id">
              <a :href="convertTrackingLink(tile.link)" class="shop__item" target="_blank">
                <div class="shop__item__badge" v-if="tile.badge">{{ tile.badge }}</div>
                <img :src="'https://app.sportlerplus.com/scaled/800/' + tile.image_url" alt="" class="shop__item__image">
              </a>
            </splide-slide>
          </SliderSmall>
        </div>
      </div>

      <!-- MAGAZIN -->
      <div class="home__magazin">
        <div class="section__title">
            <h4>Magazin</h4>
            <router-link to="/articles">Alle Beiträge</router-link>
        </div>
        <div v-if="getArticleLink(0) != null" class="home__magazin__top">
          <span @click="handleArticleClick(getArticle(0), 0)">
            <img :src="getArticleImageUrl(0)" alt="">
          </span>
        </div>
        <div v-if="getArticleLink(1) != null" class="home__magazin__bottom">
          <div class="home__magazin__bottom__left">
            <span @click="handleArticleClick(getArticle(1), 1)">
              <img :src="getArticleImageUrl(1)" alt="">
            </span>
          </div>
          <div v-if="getArticleLink(2) != null" class="home__magazin__bottom__right">
            <span @click="handleArticleClick(getArticle(2), 2)">
              <img :src="getArticleImageUrl(2)" alt="">
            </span>
          </div>
        </div>
      </div>

      <!-- YOUR PRO -->
      <div class="section">
        <div class="section__title">
          <h4>Coaches</h4>
          <router-link to="/your-pro">Alle ansehen</router-link>
        </div>
        <div class="section__content">
          <SliderSmall :slides="coaches">
            <splide-slide v-for="coach in randomCoaches" v-bind:key="coach.id"> 
              <router-link :to="'/your-pro-detail/' + coach.id" class="yourpro__item">
                <img :src="'https://app.sportlerplus.com/scaled/800/' + coach.image_url" alt="" class="yourpro__item__image">
                <h3 class="yourpro__item__title">
                  <div class="yourpro__item__badge">{{ coach.badge }}</div>
                  {{ coach.name }}
                </h3>
              </router-link>
            </splide-slide>
          </SliderSmall>
        </div>
      </div>

      <PlaylistMaximumModal></PlaylistMaximumModal>

      <SharedPlaylistModal :playlistId="importPlaylistId"></SharedPlaylistModal>
      
      <PremiumHintModal :message="premiumHintText" :title="premiumHintTitle" :badge="premiumHintBadge" :noTrialButton="premiumHintReminder" :infoArticle="premiumInfoArticle"></PremiumHintModal>
      <PurchaseSuccessModal></PurchaseSuccessModal>

    </div>

    <div v-else class="home__empty" id="home_empty">
      <h1>Are you ready?</h1>
      <v-btn class="button button--primary" to="/onboarding-step2">Registrieren</v-btn>
      <!--<v-btn class="button button--white" to="/sign-in">Einloggen</v-btn>-->
      <small>Bereits registriert? <router-link to="/sign-in" class="text-center">Jetzt einloggen</router-link></small>
    </div>

  </div>
</template>

<script>
import firebase from 'firebase';
import Slider from "@/components/Slider.vue";
import SliderSmall from "@/components/SliderSmall.vue";
import SliderHome from "@/components/SliderHome.vue";
import { SplideSlide } from '@splidejs/vue-splide';
import PremiumHintModal from '@/components/PremiumHintModal.vue';
import SharedPlaylistModal from '@/components/SharedPlaylistModal.vue';
import PurchaseSuccessModal from '@/components/PurchaseSuccessModal.vue';
import PlaylistMaximumModal from '@/components/PlaylistMaximumModal.vue';
import { analytics } from '@/firebase/index.js';
import { convertTrackingLinkApp } from '@/sportlerplus-helper.js';
import { lastActiveNonModal } from '@/router';
import CtaButton from "@/components/CtaButton.vue";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export default {
    name: 'Home',
    methods: {
        convertTrackingLink(l) {
            return convertTrackingLinkApp(l);
        },
        triggerUserVerificationFinished(user) {
            this.$store.dispatch("triggerUserVerification", {email: user.email});
        },
        init() {
            let userEmailVerified = this.$store.getters.getUserEmailVerified;
            this.$store.dispatch('fetchArticles').then( () => this.articles = this.$store.getters.getArticles );
            this.$store.dispatch('fetchHomePage').then( () => {
                this.homePageInfo = this.$store.getters.getHomePageInfo;
                this.featuredArticles = this.$store.getters.getFeaturedArticles;
            });
            this.$store.dispatch('fetchCategories').then( () => this.categories = this.$store.getters.getCategories );
            this.$store.dispatch('fetchWorkouts').then( () => this.workouts = this.$store.getters.getWorkouts );
            this.$store.dispatch('fetchPlaylists').then( () => this.playlists = this.$store.getters.getPlaylists );
            this.$store.dispatch('fetchCoaches').then( () => this.coaches = this.$store.getters.getCoaches );
            this.$store.dispatch('fetchEquipmentList');
            this.$store.dispatch("fetchChallenges").then(() => {
                this.challenges = this.$store.getters.getChallenges;
            });

            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    // User is signed in.
                    this.user = user;
                    if (this.$route.query.purchase) {
                        this.activatePurchase();
                    }
                    else {
                        this.checkPremiumStatus();
                    }

                    if (!userEmailVerified && user.emailVerified) {
                        this.triggerUserVerificationFinished(user)
                    }

                    this.fetchUserSportsTypes();
                    this.$store.dispatch('fetchAllUserPlaylists').then((res) => {
                        this.userPlaylists = res;

                        if (this.$route.query.importPlaylist) {
                            this.importPlaylistId = this.$route.query.importPlaylist;
                            
                            let playlistAlreadyImported = res.find(el => el._originalPlaylistUid == this.importPlaylistId);
                            if (playlistAlreadyImported) {
                                console.log("this playlist has already been imported, redirecting...");
                                //console.log(playlistAlreadyImported);
                                this.$router.replace({'query': null});
                                this.$router.push("/playlist/start?user_playlist_id=" + playlistAlreadyImported.uid);
                            }
                            else {
                                this.$modal.show("sharedPlaylist");
                            }

                        }
                    });

                    this.retrieveFitnessPlan();

                }
            });

            analytics.logEvent('view_home');
        },
        displayPremiumBadge(cat) {
            if (cat.premium_content && Object.keys(this.premiumStatus).length == 0) {
                return true;
            }
            return false;
        },
        async fetchUserSportsTypes() {
            await this.$store.dispatch('fetchSportsTypes').then( () => {
                this.sportsTypes = this.$store.getters.getSportsTypes;
            });
            await this.$store.dispatch('fetchUserInformation').then( (res) => {
                if (res.length != 0) {
                    this.userInformation = res[0];

                    if (this.userInformation && this.userInformation.sports_types) {
                        this.userSportsTypes = [...this.userInformation.sports_types];
                    }                    
                }
            });
            this.sportsTypes.filter(st => !st.user_selectable).forEach(st => this.userSportsTypes.push(st));
            this.userSportsTypes.forEach(ust => {
                let tempST = this.sportsTypes.find(st => st.id == ust.id);
                if (tempST) {
                    ust.image_url = tempST.image_url;
                }
            });
        },
        // a and b are javascript Date objects
		dateDiffInDays(a, b) {
			// Discard the time and time-zone information.
			const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
			const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

			return Math.floor((utc2 - utc1) / _MS_PER_DAY);
		},
        dateDiffFromToday(event) {
            var start = this.moment(event.startDate);
            var end = this.moment();
            return end.diff(start, "days")
        },
        checkPremiumStatus() {
            window._spStatus = window._spStatus || {};
            if (this.user && !window._spStatus.premiumChecked) {
                window._spStatus.premiumChecked = true;
                this.$store.dispatch('fetchPremiumStatus', {email: this.user.email});
            }
            else if (this.premiumStatus.validTo && !window._spStatus.premiumReminderPresented) {
                let endDate = new Date(0);
                endDate.setUTCSeconds(this.premiumStatus.validTo.seconds);
                let currentDate = new Date();
                let remainingPremiumDays = this.dateDiffInDays(currentDate, endDate);
                if (remainingPremiumDays < 5 && remainingPremiumDays > 1 && !this.premiumStatus.code.startsWith("com.sportplatzmedia.sportlerplus.subscription")) {
                    window._spStatus.premiumReminderPresented = true;
                    this.premiumHintText = "In Kürze hast du keinen Zugriff mehr auf die Premium-Features. Bleib' am Ball und verlängere deine Mitgliedschaft.";
                    this.premiumHintTitle = "Premium läuft ab";
                    this.premiumHintBadge = "Nur noch " + remainingPremiumDays + " Tage";
                    this.premiumHintReminder = true;
                    this.$modal.show('premiumHint');
                }
            }
        },
        showPremiumModal(infoArticle) {
            this.premiumHintText  = null;
            this.premiumHintBadge = null;
            this.premiumHintTitle = null;
            this.premiumHintReminder = false;
            this.premiumInfoArticle = infoArticle;
            this.$modal.show('premiumHint');
        },
        activatePurchase() {
            let productId = this.$route.query.productId;
            let transactionId = this.$route.query.transactionId;
            let orderId = this.$route.query.orderId; // Google Billing only
            
            console.log("activate purchase");
            this.$store.dispatch('activatePurchase', {
                code: productId,
                email: this.user.email,
                transactionId: transactionId,
                orderId: orderId,
            }).then( () => {
                this.$modal.show("purchaseSuccess");
            });
        },
        getArticle(position) {
            if (this.articles.length > position) {
                return this.articles[position];
            }
            return null;
        },
        getArticleImageUrl(position) {
            let imgPath = 'https://app.sportlerplus.com/scaled/800/';
            let article = this.getArticle(position)
            if (article.home_tile_image_url) {
                return imgPath + article.home_tile_image_url;
            }
            else {
                return imgPath + article.main_image_url;
            }
        },
        getArticleLink(position) {
            let articlePath = "/articles/"
            let tempArticle = this.getArticle(position);
            if (tempArticle != null) {
                return articlePath + this.getArticle(position).id;
            }
        },
        handleWorkoutClick(workout) {
            if (workout.premium_content && Object.keys(this.premiumStatus).length === 0) {
                this.showPremiumModal(null);
            }
            else {
                this.$router.push("/workout/" + workout.id);
            }
        },
        handlePlaylistClick(playlist) {
            if (playlist.premium_content && Object.keys(this.premiumStatus).length === 0) {
                this.showPremiumModal(null);
            }
            else {
                this.$router.push("/playlist/start?playlist_id=" + playlist.id);
            }
        },
        handleArticleClick(article, index) {
            if (index != -1) {
                analytics.logEvent('click_article_tile', { index: index, article_id: article.id });
            }

            if (article.premium_content && Object.keys(this.premiumStatus).length === 0) {
                this.showPremiumModal(null);
            }
            else {
                this.$router.push("/articles/" + article.id);
            }
        },
        handleLink(link) {
            if (Object.keys(this.premiumStatus).length === 0) {
                if (link.startsWith("/fitnessplan")) {
                    this.showPremiumModal("/articles/509");
                }
                else {
                    this.showPremiumModal(null);
                }
            }
            else {
                this.$router.push(link);
            }
        },
        handleFeatureClick(feature, index) {
            if (!feature.feature) {
                return;
            }

            let type = feature.feature.meta.type;
            analytics.logEvent('click_feature_slide', { index: index, feature_id: feature.feature.id });
            
            if (type == "blog.BlogPage") {
                let article = this.articles.find(a => a.id == feature.feature.id);
                if (article) this.handleArticleClick(article, -1);
            }
            else if (type == "blog.BlogIndexPage") {
                this.$router.push("/articles/");
            }
            else if (type == "exercise.PlaylistPage") {
                let playlist = this.playlists.find(p => p.id == feature.feature.id);
                if (playlist) this.handlePlaylistClick(playlist);
            }
            else if (type == "workout.WorkoutPage") {
                let workout = this.workouts.find(w => w.id == feature.feature.id);
                if (workout) this.handleWorkoutClick(workout);
            }
        },
        handleCategoryClick(category) {
            //if (category.premium_content && Object.keys(this.premiumStatus).length === 0) {
            //    this.showPremiumModal(null);
            //}
            //else {
            //    this.$router.push("/playlist/start?category_id=" + category.id);
            //}
            this.$router.push("/trainieren?category_id=" + category.id);
        },
        setDeviceToken(id, system, installationId) {
            this.$store.dispatch('setDeviceToken', {token: id, system: system, installationId: installationId});
        },
        transferReceiptData(receiptData, system) {
            this.$store.dispatch('transferReceiptData', {receiptData: receiptData, system: system});
        },
        async retrieveFitnessPlan() {
            await this.$store.dispatch('fetchFitnessPlan').then((res) => {
                if (res.length != 0) {
                    this.fitnessPlan = res[0];
                    this.$store.dispatch('unrollCalendar').then((res) => {
                        let thresDate = new Date();
                        thresDate.setDate(thresDate.getDate() - 1);
                        this.calendar = res.filter(c => c.startDate > thresDate && (!c.activity.meta || c.activity.meta.type != "blog.BlogPage")).slice(0, 5);
                    });
                }
            });


            if (this.fitnessPlan && this.fitnessPlan.startedChallenges) {
                this.challenges.forEach(c => {
                    let user_challenge = this.fitnessPlan.startedChallenges.find(sc => sc.id == c.id);
                    if (user_challenge && user_challenge.inProgress) {
                        c.sortOrder = 1;
                        c._status = "active";
                        c._status_text = "Aktiv";
                    }
                    else if (user_challenge && !user_challenge.inProgress) {
                        c.sortOrder = 10;
                        c._status = "finished";
                        c._status_text = "Bereits absolviert";
                    }
                    else {
                        c.sortOrder = 5;
                        c._status = "new";
                        c._status_text = "Noch nicht absolviert";
                    }
                });
            }
            this.challenges.sort((a,b) => a.sortOrder - b.sortOrder);

        },
        activityMonth(event) {
            if (!event) { return ""; }
            let d = event.startDate;
            let locale = "de-de";
            let month = d.toLocaleString(locale, { month: "short" });
            return month; 
        },
        activityDay(event) {
            if (!event) { return ""; }
            let d = event.startDate;
            return d.getDate();
        },
        activityClasses(event) {
            if (event.activityState == "cancelled") {
                return "fitnessplan__item fitnessplan__item--error";
            }
            else if (event.activityState == "finished") {
                return "fitnessplan__item fitnessplan__item--success";
            }
            else if (this.dateDiffFromToday(event) >= 1) {
                return "fitnessplan__item fitnessplan__item--error";
            }
            return "fitnessplan__item";
        },
        activityTitle(event) {
            const at = this.activityType(event.activity);
            if (at == 'Category' || at == 'Custom/SportsType' || at == 'Custom/UserPlaylist') {
                return event.activity.name;
            }
            return event.activity.title;
        },
        activityImageUrl(event) {
            if (event.activity.square_tile_image_url) {
                return 'https://app.sportlerplus.com' + event.activity.square_tile_image_url;
            }
            else if (this.activityType(event.activity) == 'Custom/UserPlaylist') {
                return 'https://app.sportlerplus.com/scaled/800/bruce-mars-gJtDg6WfMlQ-unsplash.jpg';
            }
            else if (this.activityType(event.activity) == 'workout.WorkoutPage') {
                return 'https://app.sportlerplus.com/scaled/800/' + event.activity.preview_image_url;
            }
            else return 'https://app.sportlerplus.com/scaled/800/' + event.activity.image_url;
        },
        activityType(activity) {
			if (!activity) { return null; }
			if (activity.meta === undefined) { return "Category"; }
			else { return activity.meta.type; }
		},
        openFitnessPlan(event) {
            // use event date
            this.handleLink("/fitnessplan?date=" + event.activityDate);
        },
        setModalTitle(title) {
            this.modalTitle = title;
        },
        closeModalStack() {
            if (lastActiveNonModal) {
                this.$router.push(lastActiveNonModal.path);
            }
            else {
                this.$router.go(-1);
            }
        },
        modalGoBack() {
            this.$router.go(-1);
        },
    },
    components: {
      CtaButton,
        Slider, SliderSmall, SliderHome, SplideSlide, PremiumHintModal, PurchaseSuccessModal, SharedPlaylistModal, PlaylistMaximumModal
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters.isAuthenticated;
        },
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
        /*mainCategories: function () {
            let mainCategories = []
            mainCategories = this.categories.filter(i => i.parent == null);
            mainCategories.forEach((cat) => {
                let subCategories = this.categories.filter(j => j.parent != null && j.parent.id == cat.id);
                if (subCategories.length > 0) {
                    cat['hasSubCategories'] = true
                }
                else {
                    cat['hasSubCategories'] = false
                }
            });
            return mainCategories;
        },*/
        randomCoaches() {
            //return shuffleArray([...this.coaches]).slice(0, 10);
            var coaches = [...this.coaches];
			coaches.forEach(el => el._randomSubKey = Math.random());
			coaches.sort((a, b) => a.sortOrder - b.sortOrder || a._randomSubKey - b._randomSubKey);
			return coaches.slice(0, 10);
        },
        /*trainingItems() {
            var trainingItems = this.playlists.concat(this.workouts);
            return trainingItems.sort((a, b) => {
                var d1 = new Date(a.go_live_at);
                var d2 = new Date(b.go_live_at);
                return d2 - d1;
             })
        }*/
    },
    mounted() {
        window.vm.homeComponent = this;
        this.init();
    },
    watch: {
        homePageInfo: function() {
            let home_empty = document.getElementById("home_empty");
            if (home_empty == null || this.homePageInfo == null || this.homePageInfo.image_url == null) { return; }
            home_empty.style.backgroundImage = 'url( https://app.sportlerplus.com/scaled/800/' + this.homePageInfo.image_url + ')';
        },
        $route: {
            /* eslint-disable no-unused-vars */
            immediate: true,
            handler: function(newVal, oldVal) {
                this.showModal = newVal.meta && newVal.meta.showModal;
                this.modalTitle = newVal.meta && newVal.meta.title || "";
            }
        }
    },
    data() {
        return {
            user: null,
            premiumHintText: null,
            premiumHintBadge: null,
            premiumHintTitle: null,
            premiumHintReminder: false,
            premiumInfoArticle: null,
            userInformation: null,
            userSportsTypes: [],
            featuredArticles: [],
            homePageInfo: [],
            articles: [],
            categories: [],
            playlists: [],
            userPlaylists: [],
            sportsTypes: [],
            workouts: [],
            coaches: [],
            options: {
                arrows: false,
                pagination: false,
                perPage: 3,
                gap: '24px',
                padding: '16px',
                breakpoints: {
                    768: {
                        perPage: 1,
                        drag: true,
                        gap: '8px',
                    }
                }
            },
            fitnessPlan: null,
            calendar: [],
            importPlaylistId: null,
            showModal: false,
            modalTitle: "",
            challenges: [],
        }
    },
}
</script>

<style lang="scss">
.modal-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba($colorBlack, .64);

    .modal-header {
      display: flex;
      padding: 12px 0;

      &__center {
        text-align: center;
        flex: 1;
        font-size: 17px;
        font-weight: 700;
      }

      &__left, &__right {
        display: flex;
        min-width: 24px;
      }

      &__close {
        opacity: .48;
        cursor: pointer;
      }
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      background: $colorWhite;
      padding: 6px 20px 24px;
      border-radius: 24px 24px 0 0;
    }
  }
  
  .home {

    &__wrapper {
      height: 100%;
    }

    &__slider {
      width: calc(100% + 40px);
      margin: -24px -20px 16px;

      .splide__slide{
        position: relative;
        height: 200px;

        @media screen and (min-width: 500px) {
          height: 240px;
        }

        a, span {
          text-decoration: none;
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: flex-end;
          padding-bottom: 16px;
        }
      }

      &__title {
        font-size: 40px;
        line-height: 36px;
        font-weight: 900;
        letter-spacing: -0.7px;
        color: $colorWhite;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0 2px 16px rgba(30,29,31,0.48);
        position: relative;
        z-index: 2;
        margin-bottom: 8px;
      }

      &__subtitle {
        display: inline-flex;
        margin: 0px auto;
        font-size: 11px;
        line-height: 16px;
        font-weight: 700;
        letter-spacing: -0.23px;
        color: $colorWhite;
        background: $colorPrimary;
        border-radius: 2px;
        padding: 1px 5px;
        position: relative;
        z-index: 2;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }

    &__magazin {
      margin-bottom: 16px;

      &__top {
        border-radius: 4px 4px 0 0;
        width: 100%;
        max-height: 200px;
        overflow: hidden;
        margin-bottom: 8px;
      }

      &__bottom {
        display: flex;
        justify-content: space-between;
        border-radius: 0 0 4px 4px;
        overflow: hidden;

        &__left, &__right {
          width: calc(50% - 4px);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: #ccc;
      }
    }

    &__premium-hint {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 27px;
      margin-bottom: 19px;
    }

    .grab {
      margin-top: 32px;
    }
  }

  .home__empty {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
    background-size: cover;
    padding: 20px;
    margin: 0 -20px;
    width: calc(100% + 40px);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background-image: linear-gradient(180deg, rgba($colorBlack, 0) 0%, rgba($colorBlack, .9) 100%);
      width: 100%;
      height: 64%;
      left: 0;
      bottom: 0;
    }

    h1 {
      font-size: 64px;
      line-height: 64px;
      font-weight: 900;
      letter-spacing: -1.33px;
      color: $colorWhite;
      text-shadow: 0 2px 16px rgba(30,29,31,0.48);
      margin: auto;
      text-align: center;
      text-transform: uppercase;
      padding-top: 80px;
    }

    small {
      position: relative;
      z-index: 2;
      color: $colorWhite;
      opacity: 1;
      padding: 4px 0;

      a {
        color: $colorPrimary !important;
      }
    }
  }

  .section__content {
    width: calc(100% + 40px);
    margin: 0 -20px;

    @media screen and (min-width: 768px) {
      width: calc(100% + 128px);
      margin: 0 -64px;

      .splide {
          position: relative;

          &:before {
              position: absolute;
              z-index: 3;
              content: '';
              top: 0;
              left: 0;
              width: 64px;
              height: 100%;
              background-image: linear-gradient(270deg, rgba(249,249,249,0.00) 0%, #F9F9F9 100%);
          }

          &:after {
              position: absolute;
              z-index: 3;
              content: '';
              top: 0;
              right: 0;
              width: 64px;
              height: 100%;
              background-image: linear-gradient(270deg, #F9F9F9 0%, rgba(249,249,249,0.00) 100%);
          }
      }
    }

    a {
      text-decoration: none;
    }
  }

  .section__empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: rgba($colorBlack, .04);
    padding: 32px 16px;
    width: calc(100% - 40px);
    max-width: 480px;
    margin: 0px auto;
    border-radius: 4px;

    img {
      max-width: 41px;
      margin: 0px auto;
    }

    &__text {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.29px;
      line-height: 20px;
      color: rgba($colorBlack, .32);
      text-align: center;
      margin: 11px 0 19px;
    }

    .button {
      font-size: 14px !important;
      display: inline-block;
      width: auto;
      padding: 10px 16px !important;
    }
  }

  .shop__item {
    display: inline-flex;
    border-radius: 4px;
    overflow: hidden;
    background: rgba($colorBlack, .08);
    margin-bottom: 16px;
    height: 160px;

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__badge {
      display: inline-flex;
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 2;
      font-size: 11px;
      line-height: 11px;
      font-weight: 700;
      letter-spacing: -0.23px;
      text-transform: uppercase;
      padding: 7px 7px 6px 8px;
      border-radius: 4px;
      color: $colorWhite;
      background: $colorPrimary;

      @media screen and (min-width: 768px) {
        top: 16px;
        left: 16px;
      }
    }
  }

  .freeContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: $colorWhite !important;
    text-decoration: none;
    background: $colorPrimary;
    border-radius: 8px;
    margin-top: 24px;
    box-shadow: 0 12px 24px 0 rgba($colorPrimary,0.48);

    &__icon {
      margin: 24px 0 4px;
      
      img {
        height: 64px;
      }
    }

    &__title {
      font-size: 20px;
      letter-spacing: -0.29px;
      font-weight: 700;
      margin-bottom: 3px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.29px;
      font-weight: 600;
      opacity: .64;
    }

    &__button {
      background: rgba($colorWhite, .16);
      box-shadow: 0 -1px 0 0 rgba($colorWhite, .32);
      padding: 19px 0 18px; 
      margin-top: 26px;
      font-size: 17px;
      letter-spacing: -0.35px;
      font-weight: 700;

      img {
        margin-left: 3px;
      }
    }
  }
</style>
<template>
    <splide :options="options" :slides="slides">
        <slot></slot>
    </splide>
</template>
<style lang="scss" scoped>
</style>
<script>
    import { Splide } from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';

    export default {
        name: "SliderHome",
        props:  ["slides"],
        components: { Splide },
        data() {
            return {
                options: {
                    arrows: false,
                    type: 'loop',
                    autoplay: true,
                    pagination: false,
                    perPage: 1,
                    breakpoints: {
                        768: {
                            drag: true,
                        }
                    }
                },
            };
        },
    }
</script>
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '@/firebase/';
import vmodal from 'vue-js-modal'
import vueInsomnia from "vue-insomnia";
import VueMeta from 'vue-meta'
import moment from 'moment'
import VCalendar from 'v-calendar';
import VueToast from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-sugar.css';
import VueConfetti from 'vue-confetti';

// First, checks if it isn't implemented yet.
if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) { 
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
      ;
    });
  };
}

Vue.prototype.moment = moment;

Vue.config.productionTip = false;

window.vm = {};

Vue.use(vmodal, { componentName: 'VModal' });
Vue.use(vueInsomnia);
Vue.use(VueMeta);
Vue.use(VCalendar,{
  componentPrefix: 'vc',
});
Vue.use(VueToast);
Vue.use(VueCompositionAPI);
Vue.use(VueConfetti);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


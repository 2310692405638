<template>
    <splide :options="options" :slides="slides">
        <slot></slot>
    </splide>
</template>
<style lang="scss" scoped>
</style>
<script>
import { Splide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default {
    name: "Slider",
    props: {
        slides: {
            type: Array,
            default() { return []; } 
        },
        pagination: {
            type: Boolean,
            default() { return false; }
        }
    },
    components: { Splide },
    data() {
        return {
            options: {
                arrows: false,
                pagination: this.pagination,
                perPage: 1,
                gap: '16px',
                padding: '64px',

                breakpoints: {
                    767: {
                        drag: true,
                        gap: '8px',
                        padding: '20px',
                    }
                }
            },
        };
    },
}
</script>
<template>
    <div :class='inactive ? "contentItem inactive" : "contentItem"'>
        <div class="contentItem__icon">
            <img @click="startPlaylist(id)" src="@/assets/contentitem/ic_contentitem_play.svg" alt="" />
        </div>
        <div @click="startPlaylist(id)" class="contentItem__content">
            <div class="contentItem__title">
                {{ title }}
            </div>
            <div class="contentItem__meta">
                {{ number }} Übungen
            </div>
        </div>
        <div @click="openEditModal(id)" class="contentItem__actions">
            <!-- TODO: Image needs to be implemented via SCSS -->
            <img src="@/assets/ic_more.svg" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlaylistPersonalItem',
    props: {
        title: {
            type: String,
            default() {
                return "Überschrift";
            }
        },
        number: {
            type: Number,
            default() {
                return 0;
            }        
        },
        id: {
            type: Number,
            default() {
                return 0;
            }
        },
        inactive: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    methods: {
        openEditModal(id) {
            if (this.inactive) {
                this.$emit("editPlaylistPersonalItemRestricted", id);
            }
            else {
                this.$emit("editPlaylistPersonalItem", id);
            }
        },
        startPlaylist(id) {
            if (!this.inactive) {
                this.$emit("startPlaylist", id);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .inactive {
        .contentItem__title {
            color: rgba($colorBlack, .48);
        }
        .contentItem__meta {
            color: rgba($colorBlack, .32);
        }
    }
</style>
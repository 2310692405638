<template>
	<footer class="footer">
		<div class="footer__content">
			<ul class="download">
				<li>App herunterladen</li>
				<li>
					<a href="https://apps.apple.com/de/app/sportlerplus/id1533773413" target="_blank" class="download__button">
						<div class="download__button__icon">
							<img src="@/assets/ic_appstore_apple.svg" alt="Download Apple AppStore" />
						</div>
						<div class="download__button__text">
							Download on the
							<span>AppStore</span>
						</div>
					</a>
				</li>
				<li>
					<a href="https://play.google.com/store/apps/details?id=com.sportplatzmedia.sportlerplus" target="_blank" class="download__button">
						<div class="download__button__icon">
							<img src="@/assets/ic_appstore_google.svg" alt="Download GooglePlay" />
						</div>
						<div class="download__button__text">
							Get it on
							<span>GooglePlay</span>
						</div>
					</a>
				</li>
			</ul>
			<ul class="shop">
				<li>Shop</li>
				<!-- <li><router-link to="/story">Story</router-link></li> -->
				<li><a href="https://shop.sportlerplus.de/alle-produkte/?utm_source=website&utm_medium=footer&utm_campaign=sportlerplus+referrals">Alle Produkte</a></li>
				<li><a href="https://shop.sportlerplus.de/nutrition/?utm_source=website&utm_medium=footer&utm_campaign=sportlerplus+referrals">Nutrition</a></li>
				<li><a href="https://shop.sportlerplus.de/cat/index/sCategory/6?utm_source=website&utm_medium=footer&utm_campaign=sportlerplus+referrals">Equipment</a></li>
				<li><a href="https://shop.sportlerplus.de/aktionen/?utm_source=website&utm_medium=footer&utm_campaign=sportlerplus+referrals">Aktionen</a></li>
			</ul>
			<ul class="company">
				<li>SportlerPlus</li>
				<!-- <li><router-link to="/story">Story</router-link></li> -->
				<li><router-link to="/athleten">Athleten</router-link></li>
				<li><router-link to="/teams">Teams</router-link></li>
				<li><router-link to="/magazine">Magazin</router-link></li>
				<!-- <li><router-link to="/teams">Join the club</router-link></li> -->
			</ul>
			<ul class="rules">
				<li>Rechtliches</li>
				<li><a href="mailto:info@sportlerplus.de">Kontakt</a></li>
				<li><router-link to="/info/48">AGB</router-link></li>
				<li><router-link to="/info/49">Datenschutz</router-link></li>
				<li><router-link to="/info/47">Impressum</router-link></li>
				<li><router-link to="/info/53">Gesundheit</router-link></li>
				<li><router-link to="/support/faq">FAQ</router-link></li>
			</ul>
			<ul class="social">
				<li>Social</li>
				<li><a href="https://www.facebook.com/sportlerplus" target="_blank"><img src="@/assets/ic_footer_social_facebook.svg" alt="Facebook Logo">Facebook</a></li>
				<li><a href="https://www.instagram.com/sportlerplus/?hl=de" target="_blank"><img src="@/assets/ic_footer_social_instagram.svg" alt="Instagram Logo">Instagram</a></li>
				<!--<li><a href=""><img src="@/assets/ic_footer_social_youtube.svg" alt="Youtube Logo">Youtube</a></li>-->
				<li><a href="https://vm.tiktok.com/ZMJEKLV9V/" target="_blank"><img src="@/assets/ic_footer_social_tiktok.svg" alt="TikTok Logo">TikTok</a></li>
			</ul>
			<div class="back-to-top" @click="scrollToTop">
				<img src="@/assets/landingpage/back-to-top.svg" alt="Back to Top">
			</div>
		</div>

		<!-- <div class="promobox">
			Du hast einen Bonus-Code? <span @click="modalPromoCode">Jetzt einlösen</span>
		</div> -->

		<PromoCodeModal></PromoCodeModal>
	</footer>
</template>

<script>
	import PromoCodeModal from '@/components/PromoCodeModal.vue';

	export default {
		name: 'LandingpageFooter',
		components: { PromoCodeModal },
		props: {
			openBonusCodeModal: {
				type: Boolean,
				default() { return false; } 
			}
		},
		methods: {
			modalPromoCode() {
				this.$modal.show('promoCodeModal');
			},
			scrollToTop() {
				window.scrollTo({ top: 0, behavior: 'smooth' })
			},
		},
		watch: {
			openBonusCodeModal: {
				immediate: true,
				/* eslint-disable no-unused-vars */
				handler: function(newVal, oldVal) {
					if (newVal == true) {
						this.modalPromoCode();
					}
				}
			}
		}
	};
</script>

<style lang="scss">
	.footer {
		padding: 54px 16px 40px;
		background: $colorWhite;
		box-shadow: 0 0 16px 0 rgba(0,0,0,0.02);

		@media screen and (min-width: 768px) {
			padding: 44px 24px 40px;
		}

		.landingpage--home & {
			box-shadow: 0 -1px 0 0 rgba($colorBlack, .08);

			@media screen and (min-width: 768px) {
				margin-top: -32px;
			}
		}

		&__content {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			max-width: 1040px;
			margin: 0px auto;

			@media screen and (min-width: 768px) {
				flex-direction: row;
			}
		}

		ul {
			flex: 1;
			list-style-type: none;
			padding: 0 !important;

			@media screen and (min-width: 768px) {
				padding: 0 24px 0 0 !important;
			}

			li {

				&:first-child {
					font-size: 11px;
					font-weight: 600;
					letter-spacing: -0.23px;
					color: rgba($colorBlack, .48);
					text-transform: uppercase;
					margin-bottom: 10px;

					@media screen and (min-width: 768px) {
						margin-bottom: 18px;
					}
				}

				.router-link-active, a {
					font-size: 14px;
					font-weight: 600;
					line-height: 30px;
					letter-spacing: -0.29px;
					color: $colorBlack;
					text-decoration: none;
				}
			}

			&.download {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				order: 1;

				@media screen and (min-width: 768px) {
					flex-direction: column;
					margin-right: 64px;
					justify-content: flex-start;
				}

				li {
					width: calc(50% - 4px);

					@media screen and (min-width: 330px) {
						width: calc(50% - 8px);
					}

					@media screen and (min-width: 768px) {
						width: 100%;
					}

					&:first-child {
						display: none;

						@media screen and (min-width: 768px) {
							display: flex;
						}
					}
				}

				.download__button {
					width: 100%;
					margin: 8px 0;
					justify-content: center;

					@media screen and (max-width: 330px) {
						padding: 12px 8px 12px 8px;

						span {
							font-size: 14px;
						}
					}

					@media screen and (min-width: 768px) {
						max-width: 180px;
						margin: 8px 0 4px 0;
						justify-content: flex-start;
					}
				}
			}

			&.shop, &.company, &.rules {
				flex: inherit;
				width: calc((100% - 16px) / 3);
				order: 3;

				@media screen and (min-width: 768px) {
					flex: 1;
					width: auto;
					order: 2;
				}

				li {
					box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .04);

					&:first-child, &:last-child {
						box-shadow: none;
					}

					@media screen and (min-width: 768px) {
						box-shadow: none;
					}
				}
			}

			&.social {
				display: flex;
				flex-direction: row;
				order: 2;
				margin: 32px 0 30px;

				@media screen and (min-width: 768px) {
					flex-direction: column;
					order: 3;
					margin: 0;
				}

				li {
					flex: 1;
					width: calc((100vw - 32px) / 3);

					@media screen and (min-width: 768px) {
						flex: initial;
						width: initial;
					}

					a {
						display: flex;
						flex-direction: column;
						align-items: center;

						@media screen and (min-width: 768px) {
							align-items: flex-start;
						}
					}

					img {
						width: 32px;
						height: 32px;

						@media screen and (min-width: 768px) {
							display: none;
						}
					}

					&:first-child {
						display: none;

						@media screen and (min-width: 768px) {
							display: flex;
						}
					}
				}
			}
		}

		.back-to-top {
			position: absolute;
			top: -86px;
			right: calc(50% - 27px);
			cursor: pointer;

			@media screen and (min-width: 768px) {
				top: -76px;
				right: 0;
			}
		}
	}

	.promobox {
		font-size: 14px;
		font-weight: 600;
		color: rgba($colorBlack, .48);
		text-align: center;
		padding: 19px 0 18px;
		box-shadow: inset 0 1px 0 0 rgba($colorBlack, .08);
		width: calc(100% + 32px);
		margin: 40px -16px 0;

		@media screen and (min-width: 768px) {
			margin: 40px -24px 0;
			width: calc(100% + 48px);
		}

		span {
			color: $colorPrimary;
			text-decoration: underline;
			cursor: pointer;
		}
	}
</style>

<template>
    <!-- Fitnessplan Detail Modal -->
    <VModal name="fitnessplanDetail" width="100%" height="auto" :shiftY="1" :scrollable="true"
        class="fitnessplanModal">
        <div class="vm-header">
            <div class="vm-header-left">
                <img src="@/assets/ic_nav_close_white.svg" alt="" @click="closeFitnessplanDetailModal">
            </div>
            <div class="vm-header-center">
            </div>
            <div class="vm-header-right" @click="shareChallenge">
                <img src="@/assets/ic_nav_share_white.svg" alt="">
            </div>
        </div>
        <div class="vm-content">
            <div class="fitnessplanModal__header">
                <div class="fitnessplanModal__header__badge">
                    {{ challenge.badge }}
                </div>
                <h2>{{ challenge.title }}</h2>
                <img :src="'https://app.sportlerplus.com/scaled/800/' + challenge.image_url" alt="">
            </div>

            <div class="fitnessplanModal__content">
                <Slider :pagination="true">
                    <splide-slide v-for="slide in challenge.slides" v-bind:key="slide.id">
                        <div v-html="slide.infotext"></div>
                    </splide-slide>
                    <splide-slide v-if="challenge.equipment && challenge.equipment.length > 0">
                        <div>
                            <h5>Benötigtes Equipment</h5>
                            <ul>
                                <li v-for="eq in challenge.equipment" v-bind:key="eq.id">{{ eq.name }}</li>
                            </ul>
                        </div>
                    </splide-slide>
                </Slider>
            </div>
            <v-btn class="button button--primary" @click="chooseStartDateOfChallenge">Challenge auswählen</v-btn>
        </div>
    </VModal>
</template>
<script>
import {SplideSlide} from '@splidejs/vue-splide';
import Slider from "@/components/Slider.vue";
import { sharePage } from '@/sportlerplus-helper';

export default {
    name: 'FitnessplanDetailModal',
    components: {Slider, SplideSlide},
    methods: {
        closeFitnessplanDetailModal() {
            this.$modal.hide('fitnessplanDetail');
        },
        chooseStartDateOfChallenge() {
            this.$router.push("/fitnessplan/challenge/selectDate?id=" + this.challenge.id);
        },
        shareChallenge() {
            let shareUrl = window.location.origin + "/sharing/fitnessplan/challenges?challengeId=" + this.challenge.id;
            let shareText = "Hey, ich habe gerade diese Challenge in der SportlerPlus-App entdeckt: „{0}“.\n\nProbiere sie direkt aus! 💪🚀".format(this.challenge.title);
            sharePage(shareUrl, null, shareText, null);
        }
    },
    props: {
        challenge: {
            type: Object,
            default() { return {}; } 
        }
    },
    data() {
        return {

        }
    },
}
</script>
<style lang="scss" scoped>
.fitnessplanModal {
    .vm-header {
        position: relative;
        z-index: 1;
    }

    &__header {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: flex-start;
        z-index: 0;
        min-height: 240px;
        margin: -62px -20px 0;
        padding: 0 20px;

        @media screen and (min-width: 768px) {
            margin: -62px -40px 0;
        }

        &__badge {
            position: relative;
            z-index: 1;
            margin-top: auto;
            display: inline-flex;
            font-size: 11px;
            line-height: 11px;
            font-weight: 700;
            letter-spacing: -0.13px;
            text-transform: uppercase;
            padding: 4px 5px 3px 6px;
            margin-bottom: 5px;
            border-radius: 2px;
            color: $colorWhite;
            background: $colorPrimary;
        }

        h2 {
            position: relative;
            z-index: 1;
            color: $colorWhite;
            text-transform: uppercase;
            font-size: 32px;
            line-height: 32px;
            margin: 6px 0 19px;
        }

        img {
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            left: 0;
            top: 0;
        }
    }

    &__content {
        margin: 0 -20px 20px;

        h5 {
            color: #f0f;
        }
    }

    .splide {

        &__slide {
            background: #FFFFFF;
            box-shadow: 0 8px 16px 0 rgba($colorBlack, .08), inset 0 0 0 1px rgba($colorBlack, .08);
            border-radius: 4px;
            margin: 32px 0;
            padding: 18px 16px;
        }
    }
}
</style>

<style lang="scss">
.fitnessplanModal {
    .vm-header-right {
        img {
            opacity: 1;
        }
    }

    .splide {
        &__slide {
            h5 {
                font-size: 17px;
                font-weight: 600;
                color: $colorBlack;
            }

            ul {
                list-style-type: none;
                padding: 0;
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                color: $colorBlack;

                li {
                    position: relative;
                    margin-top: 16px;
                    padding-left: 36px;

                    &:before {
                        content: url('../assets/ic_premium_check.svg');
                        position: absolute;
                        top: -4px;
                        left: 0;
                        width: 24px;
                        height: 28px;
                    }
                }
            }
        }

        &__pagination {
            &__page {
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background: rgba($colorBlack, .24);

                &.is-active {
                    background: rgba($colorBlack, .64);
                    transform: inherit;
                }
            }
        }
    }
}
</style>
<template>

  <div class="register">
    
    <h2>Welche Sportart(en) betreibst Du?</h2>
    <small>Auf Basis dieser Angabe schlagen wir dir Workouts und Fitnesspläne vor.</small>

    <div class="selection-cards selection-cards--threecolumn">
      <div class="selection-cards__item" :key="sports_type.id" v-for="sports_type in filtered_sports_types">
        <input :id="sports_type.name" :name="sports_type.name" v-model="sports_type.checked" type="checkbox" value="0">
        <label :for="sports_type.name">
          <img v-if="hasIcon(sports_type)" class="selection-cards__item__icon" :src="getSportsTypeImage(sports_type.name, false)" alt=""/>
          <div class="selection-cards__item__label">{{ sports_type.name }}</div>
        </label>
      </div>
    </div>

    <small v-if="limit != null" class="more-sports" @click="removeFilter">Mehr Sportarten <img src="@/assets/ic_12_arrow_down.svg"></small>
    
    <!-- Hint Modal -->
    <VModal name="registerHintModal" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
      <div class="vm-content">
        <img src="@/assets/notification/ic_notification_settings.svg" alt="">
        <h3>
          Auf deine Bedürfnisse optimiertes Training!
        </h3>
        <p>
          Um die Trainingsinhalte für dich und deine Bedürfnisse anzupassen, benötigen wir vorab ein paar Informationen von dir.
        </p>
        <v-btn @click="closeRegisterHintModal" type="button" class="button button--primary button--small">Alles klar</v-btn>
      </div>
    </VModal>

    <v-btn class="button button--primary" @click="onboarding_continue">Weiter</v-btn>

  </div>
</template>

<script>
import { backButtonUsed } from '@/router/index.js';

import router from '@/router'
export default {
    name: "OnboardingStep2",
    data() {
        return {
            sports_types: [],
            selected_sports_types: [],
            userInformation: null,
            limit: 9,
        }
    },
    methods: {
        init(){
            this.userInformation = this.$store.getters.getOnboardingData;

            if (!backButtonUsed) { this.$modal.show("registerHintModal"); }

            this.$store.dispatch('fetchSportsTypes').then( () => {
                this.sports_types = this.$store.getters.getSportsTypes;
                this.sports_types.forEach((st) => st.checked = false);
               
                if (this.userInformation && this.userInformation.sports_types) {
                    this.userInformation.sports_types.forEach(st => {
                        this.sports_types.find(s => s.id == st.id).checked = true;
                    });
                }
            });
        },
        closeRegisterHintModal() {
            this.$modal.hide("registerHintModal");
        },
        onboarding_continue() {
            this.sports_types.forEach((st) => {
                if (st.checked) { this.selected_sports_types.push(st); }
            });

            this.$store.dispatch('updateOnboardingData', {
                sports_types: this.selected_sports_types
            });
            router.push("/onboarding-step3");
        },
        removeFilter() {
            this.limit = null;
        },
        hasIcon(sports_type) {
            return [1, 3, 6, 2, 5, 7, 8, 9, 10].indexOf(sports_type.id) != -1;
        },
        getSportsTypeImage(sports_type_name, active) {
            sports_type_name = sports_type_name.toLowerCase();
            sports_type_name = sports_type_name.replace(/ä/g, 'ae');
            sports_type_name = sports_type_name.replace(/ö/g, 'oe');
            sports_type_name = sports_type_name.replace(/ü/g, 'ue');
            sports_type_name = sports_type_name.replace(/ß/g, 'ss');
            sports_type_name = sports_type_name.replace(/ /g, '_');
            sports_type_name = sports_type_name.replace(/\./g, '');
            sports_type_name = sports_type_name.replace(/,/g, '');
            sports_type_name = sports_type_name.replace(/\(/g, '');
            sports_type_name = sports_type_name.replace(/\)/g, '');

            try {
                if (active) {
                    return require('@/assets/ic_32_sport_'+ sports_type_name + '-active.svg');
                }
                return require('@/assets/ic_32_sport_'+ sports_type_name + '.svg');
                }
            catch(error) {
                return "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
            }
        }
  },
  components: {

  },
  computed: {
      filtered_sports_types() {
          if (this.limit) {
              return this.sports_types.slice(0, this.limit).filter(st => st.user_selectable);
          }
          else {
              return this.sports_types.filter(st => st.user_selectable);
          }
      }
  },
  mounted() {
    this.init();
  },
}
</script>

<style lang="scss">
  .register {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    text-align: center;

    h2 {
      margin: 24px 0 32px;
    }

    small {
      font-weight: 500;
      margin: -12px 0 24px;
    }

    .button {
      position: sticky;
      bottom: 16px;
      margin-top: auto;
    }

    .selection {
      background: rgba($colorBlack, .04);
      border-radius: 8px;
      padding: 0 16px;
      overflow: hidden;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &__item {
        display: flex;

        &:not(:last-child) {
          box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .04);
        }

        input {
          display: none;

          &:checked {
            ~ label {
              background: rgba($colorPrimary, .08);

              .selection__item__label {
                color: $colorPrimary;
              }

              .selection__item__icon {
                display: none;

                &--active {
                  display: flex;
                }
              }
            }
          }
        }

        label {
          display: flex;
          align-items: center;
          padding: 16px 16px;
          width: calc(100% + 32px);
          margin: 0 -16px;
        }
        
        &__icon {
          flex-shrink: 0;
          width: 32px;
          height: 32px;

          &--active {
            display: none;
          }
        }

        &__label {
          font-size: 17px;
          line-height: 20px;
          font-weight: 600;
          color: $colorBlack;
          width: 100%;
          padding-right: 32px;
          text-align: center;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>

  .more-sports {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -22px 0 23px;
    cursor: pointer;
    padding: 8px 0;
    font-weight: 600;
    
    img {
      margin-left: 5px;
    }
  }

  .selection-cards {
    margin-bottom: 32px;
  }

  .selection-cards__item {
    &:has(.selection-cards__item__icon) {
        .selection-cards__item__label {
            line-height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 16px;
            white-space: inherit;
            text-overflow: inherit;
            overflow: visible;
        }
    }

    .selection-cards__item__label {
        line-height: 14px;
        white-space: inherit;
        text-overflow: inherit;
        overflow: visible;
    }
  }
</style>
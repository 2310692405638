<template>
    <VModal name="exerciseInfo" class="centered" width="100%" height="auto" :scrollable="true">
      <div class="vm-header">
        <div class="vm-header-left">
        </div>
        <div class="vm-header-center">
          So funktioniert's
        </div>
        <div class="vm-header-right">
          <img src="@/assets/ic_nav_close.svg" alt="" @click="closeInfoModal">
        </div>
      </div>
      <div class="vm-content">
        <ul class="exerciseInfo__features" v-html="hintsList">
        </ul>
        <v-btn type="button" class="button button--primary button--small" @click="closeInfoModal">Alles klar</v-btn>
      </div>
    </VModal>
</template>
<script>
export default {
    name: 'ExerciseInfoModal',
    props: {
        exercise: {
            type: Object,
            default() { return {}; } 
        }
    },
    methods: {
        closeInfoModal() {
            this.$modal.hide('exerciseInfo');
            this.$emit("closeInfoModal");
        }
    },
    computed: {
        hintsList() {
            var re = new RegExp("<ul>(.*?)</ul>");
            if (this.exercise) {
                let hints = re.exec(this.exercise.hints);
                if (hints) {
                return hints[1];
                }
            }
            return "";
        }
    }
}
</script>
<style lang="scss">
.exerciseInfo {
    &__features {
      padding: 0 !important;
      margin: 0 0 16px;

      li {
        padding: 11px 0 11px 36px;
        position: relative;
        list-style-type: none;
        font-size: 14px;
        letter-spacing: -0.2px;
        font-weight: 600;
        line-height: 20px;
        color: $colorBlack;

        &:before {
          content: '';
          position: absolute;
          top: 8px;
          left: 0;
          width: 24px;
          height: 28px;
          background: url('../assets/ic_premium_check.svg')
        }
      }
    }
  }
</style>
<template>
    <div class="register">
        <h2>Wie möchtest du dich registrieren?</h2>

        <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field :disabled="loginType != 0" name="name" placeholder="Vorname" type="text"
                          v-model="name" required>
            </v-text-field>
            <v-text-field :disabled="loginType != 0" validate-on-blur name="email" placeholder="E-Mail-Adresse" type="email"
                          v-model="email" :rules="emailRules" required>
            </v-text-field>
            <v-text-field :disabled="loginType != 0" validate-on-blur name="password" placeholder="Passwort" id="password"
                          type="password" required v-model="password" :rules="passwordRules">
            </v-text-field>
        </v-form>

        <div class="divider">
            oder
        </div>

        <div class="selection-cards selection-cards--onecolumn">

            <!-- Apple -->
            <div class="selection-cards__item">
                <input @click="checkDeselect(3)" v-model="loginType" id="apple" name="loginType" type="radio" value="3">
                <label for="apple">
                    <img class="selection-cards__item__icon" src="@/assets/ic_24_apple.svg" alt=""/>
                    <div class="selection-cards__item__label">Mit Apple anmelden</div>
                </label>
            </div>

            <!-- Facebook -->
            <div class="selection-cards__item">
                <input @click="checkDeselect(1)" v-model="loginType" id="facebook" name="loginType" type="radio" value="1">
                <label for="facebook">
                    <img class="selection-cards__item__icon" src="@/assets/ic_24_facebook.svg" alt=""/>
                    <div class="selection-cards__item__label">Mit Facebook anmelden</div>
                </label>
            </div>

            <!-- Google -->
            <div class="selection-cards__item">
                <input @click="checkDeselect(2)" v-model="loginType" id="google" name="loginType" type="radio" value="2">
                <label for="google">
                    <img class="selection-cards__item__icon" src="@/assets/ic_24_google.svg" alt=""/>
                    <div class="selection-cards__item__label">Mit Google anmelden</div>
                </label>
            </div>

        </div>

        <div class="legal-inputs">
            <div class="legal-inputs__item">
                <input v-model="agreeTOS" id="nutzungsbedingungen" name="nutzungsbedingungen" type="checkbox">
                <label for="nutzungsbedingungen">
                    Ich akzeptiere die <router-link to="/info/48">Nutzungsbedingungen</router-link> und habe die <router-link to="/info/49">Datenschutzhinweisen</router-link> zur Kenntnis genommen.
                </label>
            </div>
        </div>

        <v-btn :disabled="!agreeTOS || (!valid && loginType == 0)" class="button button--primary" @click="onboarding_continue">Weiter</v-btn>
    </div>
</template>

<script>
import firebase from 'firebase';

export default {
    name: "OnboardingStep4",
    methods: {
        init() {
            this.userInformation = this.$store.getters.getOnboardingData;
        },
        checkDeselect(val) {
            if (this.previousLoginType == val) {
                this.loginType = 0;
            }
            this.previousLoginType = val;
        },
        onboarding_continue() {
            if (!this.agreeTOS) { return; }
            if (this.loginType == 0 ) {
                this.registerUserByEmail();
                return;
            }

            // social login (register)
            let provider = ""
            if (this.loginType == 1) {
                provider = "facebook";
            }
            else if (this.loginType == 2) {
                provider = "google";
            }
            else if (this.loginType == 3) {
                provider = "apple";
            }
            this.$store.dispatch('socialLogin', {providerString: provider, redirect: "/trackingHint"}).then(() => {
                this.$store.dispatch('saveUserInformation', this.userInformation);
            });
        },
        registerUserByEmail() {
            if (this.$refs.form.validate()) {
                this.$store.dispatch('userRegister', {
                    name: this.name,
                    email: this.email,
                    password: this.password
                });
            }

            // FIXME: prüfen, ob nötig. Das push() auf "/welcome" wird bereits oben durch userRegister 
            // ausgeführt, klappt nur scheinbar manchmal nicht
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    // User is signed in.
                    this.$router.push("/welcome").catch( error => {
                        if ( error ) {
                            console.warn( `consumed router exception: ${error}` );
                        } else {
                            console.warn( `consumed unknown router exception ${new Error().stack.replace( /^[\s\S]+?at/, "at" )}` );
                        }
                    });
                }
            });
        }
    },
    mounted() {
        this.init();
    },
    data() {
        return {
            valid: false,
            agreeTOS: false,
            loginType: 0,
            previousLoginType: 0,
            userInformation: null,
            name: '',
            email: '',
            password: '',
            emailRules: [
                v => !!v || 'Eine Email-Adresse ist notwendig',
                v => /.+@.+/.test(v) || 'Die Adresse ist nicht im gültigen Format.'
            ],
            passwordRules: [
                v => !!v || 'Ein Passwort ist notwendig.',
                v =>
                    v.length >= 6 ||
                    'Das Passwort muss länger als 6 Zeichen sein.'
            ]
        }
    }
}
</script>

<style lang="scss">

    .legal-inputs {
        margin-bottom: 16px;

        &__item {
            display: flex;
            align-items: center;
            font-size: 11px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.3px;
            text-align: left;

            a {
                color: rgba($colorBlack, .64) !important;
            }

            input {
                display: none;

                &:checked {
                    ~ label {
                        color: $colorBlack;

                        a {
                            color: $colorBlack !important;
                        }

                        &:before {
                            background: url('../assets/ic_checkbox-active.svg');
                        }
                    }
                }
            }

            label {
                position: relative;
                padding: 18px 0 18px 32px;
                color: rgba($colorBlack, .48);
                cursor: pointer;

                &:before {
                    content: '';
                    position: absolute;
                    top: 26px;
                    left: 2px;
                    width: 20px;
                    height: 20px;
                    background: url('../assets/ic_checkbox.svg');
                }
            }

            &__icon {
                width: 24px;
                height: 24px;
            }

            &__text {
                padding: 18px 0 18px 16px;
            }
        }
    }
</style>

<style lang="scss" scoped>
    .button {
        position: relative;
        bottom: 0;
    }

    .v-text-field {
        margin-bottom: 8px !important;
    }

    .v-input--is-disabled {
        opacity: .64;
    }

    .selection-cards {
        label {
            flex-direction: row !important;
        }

        .selection-cards__item__label {
            width: auto !important;
            font-size: 14px;
            font-weight: 600;
            margin: 0;
        }

        .selection-cards__item__icon {
            margin: 0 10px 0 0;
        }
    }

    .divider {
        font-size: 11px;

        &:before, &:after {
            top: 7px;
        }
    }
</style>
<template>
	<div class="menu">
		<nav>
			<ul>
				<li class="logo" @click="closeBurger">
					<router-link to="/landingpage">
						<SportlerPlusLogo></SportlerPlusLogo>
					</router-link>
				</li>
				<li :class="activeTab == 'app' ? 'active' : ''">
					<router-link to="/landingpage">
						Home
					</router-link>
				</li>
				<!-- <li :class="activeTab == 'story' ? 'active' : ''">
					<router-link to="/story">
						Story
					</router-link>
				</li> -->
				<li :class="activeTab == 'athleten' ? 'active' : ''">
					<router-link to="/athleten">
						Athleten
					</router-link>
				</li>

				<li :class="activeTab == 'teams' ? 'active' : ''">
					<router-link to="/teams">
						Teams
					</router-link>
				</li>
				<li :class="activeTab == 'magazine' ? 'active' : ''">
					<router-link to="/magazine">
						Magazin
					</router-link>
				</li>
				<li>
					<a href="https://shop.sportlerplus.de/?utm_source=website&utm_medium=top+right&utm_campaign=sportlerplus+referrals" target="_blank">
						Shop
					</a>
				</li>
				<!-- TODO @Marco: Das Burger-Menü funktioniert aktuell nicht -->
				<li class="burgerMenu__icon" @click="openBurger">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</li>
			</ul>
		</nav>
		<div class="burgerMenu">
			<ul class="burgerMenu__content">
				<li @click="closeBurger">
					<router-link to="/landingpage" :class="activeTab == 'app' ? 'active' : ''">
						<img src="@/assets/ic_burger_home.svg" alt="">
						<img src="@/assets/ic_burger_home-active.svg" alt="">
						Home
					</router-link>
				</li>
				<!-- <li @click="closeBurger">
					<router-link to="/story" :class="activeTab == 'story' ? 'active' : ''">
						<img src="@/assets/ic_burger_story.svg" alt="">
						<img src="@/assets/ic_burger_story-active.svg" alt="">
						Story
					</router-link>
				</li> -->
				<li @click="closeBurger">
					<router-link to="/athleten" :class="activeTab == 'athleten' ? 'active' : ''">
						<img src="@/assets/ic_burger_athleten.svg" alt="">
						<img src="@/assets/ic_burger_athleten-active.svg" alt="">
						Athleten
					</router-link>
				</li>
				<li @click="closeBurger">
					<router-link to="/teams" :class="activeTab == 'teams' ? 'active' : ''">
						<img src="@/assets/ic_burger_teams.svg" alt="">
						<img src="@/assets/ic_burger_teams-active.svg" alt="">
						Teams
					</router-link>
				</li>
				<li @click="closeBurger">
					<router-link to="/magazine" :class="activeTab == 'magazine' ? 'active' : ''">
						<img src="@/assets/ic_burger_magazin.svg" alt="">
						<img src="@/assets/ic_burger_magazin-active.svg" alt="">
						Magazin
					</router-link>
				</li>
				<li @click="closeBurger">
					<a href="https://shop.sportlerplus.de/?utm_source=website&utm_medium=top+right&utm_campaign=sportlerplus+referrals" target="_blank">
						<img src="@/assets/ic_burger_shop.svg" alt="">
						<img src="@/assets/ic_burger_shop-active.svg" alt="">
						Shop
					</a>
				</li>
			</ul>
			<ul class="burgerMenu__footer">
				<li>
					<router-link to="/info/48">
						AGB
					</router-link>
				</li>
				<li>
					<router-link to="/info/49">
						Datenschutz
					</router-link>
				</li>
				<li>
					<router-link to="/info/47">
						Impressum
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import SportlerPlusLogo from "@/components/SportlerPlusLogo.vue";

export default {
	name: 'LandingpageHeader',
	components: { SportlerPlusLogo },
	props: {
		activeTab: {
            type: String,
            default() {
                return "app";
            }
        },
	},
	methods: {
		openBurger() {
			[...document.getElementsByTagName('html')].forEach(el => el.classList.toggle('nav-open'))
		},
		closeBurger() {
			[...document.getElementsByTagName('html')].forEach(el => el.classList.remove('nav-open'))
		}
	}
};
</script>

<style lang="scss">
	nav {
		position: fixed;
		top: 0;
		z-index: 998 !important;
		background: $colorWhite;
		box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .08);
		width: 100%;

		.landingpage--dark & {
			@media screen and (min-width: 768px) {
				background: none;
			}
		}

		.nav-open & {
			box-shadow: none;
		}

		@media screen and (min-width: 768px) {
			position: relative;
			box-shadow: none;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-around;
			max-width: 1088px;
			margin: 0px auto;
			padding: 0 16px !important;

			@media screen and (min-width: 768px) {
				padding: 0 24px !important;
			}

			li {
				position: relative;
				display: none;
				justify-content: center;
				list-style-type: none;
				font-size: 14px;
				font-weight: 700;
				color: rgba($colorBlack, .48);
				letter-spacing: -0.29px;
				text-transform: uppercase;
				padding: 6px 0;

				img.home_icon {
					max-height: 18px;
					margin-bottom: -4px;
					opacity: 0.48;
				}

				@media screen and (min-width: 768px) {
					display: flex;
					width: inherit;

					&:not(:first-child) {
						margin-left: 36px;

						&:before {
							content: '';
							position: absolute;
							left: 50%;
							bottom: 0;
							width: 0;
							height: 2px;
							background: rgba($colorBlack, .24);
							transition: all .2s ease-in-out;

							.landingpage--dark & {
								background: rgba($colorWhite, .24);
							}
						}

						&:hover {
							&:before {
								left: 0;
								width: 100%;
							}
						}
					}
				}

				a {
					display: flex;
					color: rgba($colorBlack, .48) !important;
					text-decoration: none;

					.landingpage--dark & {
						color: $colorWhite !important;
					}
				}

				&.active {

					&:before {
						content: '';
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 2px;
						background: $colorPrimary;

						.landingpage--dark & {
							background: $colorPrimary;
						}
					}

					a {
						color: $colorBlack !important;

						.landingpage--dark & {
							color: $colorWhite !important;
						}
					}

					img.home_icon {
						max-height: 18px;
						margin-bottom: -4px;
						opacity: 1;
					}
				}

				&.logo {
					display: flex;
					margin-right: auto;
					padding: 16px 0;

					@media screen and (min-width: 768px) {
						padding: 48px 0;
					}
				}

				&.burgerMenu__icon {
					display: flex;
					position: relative;
					width: 24px;
					height: 16px;
					cursor: pointer;

					@media screen and (min-width: 768px) {
						display: none;
					}

					span {
						position: absolute;
						left: 0;
						width: 100%;
						height: 2px;
						background: $colorBlack;

						&:nth-child(1) {
							top: 0;
							transition: .2s ease-in-out;

							.nav-open & {
								opacity: 0;
								top: -8px;
							}
						}

						&:nth-child(2) {
							top: calc(50% - 1px);
							transition: .2s ease-in-out;

							.nav-open & {
								transform: rotate(45deg);
								transform-origin: 50% 50%;
							}
						}

						&:nth-child(3) {
							top: calc(50% - 1px);
							transition: .2s ease-in-out;

							.nav-open & {
								transform: rotate(-45deg);
								transform-origin: 50% 50%;
							}
						}

						&:nth-child(4) {
							bottom: 0;
							transition: .2s ease-in-out;

							.nav-open & {
								opacity: 0;
								bottom: -8px;
							}
						}
					}
				}
			}
		}
	}

	.burgerMenu {
		flex-direction: column;
		position: fixed;
		z-index: 997;
		top: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		background: $colorWhite;
		opacity: 0;
		display: none;
		overflow-y: scroll;
		height: 0;

		@keyframes openMenuWrapper {
			from {height: 0%;}
			to {height: 100%;}
		}

		.nav-open & {
			opacity: 1;
			display: flex;
			animation: openMenuWrapper .2s ease-in-out forwards;
		}

		&__content {
			padding: 72px 16px 16px !important;

			li {
				position: relative;
				list-style-type: none;
				opacity: 0;

				&:before {
					content: '';
					position: absolute;
					z-index: -1;
					top: 0;
					left: -16px;
					width: calc(100% + 32px);
					height: 100%;
					background: rgba($colorBlack, .02);
					opacity: 0;
					transition: all .2s ease-in-out;
				}

				&:after {
					content: '';
					position: absolute;
					z-index: -1;
					top: calc(50% - 12px);
					right: 0;
					width: 24px;
					height: 24px;
					background: url('../assets/ic_nav_next.svg');
					opacity: .24;
					transition: all .2s ease-in-out;
				}

				&:hover {

					&:before {
						opacity: 1;
					}

					&:after {
						opacity: .4;
					}
				}

				&:not(:last-child) {
					box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .08);
				}
				
				a {
					display: flex;
					align-items: center;
					padding: 17px 0;
					font-size: 17px;
					font-weight: 600;
					color: $colorBlack;
					text-decoration: none;

					&.active {
						color: $colorPrimary;
						
						img {
							&:first-child {
								display: none;
							}

							&:last-child {
								display: flex;
							}
						}
					}

					img {
						width: 40px;
						height: 46px;
						margin-right: 16px;
						
						&:first-child {
							display: flex;
						}

						&:last-child {
							display: none;
						}
					}
				}

				/* Animation of Items */

				@keyframes openMenuItems {
					from {opacity: 0; transform: translateY(40px);}
					to {opacity: 1; transform: translateY(0);}
				}

				.nav-open & {
					animation: openMenuItems .2s ease-in-out forwards;
				}

				&:nth-child(1) {
					animation-delay: .25s;
				}
				&:nth-child(2) {
					animation-delay: .4s;
				}
				&:nth-child(3) {
					animation-delay: .5s;
				}
				&:nth-child(4) {
					animation-delay: .55s;
				}
				&:nth-child(5) {
					animation-delay: .575s;
				}
				&:nth-child(6) {
					animation-delay: .6s;
				}
			}
		}

		&__footer {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 17px 16px 19px 16px !important;
			background: rgba($colorBlack, .04);
			margin-top: auto;
			opacity: 0;

			@keyframes openMenuFooter {
				from {opacity: 0;transform: translateY(60px);}
				to {opacity: 1;transform: translateY(0)}
			}

			.nav-open & {
				animation: openMenuFooter .2s ease-in-out forwards;
				animation-delay: .8s;
			}

			li {
				position: relative;
				list-style-type: none;
				margin: 0 12px;

				&:before {
					content: '';
					position: absolute;
					left: 0;
					bottom: 1px;
					width: 100%;
					height: 2px;
					background: rgba($colorBlack, .08);
				}

				a {
					font-size: 14px;
					font-weight: 600;
					letter-spacing: -0.29px;
					color: rgba($colorBlack, .48);
					text-decoration: none;
				}
			}
		}
	}
</style>

<template>
	<div>
		<LandingpageHeader></LandingpageHeader>

		<!-- HEADER -->
		<div class="header">
			<div class="header__text">
				<h1>Bring dein Gameplay auf das nächste <span>Level</span>!</h1>
				<p>Mit unserer <span>Sports Nutrition</span>, der kostenlosen <span>Workout App</span> &amp; spannenden Magazin-Inhalten helfen wir dir, dein volles Potenzial zu erreichen.</p>
			</div>

			<!-- <div class="header__image">
				<img src="@/assets/welcome-image.jpg" srcset="@/assets/welcome-image@2x.jpg 2x" alt="">
			</div> -->

			<div class="header__video">
				<video poster="@/assets/landingpage/startbild_default_header-video.jpg" id="bgvid" playsinline autoplay muted loop>
					<!-- <source src="@/assets/landingpage/Brand_Page_Header_Video_Neu_mit_Filter.webm" type="video/webm"> -->
					<source src="@/assets/landingpage/Brand_Page_Header_Video_Neu_mit_Filter.mp4" type="video/mp4">
				</video>
			</div>
		</div>

		<div v-if="topDealBanner" class="dealbox">
			<div class="dealbox__image">
				<img :src="'https://app.sportlerplus.com' + topDealBanner.image.meta.download_url" alt="Shop Deal">
			</div>
			<div class="dealbox__text">
				<div class="dealbox__label dealbox__label--mobile">
					<span>{{ topDealBanner.headline }}</span> {{ topDealBanner.line_1_mobile }}
				</div>
				<div class="dealbox__label dealbox__label--desktop">
					<span>{{ topDealBanner.headline }}</span> {{ topDealBanner.line_1 }}
				</div>
				<div class="dealbox__sublabel">
					{{ topDealBanner.line_2 }}
				</div>
			</div>
			<a :href="topDealBanner.link" v-if="topDealBanner.banner_target == 2" target="_blank" class="button button--primary button--small button--compact">{{ topDealBanner.cta_text }}</a>
			<a :href="topDealBanner.link" v-else class="button button--primary button--small button--compact">{{ topDealBanner.cta_text }}</a>

		</div>

		<!-- FEATURES -->
		<div class="feature">
			<div class="feature__image">
				<img src="@/assets/landingpage/feature-nutrition.jpg" srcset="@/assets/landingpage/feature-nutrition@2x.jpg 2x" alt="Sports Nutrition Trinkflasche">
			</div>
			<div class="feature__text">
				<div class="feature__text__category"><img src="@/assets/landingpage/ic_feature_nutrition.svg" alt="">Ernährung</div>
				<h2>Sports Nutrition</h2>
				<p>Wir liefern dir die perfekte Nutrition für vor, während und nach dem Sport. Mit unseren Produkten sind Teams und Einzelsportler/innen für Trainings und Wettkämpfe bestens aufgestellt.</p>
				<a href="https://shop.sportlerplus.de/?utm_source=website&utm_medium=top+right&utm_campaign=sportlerplus+referrals" target="_blank" class="button button--primary button--small button--compact button--icon"><img src="@/assets/ic_24_shop_white.svg" class="size-24" />Jetzt einkaufen</a>
			</div>
		</div>

		<div class="feature">
			<div class="feature__image">
				<img src="@/assets/landingpage/feature-workout.jpg" srcset="@/assets/landingpage/feature-workout@2x.jpg 2x" alt="Workout App SportlerPlus">
			</div>
			<div class="feature__text">
				<div class="feature__text__category"><img src="@/assets/landingpage/ic_feature_training.svg" alt="">Training</div>
				<h2>Workout App</h2>
				<p>Trainiere so individuell und persönlich wie noch nie mit unserer kostenlosen Workout-App. Durch den persönlichen Filter wählst du Workout-Typ, Intensität, Equipment uvm. genau nach deinem Geschmack aus. Dazu kannst du an Challenges teilnehmen und deinen eigenen Fitnessplan erstellen.</p>
				<div class="feature__buttons">
					<a href="https://apps.apple.com/de/app/sportlerplus/id1533773413" target="_blank" class="download__button">
						<div class="download__button__icon">
							<img src="@/assets/ic_appstore_apple.svg" alt="Download Apple AppStore" />
						</div>
						<div class="download__button__text">
							Download on the
							<span>AppStore</span>
						</div>
					</a>
					<a href="https://play.google.com/store/apps/details?id=com.sportplatzmedia.sportlerplus" target="_blank" class="download__button">
						<div class="download__button__icon">
							<img src="@/assets/ic_appstore_google.svg" alt="Download GooglePlay" />
						</div>
						<div class="download__button__text">
							Get it on
							<span>GooglePlay</span>
						</div>
					</a>
				</div>
			</div>
		</div>

		<!-- TEAMBOX -->
		<Teambox></Teambox>

		<!-- MAGAZIN -->
		<div class="content-block">
			<div class="content-block__content">
				<h3>Magazin</h3>
				<p>Wir haben interessante Artikel, Rezepte, Hacks u.v.m. rund um die Themen Training & Ernährung für dich zusammen gestellt. Erweitere deinen Horizont und verbessere dein Gameplay!</p>
				<div class="content-block__slider">
					<splide :options="sliderOptionsMagazine" :slides="articles">
						<splide-slide v-for="article in articles" :key="article.id">
							<div class="magazin__item">
								<router-link :to="'/articles/' + article.meta.slug">
									<img :src="'https://app.sportlerplus.com/scaled/800/' + article.main_image_url" class="magazin__item__image">
									<div v-if="article.series_subtitle" class="magazin__item__badge">{{ article.series_subtitle }}</div>
									<div v-else class="magazin__item__badge">{{ article.media_type_names }}</div>
									<div class="magazin__item__text">
										<div class="magazin__item__title">{{ article.title }}</div>
										<div class="magazin__item__copy">{{ article.intro }}</div>
									</div>
								</router-link>
							</div>
						</splide-slide>
					</splide>
				</div>
				<a href="/magazine" class="content-block__button content-block__button__orange">Alle Artikel ansehen</a>
			</div>
		</div>

		<!-- QUOTES -->
		<div class="quotes">
			<splide :options="sliderOptionsQuotes" :slides="testimonials">
				<splide-slide v-for="testimonial,idx in testimonials" v-bind:key="testimonial.id">
					<div :class="idx % 2 == 0 ? 'quotes__item' : 'quotes__item quotes__item--imageRight'">
						<div class="quotes__item__content">
							<div class="quotes__item__image">
								<img :src="'https://app.sportlerplus.com' + testimonial.image.meta.download_url" :alt="testimonial.name" />
							</div>
							<div class="quotes__item__text">
								<h3>{{ testimonial.quote }}</h3>
								<div class="quotes__item__text__meta">
									{{ testimonial.name }} <span>{{ testimonial.team }}</span>
								</div>
							</div>
						</div>
						<img class="quotes__item__background" :src="'https://app.sportlerplus.com' + testimonial.background.meta.download_url" />
					</div>
				</splide-slide>
			</splide>
		</div>

		<!-- ATHLETEN -->
		<div v-if="athletes" class="content-block">
			<div class="content-block__content">
				<h3>Athletes</h3>
				<p>Sie holen alles aus dir raus! Unser Team besteht aus ambitionierten Athletes &amp; Coaches aus den verschiedensten Sportarten. Viele der Coaches findest du auch in unserer Workout App.</p>
				<div class="content-block__slider athlete__slider">
					<splide :options="sliderOptionsAthletes" :slides="athletes">
						<splide-slide v-for="athlete in athletes.slice(0, 5)" v-bind:key="athlete.id">
							<div class="athletes__card" @click="openAthleteModal(athlete)">
								<div v-if="athlete.image" class="athletes__card__image">
									<img :src="'https://app.sportlerplus.com' + athlete.scaled_image_url" />
								</div>
								<div class="athletes__card__title">
									{{ athlete.first_name }}
									<span>{{ sportsTypeName(athlete) }}</span>
								</div>
								<div class="athletes__card__cta">
									Kennenlernen
								</div>
							</div>
						</splide-slide>
					</splide>
				</div>
				<a href="/athleten" class="content-block__button content-block__button__orange">Alle Athleten kennenlernen</a>
			</div>
		</div>

		<!-- NEWSLETTER -->
		<div class="special-block">
			<h2>5€-Rabatt sichern!</h2>
			<p>Registriere dich für unseren Newsletter, erfahre immer als Erster von Rabatten und Aktionen und sichere dir direkt einen 5€-Gutschein.</p>
			<a href="https://shop.sportlerplus.de/newsletter" target="_blank" class="button button--primary">
				Newsletter abonnieren
			</a>
		</div>

		<AthleteModal :athlete="currentAthlete"></AthleteModal>
		<LandingpageFooter :openBonusCodeModal="openBonusCodeModalOnLoad"></LandingpageFooter>

	</div>
</template>

<script>
import axios from 'axios';

import LandingpageHeader from "@/components/LandingpageHeader.vue";
import LandingpageFooter from "@/components/LandingpageFooter.vue";
import Teambox from "@/components/Teambox.vue";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { convertTrackingLinkWebsite } from '@/sportlerplus-helper.js';
import AthleteModal from "@/components/AthleteModal.vue";

export default {
	name: 'Landingpage',
	components: { LandingpageHeader, LandingpageFooter, Teambox, Splide, SplideSlide, AthleteModal},
	metaInfo() {
		return { 
			meta: [
				{
					vmid: "og:title",
					property: "og:title",
					content: "SportlerPlus  - Bring' dein Training auf das nächste Level!"
				},
				{
					vmid: "og:site_name",
					property: "og:site_name",
					content: "SportlerPlus"
				},
				{
					vmid: "og:type",
					property: "og:type",
					content: "website"
				},
				{
					vmid: "og:image",
					property: 'og:image', 
					content: require('@/assets/meta-vorschau.jpg')
				}
			]
		}
	},
	methods: {
		convertTrackingLink(l) {
            return convertTrackingLinkWebsite(l);
        },
		scrollToTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				/* TODO: Smooth Transition doesn't work */
				behavior: 'smooth'
			});
		},
		openAthleteModal(athlete) {
			this.currentAthlete = athlete;
			this.$modal.show("athleteModal");
		},
		sportsTypeName(athlete) {
			if (athlete.sports_type_alt_name) {
				return athlete.sports_type_alt_name;
			}
			switch(athlete.sports_type_icon) {
				case 1:
					return "Functional Fitness";
				case 2:
					return "Yoga";
				case 3:
					return "Handball";
				case 4:
					return "Fußball";
				case 5:
					return "Triathlon";
				case 6:
					return "Turnen";
				default:
					return "";
			}
		},
		init() {
			this.$store.dispatch('fetchHomePage').then( () => {
				this.homePageInfo = this.$store.getters.getHomePageInfo;
			});
			let apiUrl = this.$store.getters.getApiUrl;
			let latestFreeContentsUrl = "pages/?type=blog.BlogPage&fields=title,intro,date,go_live_at,author,main_image_url,home_tile_image_url,category_names,categories,media_type_names,media_types,coach,premium_content,series_subtitle&premium_content=false&limit=5&order=-go_live_at";
			axios.get(apiUrl + latestFreeContentsUrl)
			.then(response => {
				this.articles = response.data.items;
			});

			// retrieve athletes from API via axios
			axios.get(apiUrl + "athletes").then((response) => {
				this.athletes = response.data.items;
				// sort athletes by sort_order field
				this.athletes.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);
			});

			// retrieve testimonials from API via axios
			axios.get(apiUrl + "testimonials").then((response) => {
				this.testimonials = response.data.items;
				// sort testimonials by sort_order field
				this.testimonials.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);
			});

			// retrieve adbanners from API via axios
			axios.get(apiUrl + "adbanners/?is_active=true").then((response) => {
				this.adbanners = response.data.items;
			});

			if ((this.$route.query.bonusCode) || (this.$route.path == "/code")) {
				this.openBonusCodeModalOnLoad = true;
			}
		},
	},
	computed: {
        topDealBanner() {
            if (this.adbanners) {
                let ads = this.adbanners.filter(ab => ab.banner_position == 101 && ab.is_active == true);
                console.log("found ads "  + ads.length);
                if (ads.length > 0) {
                    var idx = Math.floor(Math.random() * ads.length);
                    return ads[idx];
                }
            }
            return null;
        },
	},
	mounted() {
		this.init();
	},
	data() {
		return {
			sliderOptionsMagazine: {
				arrows: false,
				pagination: false,
				perPage: 3,
				gap: '24px',
				padding: '16px',
				breakpoints: {
					768: {
						perPage: 1,
						drag: true,
						pagination: true,
						gap: '8px',
					}
				}
			},
			sliderOptionsQuotes: {
				drag: false,
				pagination: false,
				autoplay: true,
				rewind: true,
				pauseOnHover: false,
				resetProgress: true,
				interval: '10000',
				gap: '0',
				padding: '0',
				arrowPath: 'M23,14.2928932 L28.7071068,20 L23,25.7071068 L21.5857864,24.2928932 L24.8768932,20.9998932 L12.2928932,21 C11.7406085,21 11.2928932,20.5522847 11.2928932,20 C11.2928932,19.4477153 11.7406085,19 12.2928932,19 L24.8768932,18.9998932 L21.5857864,15.7071068 L23,14.2928932 Z',
				breakpoints: {
					768: {
						type: 'fade',
						autoplay: false,
					}
				}
			},
			sliderOptionsAthletes: {
				arrows: false,
				pagination: false,
				perPage: 3,
				gap: '24px',
				padding: '16px',
				breakpoints: {
					768: {
						perPage: 1,
						drag: true,
						pagination: true,
						gap: '8px',
					}
				}
			},
			homePageInfo: null,
			articles: null,
			openBonusCodeModalOnLoad: false,
			athletes: null,
			adbanners: null,
			heroteams: null,
			testimonials: null,
			currentAthlete: null,
		};
	},
}
</script>

<style lang="scss">

	/* HEADER */
	.header {
		position: relative;
		margin-top: -136px;

		@media screen and (max-width: 767px) {
			display: flex;
			align-items: flex-end;
			min-height: calc(100vh - 82px);
			margin-top: -82px;
		}

		&__text {
			position: relative;
			z-index: 2;
			max-width: 1088px;
			margin: 0px auto;
			padding: 16px 16px 84px;

			@media screen and (min-width: 768px) {
				padding: 256px 24px 160px;
			}

			h1 {
				line-height: 40px;
				max-width: 470px;
				color: $colorWhite;
				text-shadow: 0 2px 16px rgba(30,29,31,0.48);

				@media screen and (min-width: 768px) {
					font-size: 48px;
					line-height: 56px;
					letter-spacing: -1px;
				}

				span {
					position: relative;

					&:before {
						content: '';
						position: absolute;
						z-index: -1;
						width: 100%;
						height: 6px;
						bottom: -6px;
						left: 0;
						background: $colorPrimary;

						@media screen and (min-width: 768px) {
							height: 9px;
						}
					}
				}
			}

			p {
				max-width: 440px;
				font-weight: 600;
				color: $colorWhite;
				opacity: 1;
				text-shadow: 0 2px 16px rgba(30,29,31,0.48);
				margin: 19px 0 33px;

				@media screen and (min-width: 768px) {
					font-size: 17px;
					line-height: 28px;
					letter-spacing: -0.33px;
					padding: 10px 0 23px;
				}

				span {
					font-weight: 900;
				}
			}
		}

		&__image {
			display: none;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			&:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 50%;
				bottom: 0;
				left: 0;
				background-image: linear-gradient(180deg, rgba($colorBlack,0.00) 0%, $colorBlack 100%);
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top center;
			}

			@media screen and (min-width: 768px) {
				display: none;
			}
		}

		&__video {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: flex;
			background: $colorBlack;

			@media screen and (min-width: 768px) {
				display: flex;
			}

			img, video {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				opacity: 0;
				animation: videoFadeIn 1s ease-out forwards;
				animation-delay: .5s;

				@keyframes videoFadeIn {
					0% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}
			}
		}
	}

	/* SPECIAL DEAL BOX */
	.dealbox {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: relative;
		z-index: 2;
		width: calc(100% - 32px);
		max-width: 800px;
		background: $colorWhite;
		margin: -76px auto 0;
		padding: 16px;
		border-radius: 4px;
		box-shadow: 0 4px 16px 0 rgba($colorBlack, .04), 0 8px 40px 0 rgba($colorBlack, .04);

		@media screen and (min-width: 768px) {
			margin: -48px auto 0;
			padding: 18px 24px;
		}

		&__image {
			width: 56px;
			height: 56px;
			background: rgba($colorBlack, .08);
			border-radius: 4px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			@media screen and (min-width: 768px) {
				background: none;
				width: 96px;
				height: 96px;
				margin: -18px 4px -18px -16px;
			}
		}

		&__text {
			display: flex;
			flex-direction: column;
			flex: 1;
			margin-left: 16px;

			@media screen and (min-width: 768px) {
				margin-left: 0;
			}
		}

		&__label {
			display: flex;
			flex-direction: column;
			font-size: 14px;
			line-height: 18px;
			font-weight: 700;
			letter-spacing: -0.25px;
			color: $colorBlack;
			margin-bottom: 2px;

			@media screen and (min-width: 768px) {
				font-size: 18px;
				line-height: 24px;
				display: block;
			}

			span {
				color: $colorPrimary;
				font-size: 17px;
				line-height: 24px;
				margin-bottom: 2px;

				@media screen and (min-width: 768px) {
					font-size: 18px;
					line-height: 24px;
					margin-bottom: 0;
				}
			}

			&--mobile {
				display: flex;

				@media screen and (min-width: 768px) {
					display: none;
				}
			}

			&--desktop {
				display: none;

				@media screen and (min-width: 768px) {
					display: block;
				}
			}
		}

		&__sublabel {
			display: none;
			font-size: 14px;
			font-weight: 600;
			letter-spacing: -0.2px;
			color: rgba($colorBlack, .48);

			@media screen and (min-width: 768px) {
				display: flex;
			}
		}

		.button {
			width: 100%;
			margin-top: 16px;

			@media screen and (min-width: 768px) {
				width: auto;
				margin-top: 0;
			}
		}
	}

	/* FEATURES */
	.feature {
		display: flex;
		flex-direction: column;
		max-width: 1088px;
		margin: 80px auto;
		padding: 0 16px;

		@media screen and (min-width: 768px) {
			flex-direction: row;
			align-items: center;
			margin: 160px auto;
			padding: 0 24px;

			&:nth-child(odd) {
				flex-direction: row-reverse;
			}
		}

		&__image {
			display: flex;
			justify-content: flex-end;
			width: calc(100vw - 32px);
			margin-bottom: 30px;

			.feature:nth-child(odd) & {
				justify-content: flex-start;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 8px;
				box-shadow: 0 8px 8px 0 rgba($colorBlack, .08);

				@media screen and (min-width: 768px) {
					width: auto;
					height: auto;
					border-radius: 0;
					box-shadow: 0 16px 24px 0 rgba($colorBlack, .12);
				}
			}

			@media screen and (min-width: 768px) {
				width: 50%;
				margin-bottom: 0;
			}
		}

		&__text {
			width: 100%;

			@media screen and (min-width: 768px) {
				width: 50%;
				padding-left: 64px;

				.feature:nth-child(odd) & {
					padding-left: 0;
					padding-right: 64px;
				}
			}

			&__category {
				display: flex;
				align-items: center;
				font-size: 14px;
				line-height: 20px;
				font-weight: 700;
				color: $colorPrimary;
				text-transform: uppercase;
				margin-bottom: 9px;

				img {
					margin-right: 8px;
				}
			}

			h2 {
				margin-bottom: 17px;

				@media screen and (min-width: 768px) {
					font-size: 40px;
					line-height: 52px;
					letter-spacing: -0.83px;
				}

				span {
					position: relative;

					&:before {
						content: '';
						position: absolute;
						width: 100%;
						height: 6px;
						bottom: -4px;
						left: 0;
						background: $colorPrimary;

						@media screen and (min-width: 768px) {
							height: 8px;
						}
					}
				}
			}

			p {
				margin-bottom: 25px;

				@media screen and (min-width: 768px) {
					margin-bottom: 38px;
					font-size: 17px;
					line-height: 28px;
				}
			}
		}

		&__buttons {
			display: flex;
			justify-content: space-between;

			@media screen and (min-width: 768px) {
				justify-content: inherit;

				.download__button {
					width: auto;

					&:not(:last-child) {
						margin-right: 16px;
					}
				}
			}
		}
	}

	/* DOWNLOAD BUTTON */
	.download__button {
		display: flex;
		width: calc(50% - 8px);
		align-items: center;
		padding: 12px 20px 12px 12px;
		background: $colorPrimary;
		box-shadow: 0 12px 24px 0 rgba($colorPrimary,0.16);
		border-radius: 8px;
		text-align: left;
		text-decoration: none;
		transition: all .2s ease-in-out;

		&--large {
			@media screen and (min-width: 768px) {
				padding: 16px 8px 16px 16px;
			}
		}

		&:hover {
			box-shadow: 0 12px 24px 0 rgba($colorPrimary,0.24);
		}

		&__icon {
			display: flex;
			align-items: center;
			flex-shrink: 0;
			margin-right: 12px;
			width: 24px;
			height: 24px;

			img {
				width: 100%;
				height: 100%;
			}

			.download__button--large & {
				@media screen and (min-width: 768px) {
					margin-right: 16px;
					width: 32px;
					height: 32px;
				}
			}
		}

		&__text {
			display: flex;
			flex-direction: column;
			font-size: 8px;
			line-height: 14px;
			font-weight: 700;
			text-transform: uppercase;
			color: rgba($colorWhite, .64);
			margin-top: -6px;
			margin-bottom: 0px;

			.download__button--large & {
				@media screen and (min-width: 768px) {
					font-size: 11px;
					line-height: 17px;
					margin-top: -5px;
					margin-bottom: -1px;
				}
			}

			span {
				font-size: 17px;
				color: rgba($colorWhite, 1);
				text-transform: initial;

				.download__button--large & {
					@media screen and (min-width: 768px) {
						font-size: 20px;
					}
				}
			}
		}
	}

	/* QUOTES */
	.quotes {
		margin-top: 8px;
		overflow: hidden;
		margin-bottom: -40px;

		@media screen and (min-width: 768px) {
			margin-top: 0;
			margin-bottom: -48px;
		}

		.splide__slide {
			opacity: 1;

			@media screen and (min-width: 768px) {
				padding-top: 64px;
				overflow-x: hidden;
			}
		}

		.splide__track {
			overflow: visible;
			padding-bottom: 40px;

			@media screen and (min-width: 768px) {
				padding-bottom: 48px;
			}
		}


		.splide__arrows {
			position: absolute;
			z-index: 100;
			top: 64px;
			left: 16px;
			display: flex;

			@media screen and (min-width: 768px) {
				top: inherit;
				bottom: 24px;
				left: 50%;
				transform: translateX(-50%);
				background: $colorWhite;
				border-radius: 4px;
				box-shadow: 0 8px 16px 0 rgba($colorBlack, .04), inset 0 0 0 1px rgba($colorBlack, .08);
			}
		}

		.splide__arrow {
			display: flex;
			justify-content: center;
			align-items: center;
			all: unset;
			width: 40px;
			height: 40px;
			background: none;
			cursor: pointer;
			transition: all .2s ease-in-out;
			background: rgba($colorWhite, .12);
			border-radius: 4px;
			margin-right: 8px;

			@media screen and (min-width: 768px) {
				padding: 4px;
				background: none;
				border-radius: 0;
				margin-right: 0;
			}

			&:hover {
				background: rgba($colorWhite, .16);

				@media screen and (min-width: 768px) {
					background: rgba($colorBlack, .03);
				}
			}

			svg {
				width: 40px;
				height: 40px;

				path {
					fill: $colorWhite;

					@media screen and (min-width: 768px) {
						fill: $colorBlack;
					}
				}
			}

			&--prev {
				box-shadow: 1px 0 0 0 rgba($colorBlack, .08);
			}
		}

		&__item {
			display: flex;
			min-height: 552px;

			&__content {
				display: flex;
				flex-direction: column-reverse;
				position: relative;
				z-index: 1;
				width: 100%;
				max-width: 1088px;
				margin: 0px auto;
				padding: 96px 16px 0;

				@media screen and (min-width: 768px) {
					flex-direction: row;
					align-items: center;
					padding: 0 24px;

					.quotes__item--imageRight & {
						flex-direction: row-reverse;
					}
				}
			}

			&__text {
				padding: 0 0 48px 0;

				@media screen and (min-width: 768px) {
					width: 62%;
					padding: 0 0 0 48px;

					.quotes__item--imageRight & {
						padding: 0 48px 0 0;
					}
				}

				h3 {
					position: relative;
					color: $colorWhite;
					line-height: 36px;
					padding-top: 32px;

					@media screen and (min-width: 768px) {
						font-size: 32px;
						line-height: 48px;
						padding-top: 90px;
					}

					@media screen and (min-width: 768px) {
						&:before {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							width: 64px;
								height: 54px;
							background: url('../assets/landingpage/quotes.svg');
							background-size: cover;
						}
					}
				}

				&__meta {
					display: flex;
					flex-direction: column;
					font-size: 17px;
					line-height: 24px;
					font-weight: 600;
					color: $colorWhite;
					margin-top: 24px;

					@media screen and (min-width: 768px) {
						display: block;
						font-size: 17px;
						line-height: 28px;
						letter-spacing: -0.25px;
						margin-top: 26px;
						color: rgba($colorWhite, .64);
						margin-top: 27px;
					}

					span {
						font-size: 14px;
						color: rgba($colorWhite, .64);
						font-weight: 500;

						@media screen and (min-width: 768px) {
							font-size: 17px;
							line-height: 28px;
							letter-spacing: -0.25px;

							&:before {
								content: '・';
							}
						}
					}
				}
			}

			&__image {
				display: flex;
				justify-content: flex-start;
				height: 100%;

				@media screen and (min-width: 768px) {
					width: 38%;
					justify-content: flex-end;

					.quotes__item--imageRight & {
						justify-content: flex-start;
					}
				}

				img {
					width: 100%;
					max-height: 360px;
					object-fit: cover;
					object-position: top;

					@media screen and (min-width: 768px) {
						width: auto;
						height: calc(100% + 64px);
						margin-top: -64px;
						max-height: inherit;
					}
				}
			}

			&__background {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				object-fit: cover;

				@media screen and (min-width: 768px) {
					height: calc(100% - 64px);
					top: 64px;
				}
			}
		}
	}

	/* ATHLETEN */
	.athlete {

		&__wrapper {
			display: flex;
			margin: 64px 0 32px;
		}

		&__item {
			display: flex;
			align-items: flex-end;
			width: 100%;
			position: relative;
			padding: 0 24px 24px 24px;
			background: $colorBlack;
			height: 300px;
			box-shadow: 0 8px 16px 0 rgba($colorBlack, .04);
			border-radius: 12px;
			overflow: hidden;
			margin-bottom: 24px;

			&:before {
				content: '';
				position: absolute;
				z-index: 2;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 128px;
				background-image: linear-gradient(180deg, rgba($colorBlack, 0) 0%, rgba($colorBlack, .48) 100%);
			}

			&__title {
				position: relative;
				z-index: 3;
				display: flex;
				flex-direction: column;
				font-size: 32px;
				line-height: 32px;
				font-weight: 900;
				color: $colorWhite;

				span {
					-webkit-text-fill-color: transparent;
					-webkit-text-stroke: 1.5px;
				}
			}

			img {
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 12px;
				object-fit: cover;
				object-position: top;
			}
		}
	}

	/* SPECIAL BLOCK */
	.special-block {
		padding: 76px 16px 88px;
		text-align: center;
		background: url('../assets/landingpage/download@2x.jpg');
		background-size: cover;
		background-position: center center;

		@media screen and (min-width: 768px) {
			padding: 188px 24px 200px;
			margin-bottom: 32px;

			.team & {
				padding: 140px 24px 152px;
			}
		}

		h2 {
			font-size: 32px;
			color: $colorWhite;
			margin-bottom: 12px;

			@media screen and (min-width: 768px) {
				font-size: 48px;
				line-height: 56px;
				letter-spacing: -1px;
				margin-bottom: 29px;
			}
		}

		p {
			max-width: 640px;
			margin: 0px auto;
			color: $colorWhite;

			@media screen and (min-width: 768px) {
				font-size: 17px;
				line-height: 28px;
				opacity: 1;
			}
		}

		&__buttons {
			display: flex;
			justify-content: space-between;
			max-width: 470px;
			margin-top: 31px;

			@media screen and (min-width: 768px) {
				margin: 56px auto 0;
			}
		}

		.button {
			display: inline-flex;
			width: initial;
			padding-left: 24px !important;
			padding-right: 24px !important;
			margin-top: 24px;

			@media screen and (max-width: 767px) {
				font-size: 17px !important;
				padding: 10px 24px !important;
			}

			@media screen and (min-width: 768px) {
				margin-top: 56px;
			}
		}

		&--divided {
			display: flex;
			flex-direction: column;

			@media screen and (min-width: 768px) {
				justify-content: center;
				flex-direction: row;
			}
		}

		&__item {
			width: 100%;
			padding: 16px 0 24px;

			@media screen and (min-width: 768px) {
				width: 50%;
				max-width: 520px;
				padding-bottom: 0;
			}

			&:not(:last-child) {
				padding-bottom: 48px;
				box-shadow: inset 0 -2px 0 0 rgba($colorWhite, .16);

				@media screen and (min-width: 768px) {
					padding: 32px 48px 32px 0;
					box-shadow: inset -2px 0 0 0 rgba($colorWhite, .16);
				}
			}

			&:not(:first-child) {
				padding-top: 48px;

				@media screen and (min-width: 768px) {
					padding: 32px 0 32px 48px;
				}
			}
		}
	}

	/* CONTENT BLOCK */
	.content-block {
		overflow: hidden;

		&__content {
			display: flex;
			flex-direction: column;
			max-width: 1088px;
			margin: 0px auto;
			padding: 88px 16px;

			@media screen and (min-width: 768px) {
				padding: 152px 24px;
			}
		}

		h3 {
			font-size: 32px;
			line-height: 40px;
			font-weight: 900;
			letter-spacing: -0.48px;
			margin-bottom: 16px;

			@media screen and (min-width: 768px) {
				font-size: 40px;
				line-height: 48px;
				letter-spacing: -1px;
				margin-bottom: 23px;
				text-align: center;
			}
		}

		p {
			opacity: 1;

			@media screen and (min-width: 768px) {
				font-size: 17px;
				line-height: 28px;
				text-align: center;
				max-width: 760px;
				margin: 0px auto;
			}
		}

		&__slider {
			width: calc(100% + 32px);
			margin: 24px -16px 32px;

			@media screen and (min-width: 768px) {
				width: 100%;
				margin: 56px 0 40px;
			}

			.splide__slide {
				display: flex;
				opacity: 1;
			}

			.splide__track {
				overflow: visible;
			}

			.splide__pagination {
				bottom: inherit;
				margin-top: -3px;

				&__page {
					background: rgba($colorBlack, .24);
					opacity: 1;

					&.is-active {
						background: $colorBlack;
						transform: inherit;
						opacity: 1;
					}
				}
			}

			/* TODO: Remove MagazinItem Code if accesible from Articles.vue - but keep margin-css here */
			.magazin {

				&__item {
					border-radius: 4px;
					overflow: hidden;
					line-height: 0;
					box-shadow: 0 8px 16px 0 rgba($colorBlack,0.08), 0 0 0 1px rgba($colorBlack, 0.04);
					margin: 0 0 24px;

					&:not(:last-child) {
						margin-bottom: 16px;
					}

					a {
						text-decoration: none;
					}

					&__image {
						width: 100%;
						height: 160px;
						object-fit: cover;
					}

					&__badge {
						display: inline-flex;
						position: relative;
						z-index: 2;
						font-size: 11px;
						line-height: 11px;
						font-weight: 700;
						letter-spacing: -0.23px;
						text-transform: uppercase;
						padding: 7px 7px 6px 8px;
						margin-left: 16px;
						margin-top: -12px;
						border-radius: 4px;
						color: $colorWhite;
						background: $colorPrimary;
					}

					&__text {
						padding: 10px 16px 20px;
					}

					&__title {
						font-size: 20px;
						line-height: 26px;
						font-weight: 700;
						letter-spacing: -0.29px;
						color: $colorBlack;
						margin-bottom: 6px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}

					&__copy {
						font-size: 14px;
						line-height: 20px;
						font-weight: 500;
						letter-spacing: -0.21px;
						color: rgba($colorBlack, .48);
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}
			}
		}

		&__list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 24px;

			@media screen and (min-width: 768px) {
				margin-top: 72px;
			}
		}

		&__item {
			position: relative;
			width: calc(50% - 8px);
			text-decoration: none;
			background: $colorWhite;
			box-shadow: 0 8px 16px 0 rgba(30,29,31,0.04);
			border-radius: 4px;
			margin-bottom: 16px;
			overflow: hidden;
			text-align: center;

			@media screen and (min-width: 768px) {
				width: calc(25% - 18px);
				transition: all .2s ease-in-out;

				&:hover {
					box-shadow: 0 12px 24px 0 rgba(30,29,31,0.08);
				}
			}

			img {
				height: 160px;
				width: 100%;
				object-fit: cover;

				@media screen and (min-width: 768px) {
					height: 200px;
				}
			}

			&__badge {
				display: inline-flex;
				position: absolute;
				top: 8px;
				left: 8px;
				z-index: 2;
				font-size: 11px;
				line-height: 11px;
				font-weight: 700;
				letter-spacing: -0.23px;
				text-transform: uppercase;
				padding: 7px 7px 6px 8px;
				border-radius: 4px;
				color: $colorWhite;
				background: $colorPrimary;

				@media screen and (min-width: 768px) {
					top: 16px;
					left: 16px;
				}
			}

			&__title {
				font-size: 14px;
				line-height: 20px;
				font-weight: 700;
				letter-spacing: -0.29px;
				color: $colorBlack;
				padding: 4px 4px 3px;

				@media screen and (min-width: 768px) {
					font-size: 17px;
					line-height: 24px;
					padding: 11px 12px 3px;
				}
			}

			&__price {
				font-size: 11px;
				font-weight: 700;
				letter-spacing: -0.21px;
				color: rgba($colorBlack, .48);
				margin-bottom: 12px;

				@media screen and (min-width: 768px) {
					font-size: 14px;
					margin-bottom: 18px;
				}
			}
		}

		&__button {
			display: inline-flex;
			justify-content: center;
			padding: 12px 24px 11px;
			align-self: center;
			font-size: 17px;
			font-weight: 700;
			color: rgba($colorBlack, .48) !important;
			text-decoration: none;
			background: rgba($colorBlack, .08);
			border-radius: 8px;
			transition: all .2s ease-in-out;
			margin-top: 24px;

			@media screen and (min-width: 768px) {
				width: inherit;
				margin-top: 32px;

				&:hover {
					background: rgba($colorBlack, .12);
					color: rgba($colorBlack, .64) !important;
				}
			}
		}

		&__button__orange {
			background: $colorPrimary !important;
			color: $colorWhite !important;
			
			@media screen and (min-width: 768px) {
				margin-top: 0px;
			}
			&:hover {
				color: $colorWhite !important;
			}
		}
	}

	.newsletter {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 57px 16px 64px;
		background: url('../assets/landingpage/newsletter-background@2x.jpg');
		background-position: center center;
		background-size: cover;
		text-align: center;

		@media screen and (min-width: 768px) {
			flex-direction: row;
			max-width: 1040px;
			width: calc(100vw - 48px);
			margin: 0px auto;
			padding: 64px;
			border-radius: 12px;
			text-align: left;
			background-image: url('../assets/landingpage/newsletter-background-desktop@2x.jpg');
		}

		&:before {
			content: '';
			position: absolute;
			left: 8px;
			bottom: 0;
			width: calc(100% - 16px);
			height: 48px;
			box-shadow: 0 16px 32px 0 rgba($colorPrimary,0.48);

			@media screen and (min-width: 768px) {
				left: 40px;
				width: calc(100% - 80px);
			}
		}

		h2 {
			color: $colorWhite;
			font-size: 32px;
			margin-bottom: 18px;

			@media screen and (min-width: 768px) {
				font-size: 48px;
				letter-spacing: -1px;
			}
		}

		p {
			color: $colorWhite;
			opacity: 1;

			@media screen and (min-width: 768px) {
				font-size: 17px;
				line-height: 28px;
				margin-bottom: 0;
				opacity: .64;
			}
		}

		&__text {

			@media screen and (min-width: 768px) {
				width: 50%;
				margin-left: auto;
			}
		}

		.button {
			margin-top: 26px;
			color: $colorPrimary !important;
			width: inherit !important;
			min-width: 288px !important;
			justify-content: center;
			display: inline-flex;
			text-decoration: none;
		}
	}
</style>
<template>
    <div class="challenge__success">

        <div class="challenge__success__header">
            <img src="@/assets/notification/ic_notification_success.svg" class="playlist__success__avatar" />
            <h1>Challenge bestanden!</h1>
            <p>Wow - was für eine Leistung! Zur Belohnung schenken wir dir einen <span>15€-Gutschein</span> (ab einem Mindestbestellwert von 50€) für unseren SportlerPlus Shop.</p>
            <div class="codefield">
                <div class="codefield__label">
                    Gutschein-Code
                </div>
                <div class="codefield__value">
                    {{ challengeGiftCode }}
                </div>
                <div class="codefield__copy"></div>
            </div>
            <a href="//shop.sportlerplus.de" target=_blank class="button button--primary button--small">
                Jetzt im Shop einlösen
            </a>
        </div>

        <div v-if="finishedChallenge" class="challenge__success__statistics">
            <div class="challenge__success__statistics__content">
                <h2>Zahlen, bitte!</h2>
                <p>Während dieser Challenge hast du diese großartigen Werte erzielt:</p>

                <div class="challenge__success__statistics__wrapper">
                    <div v-if="totalEnergyExpended > 0" class="challenge__success__statistics__item">
                        <img src="@/assets/ic_64_flame.svg" alt="">
                        <div class="challenge__success__statistics__item__left">
                            <div class="challenge__success__statistics__item__value">
                                {{ totalEnergyExpended }} <span>kcal</span>
                            </div>
                            <div class="challenge__success__statistics__item__label">
                                Aktivitätskalorien
                            </div>
                        </div>
                    </div>
                    <div v-if="totalMinutes > 0" class="challenge__success__statistics__item">
                        <img src="@/assets/ic_64_stopclock.svg" alt="">
                        <div class="challenge__success__statistics__item__left">
                            <div class="challenge__success__statistics__item__value">
                                {{ totalHours }}<span>h</span> {{ totalMinutes }}<span>m</span>
                            </div>
                            <div class="challenge__success__statistics__item__label">
                                Trainingsdauer
                            </div>
                        </div>
                    </div>
                    <!-- <div class="challenge__success__statistics__item challenge__success__statistics__item--comingsoon">
                        <img src="@/assets/ic_64_water.svg" alt="">
                        <div class="challenge__success__statistics__item__left">
                            <div class="challenge__success__statistics__item__value">
                                Coming soon
                            </div>
                            <div class="challenge__success__statistics__item__label">
                                Flüssigkeitsbedarf
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="challenge__success__next">
            <h2>Bereit für neue Höhen?</h2>
            <p>Vor dem Spiel ist nach dem Spiel. Bleib’ am Ball und starte die nächste Herausforderung.</p>

            <div v-for="selectableChallenge in selectableChallenges" v-bind:key="selectableChallenge.id" class="fitnessplan__item" @click="openFitnessplanDetailModal(selectableChallenge)">
                <div class="fitnessplan__item__content">
                    <div class="fitnessplan__item__badge">
                        <span>Noch nicht absolviert</span>・{{ selectableChallenge.badge }}
                    </div>
                    <h3 class="fitnessplan__item__title">{{ selectableChallenge.title }}</h3>
                    <img :src="'https://app.sportlerplus.com/scaled/800/' + selectableChallenge.image_url"
                         class="fitnessplan__item__image">
                </div>
            </div>

        </div>

        <router-link to="/" class="button button--small button--fullwidth button--primary">
            Weiter zur Startseite
        </router-link>

        <FitnessplanDetailModal :challenge="currentChallenge"></FitnessplanDetailModal>
    </div>
</template>
<script>
import FitnessplanDetailModal from '@/components/FitnessplanDetailModal';
import axios from 'axios';

export default {
    name: "FitnessplanChallengeSuccess",
    data() {
        return {
            currentChallenge: {},
            finishedChallenge: null,
            fitnessPlan: null,
            challenges: [],
            totalTime: 0,
            totalEnergyExpended: 0,
            totalMinutes: 0,
            totalHours: 0,
            challengeGiftCode: null,
        };
    },
    components: {FitnessplanDetailModal},
    mounted() {
        this.init();
    },
    computed: {
        activeChallenges() {
            if (this.fitnessPlan && this.fitnessPlan.startedChallenges) {
                return this.fitnessPlan.startedChallenges.filter(el => el.inProgress);
            }
            return [];
        },
        getActiveChallenges() {
            if (!this.challenges) { return []; }
            let userChallenges = [...this.activeChallenges];
            userChallenges.forEach(el => {
                Object.assign(el, this.challenges.find(c => parseInt(c.id) === parseInt(el.id)))
            });
            return userChallenges;
        },
        selectableChallenges() {
            if (!this.challenges) { return []; }
            return this.challenges.filter(el => {
                let c = this.activeChallenges.find(ac => parseInt(ac.id) === parseInt(el.id)) || this.completedChallenges.find(ac => parseInt(ac.id) === parseInt(el.id));
                return (c == null);
            });
        },
        completedChallenges() {
            if (this.fitnessPlan && this.fitnessPlan.startedChallenges) {
                return this.fitnessPlan.startedChallenges.filter(el => !el.inProgress);
            }
            return [];
        },
        getCompletedChallenges() {
            if (!this.challenges) { return []; }
            return this.challenges.filter(el => {
                let c = this.completedChallenges.find(ac => parseInt(ac.id) === parseInt(el.id));
                return (c != null);
            });
        },
    },
    methods: {
        openFitnessplanDetailModal(challenge) {
            this.currentChallenge = challenge;
            this.$modal.show('fitnessplanDetail');
        },
        closeFitnessplanDetailModal() {
            this.$modal.hide('fitnessplanDetail');
        },
        async init() {
            let unique_challenge_id = this.$route.query.unique_challenge_id;

            axios
				.get("https://apphelper.sportlerplus.com/getChallengeGiftCode?unique_challenge_id=" + unique_challenge_id)
				.then(response => {
					let giftCodeData = response.data;
					this.challengeGiftCode = giftCodeData.code;
				});
            
            this.$confetti.start(
                {
                    defaultColors: [
                        '#FF6900',
                        '#1E1D1F',
                        '#FFFFFF',
                    ],
                }
            );
            // Konfetti für 5s anzeigen
            setTimeout(() => {
                this.$confetti.stop();
            }, 5000);

            await this.$store.dispatch("fetchChallenges").then(() => {
                this.challenges = this.$store.getters.getChallenges;
            });
            this.$store.dispatch('fetchFitnessPlan').then((res) => {
                if (res.length != 0) {
                    this.fitnessPlan = res[0];
                    if (this.fitnessPlan.startedActivities) {
                        this.fitnessPlan.startedActivities.filter(el => el._uniqueChallengeId == unique_challenge_id).forEach(el => {
                            if (el.realDuration) {
                                this.totalTime += el.realDuration;
                            }
                            if (el.energyExpended) {
                                this.totalEnergyExpended += el.energyExpended;
                            }
                        });
                    }
                    if (this.fitnessPlan.startedChallenges) {
                        let finishedChallenge = this.fitnessPlan.startedChallenges.find(el => el.uniqueChallengeId == unique_challenge_id);
                        if (finishedChallenge) {
                            this.finishedChallenge = this.challenges.find(el => el.id == finishedChallenge.id);
                        }
                    }
                    this.totalHours = Math.floor(this.totalTime / 60);
                    this.totalMinutes = Math.round(this.totalTime % 60);
                }
            });

        },

    },
};
</script>

<style lang="scss">

    #confetti-canvas {
        z-index: 1000;
    }
    
    .challenge__success {
        display: flex;
        flex-direction: column;
        
        &__header {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &__progress {
                margin-bottom: 22px;
            }

            img {
                height: 128px;
                margin-bottom: 22px;
            }

            h1, p {
                color: $colorBlack;
                text-align: center;
                max-width: 480px;
                margin-left: auto;
                margin-right: auto;
            }

            p {
                margin-top: 15px;
                margin-bottom: 35px;
                opacity: 1;
                color: rgba($colorBlack, .64);

                span {
                    color: $colorBlack;
                    font-weight: 600;
                }
            }
        }

        .codefield {
            position: relative;
            width: 100%;
            padding: 12px 16px 11px;
            border-radius: 8px;
            box-shadow: inset 0 0 0 2px rgba($colorBlack, .12);
            margin-bottom: 16px;

            &__label {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 11px;
                font-weight: 600;
                color: rgba($colorBlack, .32);
                text-transform: uppercase;
                background: $colorWhite;
                padding: 2px 8px 0;
            }

            &__value {
                width: 100%;
                font-size: 17px;
                font-weight: 700;
                color: $colorBlack;
                text-align: center;
            }
        }

        &__statistics {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: $colorBlack;
            padding: 64px 20px 48px;
            margin: 55px -20px 0;
            width: 100vw;

            @media screen and (min-width: 520px) {
                margin: 55px 0 0 calc((-100vw + 480px) / 2);
            }

            &__content {
                width: 100%;
                max-width: 520px;

                @media screen and (min-width: 520px) {
                    padding: 0 20px;
                }
            }

            h2 {
                color: $colorWhite;
                margin: -9px 0 8px;
            }

            p {
                color: $colorWhite;
                margin-bottom: 32px;
            }

            &__wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            &__item {
                display: flex;
                flex-direction: column;
                width: calc((100% - 12px) / 2);
                background: rgba($colorWhite, .04);
                color: $colorWhite;
                border-radius: 4px;
                padding: 20px;
                margin-bottom: 16px;

                &--small {
                    flex-direction: row;
                    width: 100%;
                    padding: 16px;

                    > img {
                        width: 48px !important;
                        height: 48px !important;
                        margin-bottom: 0 !important;  
                    }
                }

                &--dark {
                    background: rgba($colorBlack, .04);
                    color: $colorBlack;
                }

                img {
                    width: 64px;
                    height: 64px;
                    margin-bottom: 3px;
                }

                &--comingsoon {
                    img {
                        opacity: .48;
                    }
                }

                &__left {
                    display: flex;
                    flex-direction: column;
                    margin-top: 8px;
                    margin-right: auto;

                    .challenge__success__statistics__item--small & {
                        margin-left: 16px;
                        margin-top: 0;
                    }
                }

                &__value {
                    font-size: 24px;
                    font-weight: 700;

                    .challenge__success__statistics__item--small & {
                        font-size: 20px;
                    }

                    span {
                        font-size: 17px;

                        .challenge__success__statistics__item--small & {
                            font-size: 14px;
                        }
                    }

                    .challenge__success__statistics__item--comingsoon & {
                        display: inline-flex;
                        font-size: 11px;
                        font-weight: 600;
                        color: $colorBlack;
                        background: $colorWhite;
                        border-radius: 10px;
                        padding: 2px 8px;
                        margin: 16px auto 8px 0;
                    }
                }

                &__label {
                    font-size: 11px;
                    font-weight: 600;
                    opacity: .48;
                    margin-top: -3px;

                    .challenge__success__statistics__item--small & {
                        font-size: -5px;
                    }
                }

                &__right {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba($colorWhite, .48);

                    .challenge__success__statistics__item--dark & {
                        color: rgba($colorBlack, .48);
                    }

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 8px;
                    }
                }
            }
        }

        &__next {
            margin: 64px 0 48px;

            h2 {
                margin: -9px 0 8px;
            }

            p {
                margin-bottom: 32px;
            }
        }
    }

</style>

<template>
    <VModal name="contactModal" class="notification-modal large" width="100%" height="auto" :scrollable="true">
        <div class="vm-header">
            <div class="vm-header-left">
            </div>
            <div class="vm-header-center">
            </div>
            <div class="vm-header-right">
                <img src="@/assets/ic_nav_close.svg" alt="" @click="dismissDialog">
            </div>
        </div>
        <div class="vm-content">
            <!-- Hall of Fame-->
            <template v-if="programmeType == 'hallOfFame'">
                <img src="@/assets/notification/ic_notification_star.svg" alt="">
                <h3>
                    Ihr möchtet in die Hall of Fame?
                </h3>
                <p>
                    Dann schreibt uns, warum ausgerechnet ihr dazu gehört! Solltet ihr ausgewählt werden, warten tolle Preise auf euch.
                </p>
            </template>

            <!-- Team-Programm -->
            <template v-else-if="programmeType == 'teamProgramme'">
                <img src="@/assets/notification/ic_notification_premium.svg" alt="">
                <h3>
                    Ihr möchtet die Club-Vorteile erhalten?
                </h3>
                <p>
                    Dann schreibt uns einfach eine kurze Nachricht mit euren Kontaktdaten und wir melden uns mit allen Informationen.
                </p>
            </template>

            <template v-else>
                <img src="@/assets/notification/ic_notification_probetraining.svg" alt="">
                <h3>
                    Du möchtest ins SportlerPlus-Team?
                </h3>
                <p>
                    Dann schreib’ uns gerne eine Nachricht. Wir suchen regelmäßig neue Athletinnen und Athleten für unser Team.
                </p>
            </template>

            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field v-if="programmeType == 'athletes'" name="name" placeholder="Name" type="text"
                              v-model="name" :rules="nameRules" required>
                </v-text-field>
                <v-text-field v-else name="name" placeholder="Name / Teamname" type="text"
                              v-model="name" :rules="nameRules" required>
                </v-text-field>
                <v-text-field validate-on-blur name="email" placeholder="E-Mail-Adresse" type="email"
                              v-model="email" :rules="emailRules" required>
                </v-text-field>
                <textarea v-model="message" type="text" placeholder="Nachricht..."></textarea>
            </v-form>
            <v-btn :disabled="!valid" @click="saveMessage" type="button" class="button button--primary">Nachricht senden</v-btn>
        </div>
    </VModal>
</template>

<script>
import {contactFormCollection} from '@/firebase/index.js';

export default {
    name: 'ContactModal',
    methods: {
        dismissDialog() {
            this.$modal.hide('contactModal');
        },
        saveMessage() {
            let currentDate = new Date();
            let messageObject = {
                message: this.message,
                time: currentDate.getTime(),
                timeAsString: currentDate.toString(),
                email: this.email,
                name: this.name,
            }
            contactFormCollection.add(messageObject);

            this.dismissDialog();
        }
    },
    props: {
        programmeType: {
            type: String,
            default() {
                return "hallOfFame";
            }
        },
    },
    data() {
        return {
            valid: false,
            name: "",
            email: "",
            message: "",
            nameRules: [
                v => !!v || "Bitte gib einen Namen an!"
            ],
            emailRules: [
                v => !!v || "Eine Email-Adresse ist notwendig",
                v => /.+@.+/.test(v) || "Die Adresse ist nicht im gültigen Format."
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
    .vm-content {
        padding-top: 0;

        img {
            margin-top: 0;
        }
    }

    @media screen and (min-width: 768px) {
    
        .v-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .v-input {
                width: calc(50% - 8px);
                flex: initial !important;

                &:last-child {
                    width: 100%;
                }
            }

            textarea {
                max-width: 100%;
            }
        }

        .button {
            margin-top: 40px;
            width: initial;
            padding-left: 24px !important;
            padding-right: 24px !important;
        }
    }
</style>
<template>
	<div class="athleten">

		<LandingpageHeader activeTab="athleten"></LandingpageHeader>

		<!-- HEADER -->

		<div class="landingpage__header">

			<div class="landingpage__header__text">
				<h1>Das sind unsere<br><span>Pro-Level</span> Athletes!</h1>
                <p><b>Sie holen alles aus dir raus!</b> Unsere Athleten & Coaches aus den verschiedensten Fachbereichen bringen dich in individuellen Trainings mit Ihrer Expertise an deine persönlichen Grenzen.</p>
			</div>

			<div class="landingpage__header__image">
				<img src="@/assets/landingpage/story-header.jpg" srcset="@/assets/landingpage/story-header@2x.jpg 2x" alt="Story SportlerPlus">
			</div>

		</div>


		<!-- Athleten -->

		<div class="athletes">

			<div class="athletes__wrapper">

				<div v-for="athlete in athletes" v-bind:key="athlete.id" class="athletes__card" @click="openAthleteModal(athlete)">
					<div v-if="athlete.image" class="athletes__card__image">
						<img :src="'https://app.sportlerplus.com' + athlete.scaled_image_url" />
					</div>
					<a :href="athlete.social_link" target="_blank" class="athletes__card__social">
						<img v-if="socialLinkType(athlete.social_link) == 'instagram'" src="@/assets/landingpage/ic_team_instagram_white.svg" alt="Instagram Logo">
						<img v-else-if="socialLinkType(athlete.social_link) == 'tiktok'" src="@/assets/landingpage/ic_team_tiktok_white.svg" alt="TikTok Logo">
						<img v-else-if="socialLinkType(athlete.social_link) == 'youtube'" src="@/assets/landingpage/ic_team_youtube_white.svg" alt="Youtube Logo">
						<img v-else src="@/assets/landingpage/ic_team_website_white.svg" alt="Website Logo">
					</a>
					<div class="athletes__card__title">
						{{ athlete.first_name }}<span>{{ sportsTypeName(athlete) }}</span>
					</div>
					<div class="athletes__card__cta">
						Kennenlernen
					</div>
				</div>

			</div>


			<!--<div class="athletes__item">
				
				<div class="athletes__item__image">
					<img src="@/assets/landingpage/team-rob.jpg" srcset="@/assets/landingpage/team-rob@2x.jpg 2x" alt="Robert Dopke SportlerPlus">
				</div>

				<div class="athletes__item__text">
					<div class="athletes__item__category">
						<img src="@/assets/landingpage/ic_24_team_athletik.svg" alt="Functional Fitness">
						Functional Fitness
					</div>
					<h2>Robert Dopke</h2>
					<p>Rob ist DER Pro, wenn es um Functional Fitness und CrossFit geht. Der 24-Jährige kommt aus Hamburg und studiert Jura, aber seine größte Leidenschaft ist der Sport. Als Coach treibt er regelmäßig Sportler zu Höchstleistungen. Ihr findet ihn zudem als SportlerPlus Gesicht in unserer Fitness-App wieder.</p>
					<a href="https://www.instagram.com/robertj.d" target="_blank" class="athletes__item__social athletes__item__social--instagram">
						<img src="@/assets/landingpage/ic_team_instagram.svg" alt="Instagram">
						@robertj.d
					</a>
				</div>

			</div>-->
		</div>

		<!-- Bewerben -->
		<div class="special-block">
			<h2>Du willst ins Team?</h2>
			<p>Wir suchen regelmäßig neue Athletes für unsere Crew. Schreib uns gerne eine Nachricht und wir melden uns bei dir.</p>
			<a @click="openContactModal" target="_blank" class="button button--primary">
				Jetzt bewerben
			</a>					
		</div>

		<ContactModal :programmeType="'athletes'"></ContactModal>
		<AthleteModal :athlete="currentAthlete"></AthleteModal>

		<LandingpageFooter></LandingpageFooter>

	</div>
</template>

<script>
import LandingpageHeader from "@/components/LandingpageHeader.vue";
import LandingpageFooter from "@/components/LandingpageFooter.vue";
import ContactModal from "@/components/ContactModal.vue";
import AthleteModal from "@/components/AthleteModal.vue";
import axios from 'axios';

export default {
	name: 'Team',
	components: { LandingpageHeader, LandingpageFooter, ContactModal, AthleteModal },
	mounted() {
		this.init();
	},
	data() {
		return {
			athletes: [],
			apiUrl: null,
			currentAthlete: null,
		}
	},
	methods: { 
		init() {
			this.apiUrl = this.$store.getters.getApiUrl;
			// retrieve athletes from API via axios
			axios.get(this.apiUrl + "athletes").then((response) => {
				this.athletes = response.data.items;
				this.athletes.sort((a, b) => a.sort_order - b.sort_order);
			});
		},
		scrollToTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				/* TODO: Smooth Transition doesn't work */
				behavior: 'smooth'
			});
		},
		openContactModal() {
			this.$modal.show("contactModal");
		},
		openAthleteModal(athlete) {
			this.currentAthlete = athlete;
			this.$modal.show("athleteModal");
		},
		socialLinkType(link) {
			if (link.includes("instagram.")) {
				return "instagram";
			} else if (link.includes("tiktok.")) {
				return "tiktok";
			} else if (link.includes("youtube.")) {
				return "youtube";
			}
		},
		sportsTypeName(athlete) {
			if (athlete.sports_type_alt_name) {
				return athlete.sports_type_alt_name;
			}
			switch(athlete.sports_type_icon) {
				case 1:
					return "Functional Fitness";
				case 2:
					return "Yoga";
				case 3:
					return "Handball";
				case 4:
					return "Fußball";
				case 5:
					return "Triathlon";
				case 6:
					return "Turnen";
				default:
					return "";
			}
		}
	},
}
</script>

<style lang="scss">

	.athleten {
		position: relative;
		background: $colorWhite;
		overflow: hidden;

		nav {
			z-index: 2;

			@media screen and (min-width: 768px) {
				background: none;
				position: relative;
			}
		}

		footer {
			box-shadow: 0 -1px 0 0 rgba($colorBlack, .08);

			@media screen and (min-width: 768px) {
				margin-top: -32px;
			}
		}
		
		input {
			text-align: left !important;
		}
	}

	.athletes {
		position: relative;
		z-index: 2;
		max-width: 1088px;
		margin: 0px auto 96px;
		padding: 0 16px;

		@media screen and (min-width: 768px) {
			padding: 0 24px;
			margin-bottom: 160px;
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: space-between;

			@media screen and (min-width: 576px) {
				flex-direction: row;
			}
		}

		&__card {
			position: relative;
			display: flex;
			flex-direction: column;
			height: 400px;
			border-radius: 12px;
			box-shadow: 0 8px 16px 0 rgba($colorBlack, .04);
			background: $colorBlack;
			padding: 24px 24px;
			margin-bottom: 16px;
			overflow: hidden;
			cursor: pointer;
			transition: all .2s ease-in-out;

			@media screen and (min-width: 576px) {
				width: calc((100% - 24px) / 2);
				margin-bottom: 24px;
			}

			@media screen and (min-width: 768px) {
				width: calc((100% - 48px) / 3);
			}

			.landingpage--home & {
				width: 100% !important;
				height: 320px;
			}

			&:hover {
				box-shadow: 0 12px 24px 0 rgba($colorBlack, .12);
			}

			&__image {
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 12px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transform-origin: top center;
					transition: all .5s ease-in-out;

					.athletes__card:hover & {
						transform: scale(1.05);
					}
				}

				&:before {
					content: '';
					position: absolute;
					z-index: 2;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 160px;
					background-image: linear-gradient(180deg, rgba(0,0,0,0) 2%, rgba(0,0,0, .8) 100%);
				}
			}

			&__social {
				position: relative;
				z-index: 2;
				display: flex;
				background: rgba($colorWhite, .16);
				border-radius: 8px;
				width: 40px;
				height: 40px;
				padding: 8px;
				margin-bottom: auto;
				transition: all .2s ease-in-out;

				&:hover {
					background: rgba($colorWhite, .24);
				}

				img {
					width: 24px;
					height: 24px;
				}
			}

			&__title {
				position: relative;
				z-index: 2;
				display: flex;
				flex-direction: column;
				font-size: 32px;
				line-height: 32px;
				font-weight: 900;
				color: $colorWhite;
				margin-top: auto;
				margin-bottom: 21px;

				span {
					-webkit-text-fill-color: transparent;
					-webkit-text-stroke: 1.5px;
				}
			}

			&__cta {
				position: relative;
				z-index: 2;
				display: inline-flex;
				align-items: center;
				font-size: 17px;
				line-height: 17px;
				font-weight: 600;
				color: $colorWhite;
				letter-spacing: -0.35px;
				transition: all .35s ease-in-out;

				@media screen and (min-width: 768px) {
					opacity: 0;
					margin-bottom: -42px;

					.athletes__card:hover & {
						margin-bottom: 0;
						opacity: 1;
					}
				}

				&:after {
					content: url('../assets/ic_16_arrow_white.svg');
					width: 16px;
					height: 16px;
					margin-left: 6px;
				}
			}
		}

		&__item {
			display: flex;
			align-items: center;
			margin: 59px 0;
			flex-direction: column;

			@media screen and (min-width: 768px) {
				flex-direction: row;
				margin: 128px 0;
			}

			&:first-child {
				margin: 0;
			}

			&:nth-child(even) {

				@media screen and (min-width: 768px) {
					flex-direction: row-reverse;
				}

				.athletes__item__text {
					@media screen and (min-width: 768px) {
						padding: 0 48px 0 0;
					}

					@media screen and (min-width: 1024px) {
						padding: 0 96px 0 0;
					}
				}

				.athletes__item__image {
					justify-content: flex-start;
				}
			}

			&__image {
				width: calc(100% + 32px);
				margin: 0 -16px 24px;
				display: flex;
				justify-content: flex-end;

				@media screen and (min-width: 768px) {
					width: 50%;
					margin: 0;
				}

				img {
					width: 100%;
					height: 100%;

					@media screen and (min-width: 1024px) {
						width: auto;
						height: auto;
					}
				}
			}

			&__text {
				width: 100%;

				@media screen and (min-width: 768px) {
					width: 50%;
					padding-left: 48px;
				}

				@media screen and (min-width: 1024px) {
					padding-left: 96px;
				}

				h2 {
					margin-bottom: 17px;

					@media screen and (min-width: 1024px) {
						font-size: 40px;
						line-height: 52px;
						letter-spacing: -0.83px;
						margin-bottom: 29px;
					}
				}
			}

			&__category {
				display: flex;
				align-items: center;
				font-size: 14px;
				font-weight: 700;
				line-height: 28px;
				color: $colorPrimary;
				text-transform: uppercase;
				margin-bottom: 9px;

				img {
					width: 24px;
					height: 24px;
					margin-right: 8px;
				}
			}

			&__social {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 700;
				color: $colorPrimary !important;
				text-decoration: none;
				background: rgba($colorPrimary, .08);
				border-radius: 8px;
				transition: all .2s ease-in-out;
				margin-top: 8px;

				&:hover {
					background: rgba($colorPrimary, .12);
				}

				&--instagram {
					padding: 10px 16px 10px 10px;

					img {
						margin-right: 12px;
					}
				}

				&--website {
					padding: 12px 16px;

					img {
						margin-left: 6px;
					}
				}
			}
		}
	}

</style>
<template>
    <div class="teambox">
        <div class="teambox__wrapper">
            <div class="teambox__text">
                <div class="teambox__logos">
                    <a v-for="ht in heroteams" v-bind:key="ht.id" :href="ht.link" target="_blank">
                        <img v-if="ht.logo_bw" :src="'https://app.sportlerplus.com' + ht.logo_bw.meta.download_url" :alt="'Logo ' + ht.name">
                    </a>
                </div>
                <h2>Wir stehen an <span>eurer</span> Seite!</h2>
                <p>Nicht nur die Hero-Teams genießen unseren Support. Wir unterstützen auch dich und dein Team mit tollen Aktionen in unserem Shop!</p>
                <a href="https://shop.sportlerplus.de/aktionen/" target="_blank" class="button button--primary buttom--compact">Jetzt Rabatt sichern</a>
            </div>
        </div>
        <img src="@/assets/landingpage/teambox-image.png" srcset="@/assets/landingpage/teambox-image@2x.png 2x" class="desktop" />
        <img src="@/assets/landingpage/teambox-freisteller.png" srcset="@/assets/landingpage/teambox-freisteller@2x.png 2x" class="mobile" />
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Teambox',
    mounted() {
        this.init();
    },
    data() {
        return {
            heroteams: null,
        }
    },
    methods: {
        init() {
            let apiUrl = this.$store.getters.getApiUrl;

            // retrieve hero teams from API via axios
			axios.get(apiUrl + "heroteams").then((response) => {
				this.heroteams = response.data.items;
				// sort hero teams by sort_order field
				this.heroteams.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);
			});

        }
    }
}
</script>

<style lang="scss">
    .teambox {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        margin: 96px 0 0;
        background: $colorBlack;
        overflow: hidden;

        @media screen and (min-width: 768px) {
            flex-direction: row;
            min-height: 614px;
            margin: 240px 0 0;
            overflow: visible;

            .landingpage--teams & {
                margin: 80px 0 0 0;
            }
        }

        > img {
            width: auto;
            height: auto;
            object-fit: cover;
            object-position: top;

            @media screen and (min-width: 768px) {
                position: absolute;
                top: -55px;
                width: 100%;
                height: calc(100% + 55px);
                object-fit: cover;
                max-width: 1440px;
                left: 50%;
                transform:  translateX(-50%);
            }

            &.mobile {
                display: block;
                margin-top: 40px;
                transform: translateX(24px);

                @media screen and (min-width: 768px) {
                    display: none;
                }
            }

            &.desktop {
                display: none;

                @media screen and (min-width: 768px) {
                    display: block;
                }
            }
        }

        &__wrapper {
            display: flex;
            flex: 1;
            position: relative;
            z-index: 1;
            width: 100%;
            max-width: 1088px;
            margin: 0px auto;
            padding: 0 16px;

            @media screen and (min-width: 768px) {
                padding: 0 24px;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto 0;
            text-align: center;

            @media screen and (min-width: 768px) {
                width: 40%;
                align-items: flex-start;
                text-align: left;
            }

            h2 {
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -.64px;
                color: $colorWhite;
                margin-bottom: 23px;
                max-width: 330px;

                @media screen and (min-width: 768px) {
                    font-size: 48px;
                    line-height: 56px;
                    letter-spacing: -1px;
                    margin-bottom: 31px;
                    max-width: 100%;
                }

                span {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        width: 100%;
                        height: 6px;
                        bottom: -6px;
                        left: 0;
                        background: $colorPrimary;

                        @media screen and (min-width: 768px) {
                            height: 9px;
                        }
                    }
                }
            }

            p {
                color: $colorWhite;
                opacity: 1;
                margin-bottom: 32px;

                @media screen and (min-width: 768px) {
                    font-size: 17px;
                    line-height: 28px;
                    margin-bottom: 34px;
                }
            }

            .button {
                display: inline-flex;
                width: auto;

                @media screen and (max-width: 767px) {
                    padding: 14px 24px !important;
                    font-size: 17px !important;
                    line-height: 20px !important;
                }

                @media screen and (min-width: 768px) {
                    margin-left: 0;
                    padding-left: 24px !important;
                    padding-right: 24px !important;
                }
            }
        }

        &__logos {
            display: flex;
            margin-top: 64px;
            margin-bottom: 32px;

            @media screen and (min-width: 768px) {
                margin-top: 0;
                margin-bottom: 36px;
            }

            a {
                &:not(:last-child) {
                    margin-right: 16px;

                    @media screen and (min-width: 768px) {
                        margin-right: 20px;
                    }
                }
            }

            img {
                height: 32px;
                filter: saturate(0);
                transition: all .2s ease-in-out;
                opacity: .48;

                @media screen and (min-width: 768px) {
                    height: 48px;
                }

                &:hover {
                    filter: saturate(1);
                    opacity: 1;
                }
            }
        }
    }
</style>
import firebase from 'firebase';

import "firebase/analytics";
import "firebase/firestore";

const config = {
    apiKey: "AIzaSyALjShpcB6k-iIl1ydcy4v9eaxIlR-Ds8Y",
    authDomain: "sportler-plus.firebaseapp.com",
    databaseURL: "https://sportler-plus.firebaseio.com",
    projectId: "sportler-plus",
    storageBucket: "sportler-plus.appspot.com",
    messagingSenderId: "355945411235",
    appId: "1:355945411235:web:2ae0e4e050440a0efe4516",
    measurementId: "G-SPJ9WVRWGQ"
};
let fb = firebase.initializeApp(config);
let db = fb.firestore();
let storage = fb.storage()

const favouritesCollection = db.collection('favourites');
const userPlaylistCollection = db.collection('userPlaylists');
const userFeedbackCollection = db.collection('userFeedback');
const userInformationCollection = db.collection('userInformation');
const premiumPurchasesCollection = db.collection('premiumPurchases');
const devicesCollection = db.collection('devices');
const receiptCollection = db.collection('receipts');
const fitnessPlanCollection = db.collection('fitnessPlan');
const contactFormCollection = db.collection('contactForm');

const analytics = firebase.analytics();

export {
    fb,
    db,
    analytics,
    favouritesCollection,
    userPlaylistCollection,
    userFeedbackCollection,
    userInformationCollection,
    premiumPurchasesCollection,
    devicesCollection,
    receiptCollection,
    fitnessPlanCollection,
    contactFormCollection,
    storage,
}
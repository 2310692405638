<template>
    <VModal name="confirmDeleteChallengeModal" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
        <div class="vm-content">
            <img src="@/assets/notification/ic_notification_warning.svg" alt="">
            <h3>
                Bist du sicher?
            </h3>
            <p>
                Möchtest du die laufende Challenge wirklich abbrechen?
            </p>
            <p>
                Bei Abbruch werden alle zukünftigen Einheiten der Challenge aus deinem Fitnessplan
                gelöscht. Starte die Challenge gerne zu einem späteren Zeitpunkt erneut.
            </p>
            <v-btn type="button" class="button button--primary button--small" @click="reallyDelete">Ja, beenden</v-btn>
            <v-btn type="button" class="button button--secondary button--small" @click="dismissDialog">Nein, Challenge fortsetzen</v-btn>
        </div>
    </VModal>
</template>

<style lang="scss">
</style>
<script>
export default {
    name: 'ConfirmDeleteChallengeModal',
    props: {
        fitnessPlanEvent: {
            type: Object,
            default() { return {}; }
        }
    },
    methods: {
        dismissDialog() {
            this.$modal.hide('confirmDeleteChallengeModal');
        },
        reallyDelete() {
            this.$modal.hide('confirmDeleteChallengeModal');
            this.$emit("deleteFitnessPlanChallenge", this.fitnessPlanEvent);
        },
    }
}
</script>
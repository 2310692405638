import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Articles from '../views/Articles.vue'
//import Workout from '../views/Workouts.vue'
import Training from '../views/Training.vue'
import YourPro from '../views/YourPro.vue'
import store from '@/store/index.js';
import SettingsLogin from '../views/SettingsLogin.vue';
import OnboardingStep2 from '../views/OnboardingStep2';
import OnboardingStep3 from '../views/OnboardingStep3';
import OnboardingStep4 from '../views/OnboardingStep4';
import Landingpage from '../views/Landingpage.vue';
import Athleten from '../views/Athleten.vue';
import PlaylistCountdown from '../views/PlaylistCountdown.vue';
import Fitnessplan from '../views/Fitnessplan.vue';
import FitnessplanChallengeSuccess from '../views/FitnessplanChallengeSuccess.vue';

Vue.use(VueRouter)

const colorBlack= "#1E1D1F";
const colorWhite = "#ffffff";
const colorModal= "#6E6F71";

var lastActiveNonModal;

const routes = [
  {
    path: '/landingpage',
    name: 'Landingpage',
    alias: '/code',
    component: Landingpage,
    meta: {
      hideNavbars: true,
      mainExtraClasses: "landingpage landingpage--dark landingpage--home",
    }
  },
  {
    path: '/story',
    name: 'Story',
    component: () => import('../views/Story.vue'),
    meta: {
      hideNavbars: true,
      mainExtraClasses: "landingpage landingpage--story",
    }
  },
  {
    path: '/magazine',
    name: 'BrandpageMagazine',
    component: () => import('../views/BrandpageMagazine.vue'),
    meta: {
      hideNavbars: true,
      mainExtraClasses: "landingpage landingpage--magazine",
    }
  },
  {
    path: '/athleten',
    name: 'Athleten',
    component: Athleten,
    meta: {
      hideNavbars: true,
      mainExtraClasses: "landingpage landingpage--athleten",
    }
  },
  {
    path: '/teams',
    name: 'Teams',
    component: () => import('../views/Teams.vue'),
    meta: {
      hideNavbars: true,
      mainExtraClasses: "landingpage landingpage--teams",
    }
  },
  {
    path: '/pakete',
    name: 'Pakete',
    component: () => import('../views/Pakete.vue'),
    meta: {
      hideNavbars: true,
      mainExtraClasses: "landingpage",
    }
  },
  {
    path: '/team-programm',
    name: 'Team Programm',
    component: () => import('../views/Team-Programm.vue'),
    meta: {
      hideNavbars: true,
      mainExtraClasses: "landingpage",
    }
  },
  {
    path: '/LandingpageAGB',
    name: 'LandingpageAGB',
    component: () => import('../views/LandingpageAGB.vue'),
    meta: {
      hideNavbars: true,
      mainExtraClasses: "landingpage",
    }
  },
  {
    path: '/download',
    alias: '/sharing/*',
    name: 'Download',
    component: () => import('../views/Download.vue'),
    meta: {
      hideNavbars: true,
      mainExtraClasses: "landingpage",
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    props: true,
    meta: {
      authRequired: true,
      title: "Profil",
      whiteBackground: true,
    },
    children: [
      {
        path: '/neuigkeiten',
        name: 'News',
        component: () => import('../views/News.vue'),
        meta: {
          authRequired: true,
          showModal: true,
          title: "Neuigkeiten",
          bgColor: colorModal,
        }
      },
      {
        path: '/neuigkeiten/detail/:id',
        name: 'News Detail',
        component: () => import('../views/NewsDetail.vue'),
        meta: {
          authRequired: true,
          showModal: true,
          title: "",
          bgColor: colorModal,
        }
      },
      {
        path: '/settings/personal',
        name: 'SettingsPersonal',
        component: () => import('../views/SettingsPersonal.vue'),
        meta: {
          authRequired: true,
          showModal: true,
          title: "Persönliche Angaben",
          bgColor: colorModal,
        }
      },
      {
        path: '/settings/premium',
        name: 'Premium',
        component: () => import('../views/Premium.vue'),
        meta: {
          authRequired: true,
          showModal: true,
          title: "",
          bgColor: colorModal,
        }
      },
      {
        path: '/settings/login',
        name: 'SettingsLogin',
        component: SettingsLogin,
        meta: {
          authRequired: true,
          showModal: true,
          title: "Zugangsdaten",
          bgColor: colorModal,
        }
      },
      {
        path: '/password-change',
        name: 'PasswordChange',
        component: () => import('../views/PasswordChange.vue'),
        meta: {
          title: 'Passwort zurücksetzen',
          showModal: true,
          bgColor: colorModal,
        }
      },
      {
        path: '/settings/notifications',
        name: 'SettingsNotifications',
        component: () => import('../views/SettingsNotifications.vue'),
        meta: {
          authRequired: true,
          showModal: true,
          title: "Benachrichtigungen",
          bgColor: colorModal,
        }
      },
      {
        path: '/settings/sportstypes',
        name: 'SettingsSportsTypes',
        component: () => import('../views/SettingsSportsTypes.vue'),
        meta: {
          authRequired: true,
          showModal: true,
          title: "Sportarten",
          bgColor: colorModal,
        }
      },
      {
        path: '/settings/delete',
        name: 'SettingsDelete',
        component: () => import('../views/SettingsDelete.vue'),
        meta: {
          authRequired: true,
          showModal: true,
          title: "",
          bgColor: colorModal,
        }
      },
    ]
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Home',
      useLogoAsTitle: true,
      hideNavbarsForAnonUsers: true,
    },
    component: Home,
    children: [
      {
        path: '/info/:id',
        name: 'InformationPage',
        component: () => import('../views/InformationPage.vue'),
        meta: {
          showModal: true,
          title: "",
          bgColor: colorModal,
        }
      },
      {
        path: '/support/faq',
        name: 'SupportFAQ',
        component: () => import('../views/SupportFAQCategorySelect.vue'),
        meta: {
          showModal: true,
          title: "Fragen & Antworten",
          bgColor: colorModal,
        }
      },
      {
        path: '/support/faq/:category',
        name: 'SupportFAQCategorySelect',
        component: () => import('../views/SupportFAQ.vue'),
        meta: {
          showModal: true,
          title: "Fragen & Antworten",
          bgColor: colorModal,
        }
      },
      {
        path: '/support/faq-detail/:id',
        name: 'SupportFAQDetail',
        component: () => import('../views/SupportFAQDetail.vue'),
        meta: {
          showModal: true,
          bgColor: colorModal,
        }
      },
      {
        path: '/support/problem',
        name: 'SupportProblem',
        component: () => import('../views/SupportProblem.vue'),
        meta: {
          authRequired: true,
          showModal: true,
          title: "Problem melden",
          bgColor: colorModal,
        }
      },
      {
        path: '/disclaimer/health',
        name: 'HealthDisclaimer',
        component: () => import('../views/HealthDisclaimer.vue'),
        meta: {
          showModal: true,
          title: "Gesundheitshinweise",
          bgColor: colorModal,
        }
      },
    ]
  },
  {
    path: '/sign-in',
    name: 'signin',
    component: () => import('../views/Signin.vue'),
    meta: {
      title: 'Login',
      showBackButton: true,
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue'),
    meta: {
      title: 'Registrierung',
      showBackButton: true,
      progressBar: '100'
    }
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('../views/Welcome.vue'),
    meta: {
      title: ' ',
      hideNavbars: true,
      authRequired: true,
    }
  },
  {
    path: '/trackinghint',
    name: 'trackinghint',
    component: () => import('../views/TrackingHint.vue'),
    meta: {
      title: ' ',
      hideNavbars: true,
      authRequired: true,
    }
  },
  {
    path: '/password-forgot',
    name: 'PasswordForgot',
    component: () => import('../views/PasswordForgot.vue'),
    meta: {
      title: 'Passwort vergessen',
      showBackButton: true,
    }
  },
  {
    path: '/trainieren',
    name: 'Training',
    component: Training,
    /* old routes, avoid 404 errors */
    alias: ['/workouts',  '/playlists'],
    meta: {
        authRequired: true,
        title: "Trainieren",
    },
    children: [
        {
            path: '/workout/:id',
            alias: '/workouts/:id',
            name: 'Workout',
            component: () => import('../views/WorkoutDetail.vue'),
            props: true,
            meta: {
                showModal: true,
                bgColor: colorBlack,
            }
        },
        {
            path: '/workout/video/start',
            name: 'WorkoutVideo',
            component: () => import('../views/WorkoutVideo.vue'),
            meta: {
                showModal: true,
                bgColor: colorBlack,
            }
        },
        {
            path: '/playlist/start',
            name: 'PlaylistStart',
            component: () => import('../views/PlaylistStart.vue'),
            meta: {
                title: "Workout",
                showModal: true,
                bgColor: colorBlack,
            },
            children: [
              {
                path: '/playlist/start/preview/:exerciseId',
                name: 'PlaylistStartExercisePreview',
                component: () => import('../views/ExercisePreview.vue'),
                meta: {
                  showModal: true,
                  bgColor: colorBlack,
                }
              },
            ]
        },
        {
            path: '/playlist/exercises',
            name: 'PlaylistExercises',
            component: () => import('../views/PlaylistExercises.vue'),
            meta: {
                title: "Übungsvorschau",
                showModal: true,
                bgColor: colorBlack,
            },
            children: [
              {
                path: '/playlist/exercises/preview/:exerciseId',
                name: 'PlaylistExercisePreview',
                component: () => import('../views/ExercisePreview.vue'),
                meta: {
                  showModal: true,
                  bgColor: colorBlack,
                }
              },
            ]
        },
        {
            path: '/playlist/countdown',
            name: 'PlaylistCountdown',
            component: PlaylistCountdown,
            meta: {
                showModal: true,
                bgColor: colorBlack,
            }
        },
        {
            path: '/playlist/play',
            name: 'PlaylistVideoPlayer',
            component: () => import('../views/PlaylistVideoPlayer.vue'),
            meta: {
                showModal: true,
                bgColor: colorBlack,
            }
        },
        {
            path: '/playlist/success',
            name: 'PlaylistSuccess',
            component: () => import('../views/PlaylistSuccess.vue'),
            meta: {
                title: "Workout",
                showModal: true,
            }
        },
      ]
  },
  /*{
    path: '/workouts',
    name: 'Workouts',
    component: Workout,
    meta: {
      authRequired: true,
      title: "Workouts",
    },
  },*/
  /*{
    path: '/playlists',
    name: 'Playlists',
    component: () => import('../views/Playlists.vue'),
    meta: {
      authRequired: true,
      title: "Playlists",
    },
  },*/
  {
    path: '/playlists/category/:categoryId',
    name: 'PlaylistsByCategory',
    component: () => import('../views/PlaylistsByCategory.vue'),
    meta: {
      authRequired: true,
      showBackButton: true,
    }
  },
  {
    path: '/playlists/sportstype/:sportsTypeId',
    name: 'PlaylistsBySportsType',
    component: () => import('../views/PlaylistsBySportsType.vue'),
    meta: {
      authRequired: true,
      showBackButton: true,
      whiteBackground: true,
      title: "Meine Sportarten"
    }
  },
  {
    path: '/playlists/personal',
    name: 'PlaylistsPersonal',
    component: () => import('../views/PlaylistsPersonal.vue'),
    meta: {
      authRequired: true,
      showBackButton: true,
      title: "Meine Workouts"
    }
  },
  {
    path: '/playlists/create/start',
    name: 'PlaylistCreateName',
    component: () => import('../views/PlaylistCreateName.vue'),
    meta: {
      authRequired: true,
      title: "Workout erstellen",
      showBackButton: true,
      progressBar: '33',
      hideBottomNavbar: true,
    }
  },
  {
    path: '/playlists/create/select-exercises',
    name: 'PlaylistCreateExercises',
    component: () => import('../views/PlaylistCreateExercises.vue'),
    meta: {
      authRequired: true,
      title: "Workout erstellen",
      showBackButton: true,
      progressBar: '66',
      hideBottomNavbar: true,
    },
    children: [
      {
        path: '/exercise/preview/:exerciseId',
        name: 'ExercisePreview',
        component: () => import('../views/ExercisePreview.vue'),
        meta: {
          showModal: true,
          bgColor: colorModal,
        }
      },
    ]
  },
  {
    path: '/playlists/create/summary',
    name: 'PlaylistCreateSummary',
    component: () => import('../views/PlaylistCreateSummary.vue'),
    meta: {
      authRequired: true,
      title: "Workout erstellen",
      showBackButton: true,
      progressBar: '100',
      hideBottomNavbar: true,
    }
  },
  {
    path: '/articles',
    name: 'Articles',
    component: Articles,
    meta: {
      authRequired: true,
      title: "Magazin",
    }
  },
  {
    path: '/articles_by_tags/:tags',
    name: 'ArticlesByTags',
    component: () => import('../views/ArticlesByTags.vue'),
    meta: {
      //authRequired: true,
      showBackButton: true,
      title: "Magazin",
    }
  },
  {
    path: '/articles/:id',
    alias: '/articles/:series/:id',
    name: 'Article',
    component: () => import('../views/ArticleDetail.vue'),
    meta: {
      showBackButton: true,
      title: "Artikel",
    }
  },
  {
    path: '/onboarding-step2',
    name: 'OnboardingStep2',
    component: OnboardingStep2,
    meta: {
      title: 'Registrierung',
      showBackButton: true,
      skipRegister: true,
      progressBar: '25'
    }
  },
  {
    path: '/onboarding-step3',
    name: 'OnboardingStep3',
    component: OnboardingStep3,
    meta: {
      title: 'Registrierung',
      showBackButton: true,
      skipRegister: true,
      progressBar: '50'
    }
  },
  {
    path: '/onboarding-step4',
    name: 'OnboardingStep4',
    component: OnboardingStep4,
    meta: {
      title: 'Registrierung',
      showBackButton: true,
      progressBar: '75'
    }
  },
  {
    path: '/favourites',
    name: 'Favourites',
    component: () => import('../views/Favourites.vue'),
    meta: {
      authRequired: true,
      title: "Meine Favoriten",
    }
  },
  {
    path: '/probetraining',
    name: 'Probetraining',
    component: () => import('../views/Probetraining.vue'),
    meta: {
      authRequired: true,
      title: ' ',
      showBackButton: true,
      whiteBackground: true,
    }
  },
  {
    path: '/fitnessplan',
    name: 'Fitnessplan',
    component: Fitnessplan,
    meta: {
      authRequired: true,
      title: "Trainingsplan",
    }
  },
  {
    path: '/fitnessplan/calendar',
    name: 'FitnessplanCalendar',
    component: () => import('../views/FitnessplanCalendar.vue'),
    meta: {
      authRequired: true,
      title: "Trainingsplan",
    }
  },
  {
    path: '/fitnessplan/challenges',
    name: 'FitnessplanChallenges',
    component: () => import('../views/FitnessplanChallenges.vue'),
    meta: {
      authRequired: true,
      title: "Trainingsplan",
    }
  },
  {
    path: '/fitnessplan/customActivity',
    name: 'FitnessPlanEditCustomActivity',
    component: () => import('../views/FitnessPlanEditCustomActivity.vue'),
    meta: {
      authRequired: true,
      title: "Erfassen",
      showBackButton: true,
      whiteBackground: true,
      hideBottomNavbar: true,
    }
  },
  {
    path: '/fitnessplan/detailsActivity',
    name: 'FitnessplanDetailsActivity',
    component: () => import('../views/FitnessplanDetailsActivity.vue'),
    meta: {
      authRequired: true,
      title: "Workoutdetails",
      showBackButton: true,
      whiteBackground: true,
      hideBottomNavbar: true,
    }
  },
  {
    path: '/fitnessplan/create',
    name: 'FitnessplanCreate',
    component: () => import('../views/FitnessplanCreate.vue'),
    meta: {
      authRequired: true,
      title: "Training erstellen",
      showBackButton: true,
      hideBottomNavbar: true,
    }
  },
  {
    path: '/fitnessplan/create/exercises',
    name: 'FitnessplanCreateExercises',
    component: () => import('../views/FitnessplanCreateExercises.vue'),
    meta: {
      authRequired: true,
      title: "Übung wählen",
      showBackButton: true,
      hideBottomNavbar: true,
    }
  },
  {
    path: '/fitnessplan/challenge/selectDate',
    name: 'FitnessplanChallengeSelectDate',
    component: () => import('../views/FitnessplanChallengeSelectDate.vue'),
    meta: {
      authRequired: true,
      title: "Challenge einrichten",
      showBackButton: true,
      hideBottomNavbar: true,
      progressBar: '33'
    }
  },
  {
    path: '/fitnessplan/challenge/selectTime',
    name: 'FitnessplanChallengeSelectTime',
    component: () => import('../views/FitnessplanChallengeSelectTime.vue'),
    meta: {
      authRequired: true,
      title: "Challenge einrichten",
      showBackButton: true,
      hideBottomNavbar: true,
      progressBar: '66'
    }
  },
  {
    path: '/fitnessplan/challenge/showSummary',
    name: 'FitnessplanChallengeSummary',
    component: () => import('../views/FitnessplanChallengeSummary.vue'),
    meta: {
      authRequired: true,
      title: "Challenge einrichten",
      showBackButton: true,
      hideBottomNavbar: true,
      progressBar: '100'
    }
  },
  {
    path: '/fitnessplan/challenge/intermediate',
    name: 'FitnessplanChallengeIntermediate',
    component: () => import('../views/FitnessplanChallengeIntermediate.vue'),
    meta: {
      authRequired: true,
      title: " ",
      showBackButton: true,
      whiteBackground: true,
      hideBottomNavbar: true,
    }
  },
  {
    path: '/fitnessplan/challenge/success',
    name: 'FitnessplanChallengeSuccess',
    component: FitnessplanChallengeSuccess,
    meta: {
      authRequired: true,
      title: " ",
      showBackButton: true,
      whiteBackground: true,
      hideBottomNavbar: true,
    }
  },
  {
    path: '/your-pro',
    name: 'YourPro',
    component: YourPro,
    meta: {
      authRequired: true,
      title: "Coaches",
      showBackButton: true,
    }
  },
  {
    path: '/your-pro-detail/:id',
    name: 'YourProDetail',
    component: () => import('../views/YourProDetail.vue'),
    meta: {
      authRequired: true,
      title: " ",
      whiteBackground: true,
      showBackButton: true,
    }
  },
  {
    path: '/statistiken',
    name: 'Statistics',
    component: () => import('../views/Statistics.vue'),
    meta: {
      authRequired: true,
      title: "Aktivität",
    }
  },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    /* eslint-disable no-unused-vars */
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
		} else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
		}
    },
});

var backButtonUsed;
var disallowHistoryBack;
window.popStateDetected = false;
window.addEventListener('popstate', () => {
    window.popStateDetected = true;
});

/* eslint-disable no-unused-vars */
router.beforeEach((to, from, next) => {
    let sourceIsArticlePage = from.path.startsWith("/articles") || from.path.startsWith("/magazine");
    let targetIsArticlePage = to.path.startsWith("/articles") || to.path.startsWith("/magazine");
    if (!( (sourceIsArticlePage && targetIsArticlePage) || (from.path === "/" && (targetIsArticlePage && to.path.length > 9)) )) {
      window.localStorage.removeItem('searchWord');
      store.state.articleCategories = [];
    }

    backButtonUsed = window.popStateDetected;
    disallowHistoryBack = false;
    if (from.path.startsWith("/playlist/success")) {
        disallowHistoryBack = true;
    }
    window.popStateDetected = false;

    let landing_page_hosts = ["www.sportlerplus.de", "sportlerplus.de", "www.sportlerplus.com", "sportlerplus.com"];
    
    if (landing_page_hosts.find(el => el == window.location.hostname) && window.location.pathname == "/") {
            // don't use router functions here, results in infinite loop
            window.location = '/landingpage';
    }

    // save last route before entering any modal
    if (!(to.meta && to.meta.showModal)) {
        lastActiveNonModal = to;
    }

    if (to.path != "/sign-in") {
        window.localStorage.removeItem("_redirectAfterSignIn");
    }

    if (to.matched.some(record => record.meta.authRequired)) {
        if (!store.state.user) {
            window.localStorage.setItem("_redirectAfterSignIn", to.path);
            next({
            path: '/sign-in'
            });
        } else {
            attemptSetBGColor(to.meta.bgColor || colorWhite);
            next();
        }
    } else {
        attemptSetBGColor(to.meta.bgColor || colorWhite);
        next();
    }
});

function attemptSetBGColor(color) {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.bgColorMessageHandler) {
        window.webkit.messageHandlers.bgColorMessageHandler.postMessage({bgColor: color})
    }
    else if (window.AndroidJSInterface != undefined) {
        window.AndroidJSInterface.setBGColor(color);
    }
}

export default router;
export { router, lastActiveNonModal, backButtonUsed, disallowHistoryBack };
<template>
  <div class="yourpro noselect">
    <router-link v-for="coach in sortedCoaches" v-bind:key="coach.id" :to="targetLink(coach)" class="yourpro__item">
      <img :src="'https://app.sportlerplus.com/scaled/800/' + coach.image_url" alt="" class="yourpro__item__image">
      <h3 class="yourpro__item__title">
        <div class="yourpro__item__badge">{{ coach.badge }}</div>
        {{ coach.name }}
      </h3>
    </router-link>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'YourPro',
    methods: {
        init() {
            this.coaches = this.$store.getters.getCoaches;
            this.tabName = this.$route.query.tab;
        },
        targetLink(coach) {
            let link = '/your-pro-detail/' + coach.id;
            if (this.tabName) {
                link = link + "?tab=" + this.tabName;
            }
            return link;
        }
    },
    mounted() {
        this.init();
    },
    data() {
        return {
            coaches: [],
            tabName: null,
        }
    },
    computed: {
        sortedCoaches() {
            var coaches = [...this.coaches];
			coaches.sort((a, b) => a.sortOrder - b.sortOrder || a.id - b.id);
			return coaches
        }
    }
}
</script>

<style lang="scss">
  .yourpro {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      height: 160px;
      border-radius: 4px;
      overflow: hidden;
      text-decoration: none;
      background: rgba($colorBlack, .08);

      .yourpro & {
        width: calc(50% - 8px);
        margin-bottom: 16px;
      }

      &__image {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: auto 0 12px;
        padding: 0 16px;
        color: $colorWhite;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 24px;
        text-shadow: 0 2px 16px rgba($colorBlack ,0.48);
        max-width: 100%;
      }

      &__badge {
        font-size: 11px;
        line-height: 11px;
        font-weight: 700;
        letter-spacing: -0.13px;
        text-transform: uppercase;
        padding: 4px 5px 3px 6px;
        margin-bottom: 5px;
        border-radius: 2px;
        color: $colorWhite;
        background: $colorPrimary;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
      }
    }
  }
</style>
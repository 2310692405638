<template>
    <div class="register">
        <h2>Erzähl' uns etwas mehr über dich...</h2>
        
        <small>Deine Angaben nutzen wir ausschließlich für die Auswertung deiner Trainingsleistung.</small>

        <div class="form">

            <div class="form__layoutwrapper--50">

                <!-- Körpergröße -->
                <div class="form__stepper">
                    <div v-on:click="changeHeight(-1)" class="form__stepper__icon form__stepper__icon--down">
                    </div>
                    <div class="form__stepper__value">
                        <input @click="selectText($event.target)" @blur="height = parseInt($event.target.value) || 0" :value="height + ' cm'">
                    </div>
                    <div v-on:click="changeHeight(1)" class="form__stepper__icon form__stepper__icon--up">
                    </div>
                </div>

                <!-- Gewicht -->
                <div class="form__stepper">
                    <div v-on:click="changeWeight(-1)" class="form__stepper__icon form__stepper__icon--down">
                    </div>
                    <div class="form__stepper__value">
                        <input @click="selectText($event.target)" @blur="weight = parseInt($event.target.value) || 0" :value="weight + ' kg'">
                    </div>
                    <div v-on:click="changeWeight(1)" class="form__stepper__icon form__stepper__icon--up">
                    </div>
                </div>

            </div>

            <!-- Geschlecht -->
            <div class="form__selectionrow">
                <div class="form__selectionrow__item">
                    <input id="gender-0" name="gender-0" type="radio" v-model="gender" value="0">
                    <label for="gender-0">
                        Männlich
                    </label>
                </div>
                <div class="form__selectionrow__item">
                    <input id="gender-1" name="gender-1" type="radio" v-model="gender" value="1">
                    <label for="gender-1">
                        Weiblich
                    </label>
                </div>
                <div class="form__selectionrow__item">
                    <input id="gender-2" name="gender-2" type="radio" v-model="gender" value="2">
                    <label for="gender-2">
                        Divers
                    </label>
                </div>
            </div>

            <div class="form__input form__input--boxed">
                <img class="form__input__icon" src="@/assets/ic_32_date.svg">
                <input type="date" v-model="birthday" placeholder="Geburtstag (optional)">
                <label>Geburtstag (optional)</label>
            </div>

        </div>

        <v-btn class="button button--primary" @click="onboarding_continue">Weiter</v-btn>

    </div>
</template>

<script>
import router from '@/router';

export default {
    name: "OnboardingStep3",
    data() {
        return {
            height: 175,
            weight: 75,
            gender: null,
            birthday: null,
            userInformation: null,
        }
    },
    methods: {
        init(){
            this.userInformation = this.$store.getters.getOnboardingData;
            if (this.userInformation) {
                this.height = this.userInformation.height || 175;
                this.weight = this.userInformation.weight || 75;
                this.gender = this.userInformation.gender;
                // gets converted to string at times (page reload etc.)
                if (typeof(this.userInformation.birthday) == "string") {
                    this.birthday = this.userInformation.birthday.split('T')[0];
                }
                else if (this.userInformation.birthday != null) {
                    this.birthday = this.userInformation.birthday.toISOString().split('T')[0];
                }
            }
        },
        selectText(elem) {
            elem.select();
        },
        changeHeight(val) { this.height+=val },
        changeWeight(val) { this.weight+=val },
        onboarding_continue() {
            let user_birthday = null;
            if (this.birthday != null) {
                user_birthday = new Date(this.birthday);
                user_birthday.setSeconds(0);
                user_birthday.setMinutes(0);
                user_birthday.setHours(12);
            }
            this.$store.dispatch('updateOnboardingData', {
                height: this.height,
                weight: this.weight,
                birthday: user_birthday,
                gender: this.gender,
            });
            router.push("/onboarding-step4");
        }
    },
    components: {},
    computed: {

    },
    mounted() {
        this.init();
    }
}
</script>

<style lang="scss">
    .form {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 12px;
        grid-row-gap: 12px;
        width: 100%;

        &__layoutwrapper--50 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 12px;
            grid-row-gap: 12px;
            width: 100%;
        }

        &__stepper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background: $colorWhite;
            box-shadow: 0 8px 16px 0 rgba($colorBlack, .04), 0 0 0 1px rgba($colorBlack, .08);
            border-radius: 8px;
            transition: all .2s ease-in-out;

            &:hover {
                box-shadow: 0 8px 24px 0 rgba($colorBlack, .06), 0 0 0 1px rgba($colorBlack, .12);
            }

            input {
                width: 100%;
                text-align: center;
                font-size: 17px;
                font-weight: 600;

                &:focus {
                    outline: none;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                &[type=number] {
                  -moz-appearance: textfield;
                }
            }

            &__icon {
                position: relative;
                flex-shrink: 0;
                width: 44px;
                height: 64px;
                cursor: pointer;

                &:before {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 2px;
                    border-radius: 1px;
                    background: $colorPrimary;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }

                &--up {
                    &:after {
                       content: '';
                        position: absolute;
                        width: 2px;
                        height: 12px;
                        border-radius: 1px;
                        background: $colorPrimary;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%); 
                    }
                }
            }
        }

        &__selectionrow {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background: $colorWhite;
            box-shadow: 0 8px 16px 0 rgba($colorBlack, .04), 0 0 0 1px rgba($colorBlack, .08);
            border-radius: 8px;
            padding: 8px 4px;
            transition: all .2s ease-in-out;

            &:hover {
                box-shadow: 0 8px 24px 0 rgba($colorBlack, .06), 0 0 0 1px rgba($colorBlack, .12);
            }

            &__item {
                display: flex;
                width: 100%;

                label {
                    position: relative;
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0 4px;
                    color: $colorBlack;
                    padding: 13px 4px 14px;
                    border-radius: 4px;
                    width: 100%;
                    cursor: pointer;
                    transition: all .2s ease-in-out;

                    &:hover {
                        background: rgba($colorBlack, .04);
                    }
                }

                input {
                    display: none;

                    &:checked {
                        ~ label {
                            background: $colorPrimary;
                            color: $colorWhite;
                        }
                    }
                }

                &:not(:first-child):not(:last-child) {
                    label {

                        &:before, &:after {
                            content: '';
                            position: absolute;
                            width: 1px;
                            height: 24px;
                            background: #E4E3E4;
                        }

                        &:before {
                            left: -4px;
                            top: calc(50% - 12px);
                        }

                        &:after {
                            right: -4px;
                            top: calc(50% - 12px);
                        }
                    }
                }
            }
        }

        &__input {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &--boxed {
                input {
                    background: $colorWhite;
                    box-shadow: 0 8px 16px 0 rgba($colorBlack, .04), 0 0 0 1px rgba($colorBlack, .08) !important;
                    border-radius: 8px;
                    transition: all .2s ease-in-out;

                    &:hover {
                        box-shadow: 0 8px 24px 0 rgba($colorBlack, .06), 0 0 0 1px rgba($colorBlack, .12);
                    }

                    &:focus {
                        box-shadow: 0 8px 24px 0 rgba($colorBlack, .08), 0 0 0 1px rgba($colorBlack, .12) !important;
                    }
                }
            }

            &__icon {
                position: absolute;
                left: 12px;
            }

            label {
                position: absolute;
                top: 22px;
                font-size: 14px;
                font-weight: 500;
                color: rgba($colorBlack, .48);
                pointer-events: none;
            }

            input {
                justify-content: center;
                text-align: center;
                font-size: 14px;

                &::-webkit-input-placeholder {
                    opacity: 0 !important;
                }

                &[type="date"] {
                    padding: 18px 16px !important;
                    min-height: 64px !important;

                    &:before {
                        display: none !important;
                    }

                    &:not(:placeholder-shown), &[value=""] {
                        padding: 24px 16px 12px !important;
                    }
                }

                &:not(:placeholder-shown), &:focus, &[value=""] {
                    font-weight: 600;
                    padding: 26px 16px 14px !important;

                    ~ label {
                        top: 11px;
                        font-size: 11px;
                        font-weight: 500;
                    }
                }

                /* Disable native styling */
                -webkit-appearance: none;
                &::-webkit-date-and-time-value {
                    margin-right: 0 !important;
                }

                /* Placeholder Hack for iOS */
                &:not(.has-value):before {
                    color: rgba($colorBlack, .24);
                    content: attr(placeholder);
                }
            }
        }
    }

    .stepper {
        display: flex;
        align-items: center;
        padding: 24px 16px;
        margin-bottom: 16px;
        
        &:not(:last-child) {
            box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .04);
            margin-bottom: 0;
        }

        &__icon {
            flex-shrink: 0;
            width: 32px;
            height: 32px;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            cursor: pointer;
        }

        &__value {
            flex: 1;
            justify-content: center;
            font-size: 20px;
            font-weight: 600;
            color: $colorBlack;

            input {
                width: 100%;
                text-align: center;

                &:focus {
                    outline: none;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                &[type=number] {
                  -moz-appearance: textfield;
                }
            }
        }
    }

    .date-input {
        position: relative;
        margin-bottom: 32px;
        width: 100%;

        img {
            position: absolute;
            left: 16px;
            top: 16px;
        }

        button {
            position: absolute;
            top: 16px;
            right: 12px;
            width: 16px;
            height: 16px;
            padding: 16px;
            background: url('../assets/ic_nav_close.svg');
            background-size: 16px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    input[type="date"], input[type="time"] {
        display: flex;
        justify-content: center;
        font-size: 17px;
        font-weight: 600;
        color: $colorBlack;
        padding: 0;
        width: 100% !important;
        text-align: center;
        min-height: 64px;

        -webkit-appearance: none;
        &::-webkit-date-and-time-value{
            margin-right: 0 !important;
        }

        &:not(.has-value):before{
            color: rgba($colorBlack, .24);
            content: attr(placeholder);
        }
    }
</style>
<template>
    <router-link :to="path" class="grab grab--small">
        <div class="grab__icon">
            <img :src="require(`@/assets/${ iconSource }`)" alt="Button icon" />
        </div>
        <div class="grab__text">
            {{ firstLine }} <span>{{ secondLine }}</span>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "CtaButton",
	props: {
        firstLine: {
            type: String,
            default() {return {}; }
        },
        secondLine: {
            type: String,
            default() {return {}; }
        },
        path: {
            type: String,
            default() {return {}; }
        },
        iconSource: {
            type: String,
            default() {return {}; }
        }
	}
}
</script>
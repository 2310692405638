<template>
    <VModal @closed="emitClose" name="generalAlert" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
      <div class="vm-content">
        <img src="@/assets/notification/ic_notification_warning.svg" alt="">
        <h3>
          {{ title }}
        </h3>
        <p v-html="message">
        </p>
        <v-btn type="button" class="button button--primary button--small" @click="dismissDialog">Verstanden</v-btn>
      </div>
    </VModal>
</template>

<script>
    export default {
        name: 'GeneralAlertModal',
        props: {
            title: {
                type: String,
                default() { return {}; } 
            },
            message: {
                type: String,
                default() { return {}; } 
            }
        },
        methods: {
            dismissDialog() {
                this.$modal.hide('generalAlert');
            },
            emitClose() {
                this.$emit('closeModal');
            }
        }
    }
</script>
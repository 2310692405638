import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from 'axios';
import firebase from 'firebase';
import router from '@/router';
import {favouritesCollection, userPlaylistCollection, userInformationCollection, premiumPurchasesCollection, devicesCollection, receiptCollection, fitnessPlanCollection, storage} from '@/firebase/index.js';
import moment from 'moment';
import { addDays, toIsoString } from '@/sportlerplus-helper';
import {version} from "../../package";

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

const SECONDS_PER_DAY = 86400;

export default new Vuex.Store({
    state: {
        articles: [],
        readArticleList: [],
        displayedMotivationTextList: [],
        workouts: [],
        challenges: [],
        playlists: [],
        exercises: [],
        homePageInfo: [],
        categories: [],
        sportsTypes: [],
        equipmentList: [],
        userEquipment: [],
        coaches: [],
        articleCategories: [],
        articleMediaTypes: [],
        apiUrl: "https://cms.sportlerplus.com/api/v2/",
        user: null,
        isAuthenticated: false,
        userEmailVerified: false,
        onboardingData: {},
        temporaryPlaylist: {},
        workoutSource: [],
        deviceToken: null,
        operatingSystem: null,
        installationId: null,
        premiumStatus: null,
        receiptData: null,
        soundEnabled: true,
        selectedFitnessPlanActivity: null,
        recentFitnessPlanActivities: [],
        recentTrainingActivities: [],
        fitnessPlan: {},
        workoutIntensities: [{"id": 1, "name": "Leicht"}, {"id": 2, "name": "Mittel"}, {"id": 3, "name": "Schwer"}],
    },
    mutations: {
        clearMotivationTextAsDisplayed(state, uniqueChallengeId) {
            state.displayedMotivationTextList = state.displayedMotivationTextList.filter(text => text.uniqueChallengeId !== uniqueChallengeId);
        },
        setSoundEnabled(state, payload) {
            state.soundEnabled = payload;
        },
        setPremiumStatus(state, payload) {
            state.premiumStatus = payload;
        },
        setHomePageInfo(state, payload) {
            state.homePageInfo = payload;
        },
        setCategories(state, payload) {
            state.categories = payload;
        },
        setSportsTypes(state, payload) {
            state.sportsTypes = payload;
        },
        setEquipmentList(state, payload) {
            state.equipmentList = payload;
        },
        setCoaches(state, payload) {
            state.coaches = payload;
        },
        setArticles(state, payload) {
            state.articles = payload;
        },
        setWorkouts(state, payload) {
            state.workouts = payload;
        },
        setChallenges(state, payload) {
            state.challenges = payload;
        },
        setPlaylists(state, payload) {
            state.playlists = payload;
        },
        setExercises(state, payload) {
            state.exercises = payload;
        },
        setUser(state, payload) {
            state.user = payload;
        },
        setIsAuthenticated(state, payload) {
            state.isAuthenticated = payload;
        },
        setUserEmailVerified(state, payload) {
            state.userEmailVerified = payload;
        },
        setArticleMediaTypes(state, payload) {
            state.articleMediaTypes = payload;
        },
        setArticleCategories(state, payload) {
            state.articleCategories = payload;
        },
        updateOnboardingData(state, payload) {
            state.onboardingData = Object.assign(state.onboardingData, payload);
        },
        updateTemporaryPlaylist(state, payload) {
            state.temporaryPlaylist = Object.assign(state.temporaryPlaylist, payload);
        },
        initTemporaryPlaylist(state, payload) {
            state.temporaryPlaylist = payload;
        },
        initWorkoutSource(state, payload) {
            state.workoutSource = payload;
        },
        setDeviceToken(state, token) {
            state.deviceToken = token;
        },
        setReceiptData(state, receiptData) {
            state.receiptData = receiptData
        },
        setOperatingSystem(state, system) {
            state.operatingSystem = system;
        },
        setInstallationId(state, installationId) {
            state.installationId = installationId;
        },
        setUserEquipment(state, list) {
            state.userEquipment = list;
        },
        markArticleAsRead(state, article_id) {
            if (state.readArticleList.indexOf(article_id) != -1) { return; }
            state.readArticleList.push(article_id);
        },
        markMotivationTextAsDisplayed(state, mark) {
            if (state.displayedMotivationTextList.indexOf(mark) !== -1) { return; }
            state.displayedMotivationTextList.push(mark);
        },
        setFitnessPlan(state, payload) {
            state.fitnessPlan = payload;
        },
        setSelectedFitnessPlanActivity(state, activity) {
            state.selectedFitnessPlanActivity = activity;
        },
        addRecentFitnessPlanActivity(state, activity) {
            if (!state.recentFitnessPlanActivities.find(n => {
                if (n.meta === undefined && activity.meta === undefined) {
                    return n.id == activity.id;
                }
                else if (n.meta && activity.meta) {
                    return n.id == activity.id && n.meta.type == activity.meta.type;
                }
                return false;
            })) {
                state.recentFitnessPlanActivities.push(activity);
            }
            state.recentFitnessPlanActivities = state.recentFitnessPlanActivities.slice(-5);
        },
        // TODO: should be removed
        addRecentTrainingActivity(state, activity) {
            if (!state.recentTrainingActivities.find(n => {
                if (n.meta === undefined && activity.meta === undefined) {
                    return n.id == activity.id;
                }
                else if (n.meta && activity.meta) {
                    return n.id == activity.id && n.meta.type == activity.meta.type;
                }
                return false;
            })) {
                state.recentTrainingActivities.push(activity);
            }
            state.recentTrainingActivities = state.recentTrainingActivities.slice(-5);
        }
    },
    actions: {
        updateOnboardingData({ commit }, new_data) {
            commit('updateOnboardingData', new_data);
        },
        updateTemporaryPlaylist({ commit }, new_data) {
            commit('updateTemporaryPlaylist', new_data);
        },
        initTemporaryPlaylist({ commit }, new_data) {
            commit('initTemporaryPlaylist', new_data);
        },
        initWorkoutSource({ commit }, new_data) {
            commit('initWorkoutSource', new_data);
        },
        updateUserEquipment({ commit }, new_data) {
            commit('setUserEquipment', new_data);
        },
        async saveUserPlaylist( {state}, playlist) {
            const userId = state.user.user.uid;

            playlist.userId = userId;
            playlist.version = (playlist.version || 0) + 1;
            playlist.userDisplayName = state.user.user.displayName;

            if (playlist.uid) {
                await userPlaylistCollection.doc(playlist.uid).set(playlist);
            }
            else {
                playlist.creationDate = new Date().toISOString();
                await userPlaylistCollection.add(playlist);
            }
        },
        async deleteUserPlaylist( {state}, playlist) {
            const userId = state.user.user.uid;

            if (playlist.uid && (playlist.userId === userId)) {
                await userPlaylistCollection.doc(playlist.uid).delete();
            }
        },
        async fetchAllUserPlaylists( {state} ) {
            const userId = state.user.user.uid;
            var result = [];
            var shared_documents = []
            var query = userPlaylistCollection.where("userId", "==", userId);
            await query.get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // if _originalPlaylistUid present (shared workout), retrieve original document laster
                    // doc.data() is never undefined for query doc snapshots
                    var workout_document = doc.data();
                    workout_document = Object.assign({ uid: doc.id, meta: { type: "Custom/UserPlaylist" }, creationDate: doc.creationDate || "1978-01-01T00:00:00" }, workout_document);
                    if (workout_document._originalPlaylistUid) {
                        shared_documents.push(workout_document);
                        return;
                    }
                    result.push(workout_document);
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });

            for (var i = 0; i < shared_documents.length; i++) {
                //console.log("fetching shared playlist " + shared_documents[i]._originalPlaylistUid)
                var master_document = await userPlaylistCollection.doc(shared_documents[i]._originalPlaylistUid).get();
                master_document = master_document.data();

                if (master_document) {

                    shared_documents[i].name = master_document.name;
                    shared_documents[i].items = master_document.items;
                    shared_documents[i].shuffle = master_document.shuffle;
                    result.push(shared_documents[i]);
                }
                else {
                    result.push(shared_documents[i]);
                }
            }

            return result;
        },
        async fetchUserPlaylist( {state}, uid ) {
            const userId = state.user.user.uid;
            let playlistDoc = await userPlaylistCollection.doc(uid).get();
            if (playlistDoc.data().userId == userId) {
                var document_data = playlistDoc.data();
                if (document_data._originalPlaylistUid) {
                    var master_document = await userPlaylistCollection.doc(document_data._originalPlaylistUid).get();
                    master_document = master_document.data();
                    if (master_document) {
                        document_data.name = master_document.name;
                        document_data.items = master_document.items;
                        document_data.shuffle = master_document.shuffle;
                        if (document_data.version != master_document.version) {
                            console.log("detected change in workout, saving newer version...");
                            document_data.version = master_document.version;
                            await userPlaylistCollection.doc(playlistDoc.id).set(document_data);
                        }
                    } else {
                        console.log("master document not available");
                    }
                }
                return Object.assign({ uid: playlistDoc.id }, document_data);
            }
            return { error: "no access"};
        },
        /* eslint-disable no-unused-vars */
        async fetchSharedPlaylist( {state}, uid ) {
            // todo: should implement a flag to check whether this playlist has actually been shared
            let playlistDoc = await userPlaylistCollection.doc(uid).get();
            return playlistDoc.data();
        },
        async fetchFitnessPlan( {state} ) {
            const userId = state.user.user.uid;
            var result = [];

            let fpReference = storage.ref("fitnessplan").child(userId);

            let fpData;
            let axiosError = false;
            let urlError = false;
            let fpUrl = await fpReference.getDownloadURL().catch( () => urlError = true );
            if (!urlError) {
                await axios.get(fpUrl).then((response) => {
                    fpData = response.data;
                }).catch( () => { console.log("error retrieving stored file"); axiosError = true; } );
                if (axiosError) {
                    return [state.fitnessPlan];
                }
                if (fpData) {
                    this.commit('setFitnessPlan', fpData);
                    return [fpData];
                }
            }

            // old fallback code, can possibly be removed later
            var query = fitnessPlanCollection.where("userId", "==", userId);
            await query.limit(1).get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    result.push(Object.assign({ uid: doc.id }, doc.data()));
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });

            this.commit('setFitnessPlan', result.length > 0 ? result[0] : []);
            return result;
        },
        async saveFitnessPlan( {state}, fitnessPlan) {
            const userId = state.user.user.uid;

            fitnessPlan.userId = userId;

            // tidy up objects
            if (fitnessPlan.items) {
                fitnessPlan.items.forEach(it => {
                    delete it["activity"]["sports_types"];
                    delete it["activity"]["items"];
                    delete it["activity"]["exercises"];
                    delete it["activity"]["intro"];
                    delete it["activity"]["video_events"];
                    delete it["activity"]["equipment"];
                    delete it["activity"]["video_file"];
                    delete it["activity"]["video_file_hq"];
                    delete it["activity"]["body"];
                });
            }
            if (fitnessPlan.startedActivities) {
                fitnessPlan.startedActivities.forEach(it => {
                    delete it["activity"]["sports_types"];
                    delete it["activity"]["items"];
                    delete it["activity"]["exercises"];
                    delete it["activity"]["intro"];
                    delete it["activity"]["video_events"];
                    delete it["activity"]["body"];
                    if (it["uniqueChallengeId"]) {
                        it["_uniqueChallengeId"] = it["uniqueChallengeId"];
                        delete it["uniqueChallengeId"];
                    }
                });
            }

            let objJsonStr = JSON.stringify(fitnessPlan);
            let objJsonB64 = Buffer.from(objJsonStr).toString("base64");

            //console.log("save base64 fitnessplan json")
            await storage.ref("fitnessplan").child(userId).putString(objJsonB64, "base64", {contentType: 'application/json'});
            
            this.commit('setFitnessPlan', fitnessPlan);
            /*if (fitnessPlan.uid) {
                await fitnessPlanCollection.doc(fitnessPlan.uid).set(fitnessPlan);
            }
            else {
                await fitnessPlanCollection.add(fitnessPlan);
            }*/
        },
        async fetchUserInformation( {state} ) {
            const userId = state.user.user.uid;
            var result = [];
            var query = userInformationCollection.where("userId", "==", userId);
            await query.limit(1).get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    result.push(Object.assign({ uid: doc.id }, doc.data()));
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            return result;
        },
        async saveUserInformation( {state}, userInformation) {
            const userId = state.user.user.uid;

            userInformation.userId = userId;

            if (userInformation.uid) {
                await userInformationCollection.doc(userInformation.uid).set(userInformation);
            }
            else {
                await userInformationCollection.add(userInformation);
            }
        },
        async fetchHomePage({ state }) {
            try {
                let home_page_query_url = "pages/?type=home.HomePage&fields=featured_articles,magazine_featured_articles,shop_tiles,image_url,shop_image,shop_link";
                let response = await axios.get(`${state.apiUrl}`+home_page_query_url);
                this.commit('setHomePageInfo', response.data.items[0]);
            }
            catch (error) {
                this.commit('setHomePageInfo', []);
            }
        },
        async fetchCategories({ state }) {
            try {
                if (state.categories != null && state.categories._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.categories._lastUpdate) / 1000;
                    if (ageInSeconds < SECONDS_PER_DAY) { return; }
                }
                let categories_query_url = "categories/?order=sortOrder";
                let response = await axios.get(`${state.apiUrl}`+categories_query_url);
                response.data._lastUpdate = Date.now();
                this.commit('setCategories', response.data);
            }
            catch (error) {
                this.commit('setCategories', []);
            }
        },
        async fetchSportsTypes({ state }) {
            try {
                if (state.sportsTypes != null && state.sportsTypes._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.sportsTypes._lastUpdate) / 1000;
                    if (ageInSeconds < SECONDS_PER_DAY) { return; }
                }
                let sports_types_query_url = "sportsTypes/?limit=100";
                let response = await axios.get(`${state.apiUrl}`+sports_types_query_url);
                let sportsTypes = response.data.items;
                sportsTypes.sort((a,b) => {
                    let res = a.sort_order - b.sort_order;
                    if (res != 0) return res;
                    return a.name.localeCompare(b.name);
                });
                this.commit('setSportsTypes', {items: sportsTypes, _lastUpdate: Date.now()});
            }
            catch (error) {
                this.commit('setSportsTypes', []);
            }
        },
        async fetchEquipmentList({ state }) {
            if (state.equipmentList != null && state.equipmentList._lastUpdate) {
                let currentTime = Date.now()
                let ageInSeconds = (currentTime - state.equipmentList._lastUpdate) / 1000;
                if (ageInSeconds < SECONDS_PER_DAY) { return; }
            }
            try {
                let categories_query_url = "equipment/";
                let response = await axios.get(`${state.apiUrl}`+categories_query_url);
                response.data.items.forEach(el => el.checked = false);
                response.data._lastUpdate = Date.now();
                this.commit('setEquipmentList', response.data);
            }
            catch (error) {
                this.commit('setEquipmentList', []);
            }
        },
        async fetchArticleCategories({ state }) {
            if (state.articleCategories != null && state.articleCategories._lastUpdate) {
                let currentTime = Date.now()
                let ageInSeconds = (currentTime - state.articleCategories._lastUpdate) / 1000;
                if (ageInSeconds < SECONDS_PER_DAY) { return; }
            }
            try {
                let article_categories_query_url = "articleCategories/";
                let response = await axios.get(`${state.apiUrl}`+article_categories_query_url);
                response.data._lastUpdate = Date.now();
                this.commit('setArticleCategories', response.data);
            }
            catch (error) {
                this.commit('setArticleCategories', []);
            }
        },
        async fetchMediaTypes({ state }) {
            try {
                if (state.articleMediaTypes != null && state.articleMediaTypes._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.articleMediaTypes._lastUpdate) / 1000;
                    if (ageInSeconds < SECONDS_PER_DAY) { return; }
                }
                let media_types_query_url = "mediaTypes/";
                let response = await axios.get(`${state.apiUrl}`+media_types_query_url);
                response.data._lastUpdate = Date.now();
                this.commit('setArticleMediaTypes', response.data);
            }
            catch (error) {
                this.commit('setArticleMediaTypes', []);
            }
        },
        async fetchCoaches({ state }) {
            try {
                if (state.coaches != null && state.coaches._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.coaches._lastUpdate) / 1000;
                    if (ageInSeconds < 300) { return; }
                }
                let coaches_query_url = "coaches/";
                let response = await axios.get(`${state.apiUrl}`+coaches_query_url);
                response.data._lastUpdate = Date.now();
                this.commit('setCoaches', response.data);
            }
            catch (error) {
                this.commit('setCoaches', []);
            }
        },
        async fetchChallenges({ state, commit }) {
            try {
                if (state.challenges != null && state.challenges._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.challenges._lastUpdate) / 1000;
                    if (ageInSeconds < 300) { return; }
                }
                let query = {
                    type: "fitnessplan.FitnessPlanPage",
                    fields: "title,description,image_url,slides,equipment,difficulty,badge,motivationtexts",
                    limit: 1000,
                    order: "-id"};
                let challenges_url = "pages/"
                let response = await axios.get(`${state.apiUrl}`+challenges_url, {
                    params: query
                });
                response.data._lastUpdate = Date.now();
                commit('setChallenges', response.data);
            } catch (error) {
                commit('setChallenges', []);
            }
        },
        async fetchWorkouts({ state, commit }) {
            try {
                if (state.workouts != null && state.workouts._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.workouts._lastUpdate) / 1000;
                    if (ageInSeconds < 300) { return; }
                }
                let query = {
                    type: "workout.WorkoutPage",
                    fields: "title,intro,go_live_at,coach,duration,difficulty,preview_image_url,square_tile_image_url,requires_equipment,equipment_list,category_list,premium_content,badge,workout_type,sports_types,hide_from_overview",
                    limit: 1000,
                    order: "-go_live_at"};
                let workouts_url = "pages/"
                let response = await axios.get(`${state.apiUrl}`+workouts_url, {
                    params: query
                });
                response.data._lastUpdate = Date.now();
                commit('setWorkouts', response.data);
            } catch (error) {
                commit('setWorkouts', []);
            }
        },
        async fetchArticles({ state, commit }) {
            try {
                if (state.articles != null && state.articles._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.articles._lastUpdate) / 1000;
                    if (ageInSeconds < 300) { return; }
                }
                let query = {
                    type: "blog.blogPage",
                    fields: "title,intro,date,go_live_at,author,main_image_url,series_subtitle,home_tile_image_url,category_names,categories,media_type_names,media_types,coach,premium_content,readtime,sports_types,tags",
                    limit: 1000,
                    hide_article: false,
                    order: "-go_live_at"};
                let blog_url = "pages/"
                let response = await axios.get(`${state.apiUrl}`+blog_url, {
                    params: query
                });
                response.data._lastUpdate = Date.now();
                commit('setArticles', response.data);
            } catch (error) {
                commit('setArticles', []);
            }
        },
        async searchArticlesByTags({ state }, {search_tags}) {
            try {
                let query = {
                    type: "blog.blogPage",
                    tags: search_tags,
                    fields: "title,intro,date,go_live_at,author,main_image_url,series_subtitle,home_tile_image_url,category_names,categories,media_type_names,media_types,coach,premium_content,readtime,sports_types,tags",
                    limit: 1000,
                    order: "-go_live_at"};
                let blog_url = "pages/"
                let response = await axios.get(`${state.apiUrl}`+blog_url, {
                    params: query
                });
                return response.data;
            } catch (error) {
                return [];
            }
        },
        async searchArticlesByInput({ state }, {search_word}) {
            try {
                let query = {
                    type: "blog.blogPage",
                    search: search_word,
                    search_operator: "and",
                    fields: "title,intro,date,author,main_image_url,series_subtitle,home_tile_image_url,category_names,categories,media_type_names,media_types,coach,premium_content,readtime,sports_types,tags",
                    limit: 1000,
                    //order: "-date"
                };
                let blog_url = "pages/"
                let response = await axios.get(`${state.apiUrl}`+ blog_url, {
                    params: query
                });
                return response.data;
            } catch (error) {
                return [];
            }
        },
        async fetchExercises({ state, commit }) {
            try {
                if (state.exercises != null && state.exercises._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.exercises._lastUpdate) / 1000;
                    if (ageInSeconds < SECONDS_PER_DAY) { return; }
                }
                let query = {
                    type: "exercise.ExercisePage",
                    fields: "categories,requires_equipment,equipment,video_file,video_file_hq,hints,preview_image_url,square_preview_image_url,premium_content,mez,complementary_exercise,warmup",
                    limit: 1000
                };
                let exercise_url = "pages/"
                let response = await axios.get(`${state.apiUrl}`+exercise_url, {
                    params: query
                });
                response.data._lastUpdate = Date.now();
                commit('setExercises', response.data);
            } catch (error) {
                commit('setExercises', []);
            }
        },
        async fetchPlaylists({ state, commit }) {
            if (state.playlists != null && state.playlists._lastUpdate) {
                let currentTime = Date.now()
                let ageInSeconds = (currentTime - state.playlists._lastUpdate) / 1000;
                if (ageInSeconds < 300) { return; }
            }
            try {
                let query = {
                    type: "exercise.PlaylistPage",
                    fields: "title,intro,go_live_at,image_url,square_tile_image_url,coach,difficulty,duration,requires_equipment,equipment_list,category_list,premium_content,sports_types,hide_from_overview",
                    limit: 1000,
                    order: "-go_live_at"}
                let playlists_url = "pages/"
                let response = await axios.get(`${state.apiUrl}`+playlists_url, {
                    params: query
                });
                response.data._lastUpdate = Date.now();
                commit('setPlaylists', response.data);
            } catch (error) {
                commit('setPlaylists', []);
            }
        },
        async triggerUserVerification({ state, commit }, { email }) {
            email = email.toLowerCase().trim();
            let formData = new FormData();
            formData.append('email', email);

            await axios.post('https://apphelper.sportlerplus.com/verifyUser',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((res) => {
                commit('setUserEmailVerified', true);
            });
        },
        /* eslint-disable no-unused-vars */
        /* redeemCode usually called from landing page, user not logged in */
        async redeemCode({state}, { email, code }) {
            let d = new Date();
            code = code.toUpperCase().trim();
            email = email.toLowerCase().trim();
            let valid = false;

            let formData = new FormData();
            formData.append('code', code);

            await axios.post('https://apphelper.sportlerplus.com/redeemCode',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((res) => {
                let code_information = res.data;
                if (code_information.duration) {
                    valid = true;
                    let duration = code_information.duration;
                    if (duration.endsWith("m")) {
                        d.setMonth(d.getMonth() + parseInt(duration));
                    }
                    else if (duration.endsWith("d")) {
                        d.setDate(d.getDate() + parseInt(duration));
                    }
                }
            });

            if (!valid) return {};

            var result = [];
            var query = premiumPurchasesCollection.where("email", "==", email);
            await query.limit(1).get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    result.push(Object.assign({ uid: doc.id }, doc.data()));
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            let doc;
            if (result.length == 0) {
                doc = {email: email, code: code, validTo: d, conversion: "promo"};
                await premiumPurchasesCollection.add(doc);
            }
            else {
                doc = result[0];
                doc.validTo = d;
                doc.code = code;
                await premiumPurchasesCollection.doc(doc.uid).set(doc);
            }
            return doc;
        },
        async activatePurchase({state, commit}, { email, code, transactionId }) {
            let d = new Date();
            email = email.toLowerCase();
            if (code === "com.sportplatzmedia.sportlerplus.subscription12m") {
                d.setMonth(d.getMonth() + 13);
            }
            else if (code == "com.sportplatzmedia.sportlerplus.subscription3m") {
                d.setMonth(d.getMonth() + 3);
            }
            else {
                return {};
            }

            var result = [];
            var query = premiumPurchasesCollection.where("email", "==", email);
            await query.limit(1).get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    result.push(Object.assign({ uid: doc.id }, doc.data()));
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            let doc;
            if (result.length == 0) {
                doc = {email: email, code: code, validTo: d, transactionId: transactionId, conversion: "direct"};
                await premiumPurchasesCollection.add(doc);
            }
            else {
                doc = result[0];
                doc.validTo = d;
                doc.code = code;
                doc.transactionId = transactionId;
                await premiumPurchasesCollection.doc(doc.uid).set(doc);
            }
            commit('setPremiumStatus', doc);
            return doc;
        },
        async fetchPremiumStatus({commit}, {email}) {
            let currentDate = new Date();
            var result = [];
            email = email.toLowerCase();
            
            var query = premiumPurchasesCollection.where("email", "==", email);
            await query.get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    let data = doc.data()
                    let endDate = data.validTo.toDate();
                    if (endDate > currentDate) {
                        result.push(data)
                    }
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            
            commit('setPremiumStatus', result.length > 0 ? result[0] : []);
        },
        userRegister({ state, commit }, { email, password, name }) {
        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(user => {
                commit('setUser', user);
                commit('setIsAuthenticated', true);
                try {
                    let fbUser = firebase.auth().currentUser;
                    fbUser.updateProfile({
                        displayName: name,
                    });
                    fbUser.sendEmailVerification();

                    let onboardingData = state.onboardingData;
                    onboardingData.userId = fbUser.uid;
                    userInformationCollection.add(onboardingData);

                    router.push("/welcome").catch( () => {} ); 
                }
                catch(error) {
                    console.log("Error storing onboarding data: " + error);
                }
            })
            .catch((error) => {
                console.log("error registering user");
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log("code: " + errorCode);
                console.log("message: " +errorMessage);
                if (errorCode == "auth/email-already-in-use") {
                    alert("Diese Email-Adresse wird bereits für ein anderes SportlerPlus Konto verwendet.")
                }
                else {
                    alert(errorMessage);
                }
                commit('setUser', null);
                commit('setIsAuthenticated', false);
            });
        },
        userLogin({ commit }, { email, password, redirect }) {
            firebase.auth().signInWithEmailAndPassword(email, password)
            .then(user => {
                commit('setUser', user);
                commit('setIsAuthenticated', true);
                this.dispatch('fetchPremiumStatus', {email: user.user.email});
                router.push(redirect || "/");
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                console.log("login error:");
                console.log(errorCode);
                console.log(errorMessage);
                alert("Fehler beim Anmelden, Benutzername oder -kennwort falsch.")
                commit('setUser', null);
                commit('setIsAuthenticated', false);
            });
        },
        userSignOut({ commit }) {
            window._spStatus = {};
            firebase.auth().signOut()
            .then(() => {
                commit('setUser', null);
                commit('setIsAuthenticated', false);
                router.push('/');
            })
            .catch(() => {
                commit('setUser', null);
                commit('setIsAuthenticated', false);
                router.push('/');
            });
        },
        socialLogin({ commit }, { providerString, redirect }) {
            let provider = null;
            if (providerString === "google")
                provider = new firebase.auth.GoogleAuthProvider();
            else if (providerString === "facebook")
                provider = new firebase.auth.FacebookAuthProvider();
            else if (providerString === "apple") {
                provider = new firebase.auth.OAuthProvider('apple.com');
                provider.addScope('email');
                provider.addScope('name');
            }
            firebase.auth().signInWithPopup(provider).then((user) => {
                commit('setUser', user);
                commit('setIsAuthenticated', true);
                this.dispatch('fetchPremiumStatus', {email: user.user.email});
                router.push(redirect || "/");
            }).catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                console.log("login error:");
                console.log(errorCode);
                console.log(errorMessage);
                alert(errorMessage);
                console.log(email);
                console.log(credential);
                commit('setUser', null);
                commit('setIsAuthenticated', false);
                //router.push('/');
            });
        },
        async likeArticle( {state}, article) {
            const userId = state.user.user.uid;
            const docId = `${userId}_${article.id}`;

            // check if user has liked article
            const doc = await favouritesCollection.doc(docId).get();
            if (doc.exists) { return }

            // create post
            await favouritesCollection.doc(docId).set({
                articleId: article.id,
                userId: userId,
                type: 'blog.BlogPage',
                title: article.title,
                subtitle: "Magazin - " + article.category_names,
            });
        },
        async likePlaylist( {state}, playlist) {
            const userId = state.user.user.uid;
            const docId = `${userId}_${playlist.id}`;

            // check if user has liked playlist
            const doc = await favouritesCollection.doc(docId).get();
            if (doc.exists) { return }

            // create post
            await favouritesCollection.doc(docId).set({
                articleId: playlist.id,
                userId: userId,
                type: 'exercise.Playlist',
                title: playlist.title,
                subtitle: "Playlist - " + playlist.exercises.length + " Übungen",
            });
        },
        async likeWorkout( {state}, workout) {
            const userId = state.user.user.uid;
            const docId = `${userId}_${workout.id}`;

            // check if user has liked article
            const doc = await favouritesCollection.doc(docId).get();
            if (doc.exists) { return }

            let coach, subtitle;
            if (workout.coach && state.coaches && state.coaches.items) {
                coach = state.coaches.items.find(c => c.id == workout.coach.id);
            }
            if (coach != null) {
                subtitle = "Workout - " + coach.name + " - " + workout.duration + " Min."
            }
            else {
                subtitle = "Workout - " + workout.duration + " Min.";
            }
            // create post
            await favouritesCollection.doc(docId).set({
                articleId: workout.id,
                userId: userId,
                type: 'workout.WorkoutPage',
                title: workout.title,
                subtitle: subtitle,
            });
        },
        async checkArticleFavourite( {state}, articleId) {
            const userId = state.user.user.uid;
            const docId = `${userId}_${articleId}`;

            // check if user has liked article
            const doc = await favouritesCollection.doc(docId).get();
            if (doc.exists) { return true; }
            return false;
        },
        async fetchAllFavourites( {state} ) {
            const userId = state.user.user.uid;
            var result = [];
            var query = favouritesCollection.where("userId", "==", userId);
            query.get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                result.push(Object.assign({ uid: doc.id }, doc.data()));
                });
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            return result;
        },
        removeArticleLike ( {state}, article) {
            const userId = state.user.user.uid;
            const docId = `${userId}_${article.id}`;
            favouritesCollection.doc(docId).delete();
        },
        setDeviceToken( { state, commit }, { token, system, installationId } ) {
            if (!state.isAuthenticated || (installationId === state.installationId)) { return; }
            const userId = state.user.user.uid;
            commit('setDeviceToken', token);
            commit('setOperatingSystem', system);
            commit('setInstallationId', installationId);
            devicesCollection.doc(userId).set({userId: userId, fcmToken: token, system: system, installationId: installationId});
        },
        transferReceiptData( { state, commit }, { receiptData, system } ) {
            if (!state.isAuthenticated || (receiptData === state.receiptData)) { return; }
            const userId = state.user.user.uid;
            commit('setReceiptData', receiptData);
            receiptCollection.doc(userId).set({receiptData: receiptData, system: system});
        },
        unrollCalendar() {
            let calendar = [];
            let tempId = 0;
            // should cover only future activities
            this.state.fitnessPlan.items.forEach(it => {
                if (it.repeating) {
                    let initialDate = moment(it.activityDate + " " + it.activityTime, 'YYYY-MM-DD HH:mm').toDate();
                    let endDate = null;
                    if (it.activityEndDate != null) {
                        endDate = moment(it.activityEndDate + " " + it.activityTime, 'YYYY-MM-DD HH:mm').toDate();
                    }
                    // find correct start weekday (it.weekday)
                    if (it.weekday != null && it.weekday != initialDate.getDay()) {
                        let curWeekday = initialDate.getDay();
                        if (it.weekday > curWeekday) {
                            initialDate = addDays(initialDate, it.weekday - curWeekday);
                        }
                        else {
                            initialDate = addDays(initialDate, 7 + it.weekday - curWeekday);
                        }
                    }
                    // max. 52 weeks ahead
                    for (let r = 0; r < (52 / 2**it.repeatInterval); r++) {
                        let new_obj = Object.assign({}, it);
                        new_obj.startDate = initialDate;
                        let date_components = toIsoString(initialDate).split("T");
                        new_obj.activityDate = date_components[0];
                        new_obj.activityTime = date_components[1].substr(0,5);
                        new_obj.id = tempId++;
                        if ((endDate == null || endDate >= initialDate) && (!new_obj.exceptionDates || !new_obj.exceptionDates.includes(new_obj.activityDate))) {
                            calendar.push(new_obj);
                        }
                        if (it.repeatInterval == 0) {
                            initialDate = addDays(initialDate, 7);
                        }
                        else if (it.repeatInterval == 1) {
                            initialDate= addDays(initialDate, 14);
                        }
                        else if (it.repeatInterval == 2) {
                            initialDate = addDays(initialDate, 28);
                        }
                    }
                }
                else {
                    it.startDate = moment(it.activityDate + " " + it.activityTime, 'YYYY-MM-DD HH:mm').toDate();
                    if (it.startDate instanceof Date && isFinite(it.startDate)) {
                        it.id = tempId++;
                        calendar.push(it);
                    }
                }
            });
            // find extra information from startedActivities array
            if (this.state.fitnessPlan.startedActivities) {
                this.state.fitnessPlan.startedActivities.forEach(it => {
                    let c = calendar.find(el => el.guid == it.guid && el.activityDate == it.activityDate && el.activityTime == it.activityTime);
                    if (c != null) {
                        c.activityState = it.activityState;
                        // copy real start date in case user started activity earlier or later
                        if (it._realStartDate) {
                            let d = moment(it._realStartDate).toDate();
                            if (d instanceof Date && !isNaN(d)) {
                                c.startDate = d;
                            }
                        }
                    }
                    else {
                        if (it._realStartDate) {
                            it.startDate = moment(it._realStartDate).toDate();
                        }
                        else {
                            it.startDate = moment(it.activityDate + " " + it.activityTime, 'YYYY-MM-DD HH:mm').toDate();
                        }
                        if (it.startDate instanceof Date && !isNaN(it.startDate)) {
                            it.historicalEvent = true;
                            it.id = tempId++;
                            calendar.push(it);
                        }
                    }
                });
            }
            return calendar.sort((a, b) => (a.startDate - b.startDate));
        },
        /* scope may be 'week', 'month', 'year', 'entire' */
        statisticsData( {state}, { startDate, scope }) {
            if (!state.fitnessPlan || !state.fitnessPlan.startedActivities) {
                return {
                    "data": [],
                    "minDuration": 0,
                    "maxDuration": 0,
                    "avgDuration": 0,
                    "totalDuration": 0,
                    "minEnergy": 0,
                    "maxEnergy": 0,
                    "avgEnergy": 0,
                    "totalEnergy": 0,
                    "minCount": 0,
                    "maxCount": 0,
                    "avgCount": 0,
                    "totalCount": 0,
                    "minDistance": 0,
                    "maxDistance": 0,
                    "avgDistance": 0,
                    "totalDistance": 0,
                    "topActivities": [],
                    "minDate": moment(),
                    "maxDate": moment(),
                };
            }

            let activityType = function (activity) {
                if (!activity) { return null; }
                if (activity.meta === undefined) { return "Category"; }
                else { return activity.meta.type; }
            };

            let statistics = [];
            let topActivities = [];
            let d1, d2;
            let count = 0;
            let incrUnit = ""
            if (scope === 'week') {
                d1 = moment(startDate).startOf('week');
                d2 = moment(d1).add(1, 'week');
                incrUnit = "day";
            }
            else if (scope === 'month') {
                d1 = moment(startDate).startOf('month');
                d2 = moment(d1).add(1, 'month');
                incrUnit = "day";
            }
            else if (scope === 'year') {
                d1 = moment(startDate).startOf('year');
                d2 = moment(d1).add(1, 'year');
                incrUnit = "month";
            }
            else if (scope === 'entire') {
                let dateArray = state.fitnessPlan.startedActivities.map(it => moment(it.activityDate + " " + it.activityTime, 'YYYY-MM-DD HH:mm'));
                if (dateArray.length > 0) {
                    d1 = (dateArray.reduce((a, b) => a > b ? b : a)).startOf('year');
                }
                else {
                    d1 = moment().startOf("year").subtract(5, "year");
                }
                d2 = moment().startOf("year").add(1, "year");
                incrUnit = "year";
            }
            count = moment(d2).diff(d1, incrUnit);

            console.log("statistics from " + d1.toDate());
            console.log("statistics to   " + d2.toDate());
            console.log("items           " + count);

            for (let i = 0; i < count ; i++) {
                let s = {
                    "startDate": moment(d1),
                    "endDate": moment(d1).add(1, incrUnit),
                    "energyExpended": 0,
                    "duration": 0,
                    "numberOfWorkouts": 0,
                    "distance": 0,
                }
                d1.add(1, incrUnit);
                statistics.push(s);
            }

            let minDate = moment();
            let maxDate = moment();

            if (state.fitnessPlan.startedActivities) {
                // reverse array to have more recent into (changed images etc.) in top list
                [...state.fitnessPlan.startedActivities].reverse().forEach(it => {
                    if (it.activityState !== "finished") { return; }
                    let itemDate;
                    if (it._realStartDate) {
                        itemDate  = moment(it._realStartDate);
                    }
                    else {
                        itemDate = moment(it.activityDate + " " + it.activityTime, 'YYYY-MM-DD HH:mm');
                    }
                    if (itemDate > maxDate) { maxDate = itemDate; }
                    if (itemDate < minDate) { minDate = itemDate; }
                    for (let i = 0; i < count; i++) {
                        let s = statistics[i];
                        if (s.startDate <= itemDate && s.endDate >= itemDate) {
                            s.duration = s.duration + parseInt(it.realDuration);
                            s.energyExpended = s.energyExpended + parseInt(it.energyExpended);
                            s.numberOfWorkouts = s.numberOfWorkouts + 1;
                            s.distance = s.distance + parseFloat((it.distance || 0));
                            let existingActivity = topActivities.find(el => el.activity.id == it.activity.id && activityType(el) == activityType(it));
                            if (existingActivity) {
                                existingActivity._useCount = existingActivity._useCount + 1;
                            }
                            else {
                                it._useCount = 1;
                                topActivities.push(it);
                            }
                            break;
                        }
                    }
                });
            }

            let minDuration = 0, maxDuration = 0, totalDuration = 0, avgDuration = 0;
            let durationArray = statistics.filter(item => item.duration > 0).map(item => item.duration);
            if (durationArray.length > 0) {
                minDuration = Math.min(...durationArray);
                maxDuration = Math.max(...durationArray);
                totalDuration = durationArray.reduce((a, b) => (a + b), 0)
                avgDuration = totalDuration / durationArray.length;
            }
            
            let minEnergy = 0, maxEnergy = 0, totalEnergy = 0, avgEnergy = 0;
            let energyArray = statistics.filter(item => item.energyExpended > 0).map(item => item.energyExpended);
            if (energyArray.length > 0) {
                minEnergy = Math.min(...energyArray);
                maxEnergy  = Math.max(...energyArray);
                totalEnergy = energyArray.reduce((a, b) => (a + b), 0);
                avgEnergy = totalEnergy / energyArray.length;
            }

            let minCount = 0, maxCount = 0, totalCount = 0, avgCount = 0;
            let countArray = statistics.filter(item => item.numberOfWorkouts > 0).map(item => item.numberOfWorkouts);
            if (countArray.length > 0) {
                minCount = Math.min(...countArray);
                maxCount  = Math.max(...countArray);
                totalCount = countArray.reduce((a, b) => (a + b), 0);
                avgCount = totalCount / countArray.length;
            }

            let minDistance = 0, maxDistance = 0, totalDistance = 0, avgDistance = 0;
            let distanceArray = statistics.filter(item => item.distance > 0).map(item => item.distance);
            if (distanceArray.length > 0) {
                minDistance = Math.min(...distanceArray);
                maxDistance = Math.max(...distanceArray);
                totalDistance = distanceArray.reduce((a, b) => (a + b), 0);
                avgDistance = totalDistance / distanceArray.length;
            }

            topActivities = topActivities.sort((a, b) => b._useCount - a._useCount);
            
            return {
                "data": statistics,
                "minDuration": minDuration,
                "maxDuration": maxDuration,
                "avgDuration": avgDuration,
                "totalDuration": totalDuration,
                "minEnergy": minEnergy,
                "maxEnergy": maxEnergy,
                "avgEnergy": avgEnergy,
                "totalEnergy": totalEnergy,
                "minCount": minCount,
                "maxCount": maxCount,
                "avgCount": avgCount,
                "totalCount": totalCount,
                "minDistance": minDistance,
                "maxDistance": maxDistance,
                "avgDistance": avgDistance,
                "totalDistance": totalDistance,
                "topActivities": topActivities,
                "minDate": minDate,
                "maxDate": maxDate,
            };
        }
    },
    modules: {},
    getters: {
        getSoundEnabled(state) {
            return state.soundEnabled;
        },
        isAuthenticated(state) {
            return state.user !== null && state.user !== undefined;
        },
        getArticles(state) {
            if (state.articles !== null) {
                return state.articles.items;
            }
            return null;
        },
        getFitnessplan(state) {
            if (state.fitnessPlan !== null) {
                return state.fitnessPlan;
            }
            return null;
        },
        getFeaturedArticles(state) {
            if (state.homePageInfo !== null) {
                return state.homePageInfo.featured_articles;
            }
        },
        getFeaturedMagazineArticles(state) {
            if (state.homePageInfo !== null) {
                return state.homePageInfo.magazine_featured_articles;
            }
        },
        getHomePageInfo(state) {
            return state.homePageInfo;
        },
        getWorkouts(state) {
            if (state.workouts !== null) {
                return state.workouts.items;
            }
            return null;
        },
        getChallenges(state) {
            if (state.challenges !== null) {
                return state.challenges.items;
            }
            return null;
        },
        getPlaylists(state) {
            if (state.playlists !== null) {
                return state.playlists.items;
            }
            return null;
        },
        getExercises(state) {
            if (state.exercises !== null) {
                return state.exercises.items;
            }
            return null;
        },
        getCategories(state) {
            if (state.categories) {
                return state.categories.items;
            }
            return null;
        },
        getSportsTypes(state) {
            if (state.sportsTypes) {
                return state.sportsTypes.items;
            }
            return null;
        },
        getEquipmentList(state) {
            return state.equipmentList.items;
        },
        getCoaches(state) {
            if (state.coaches) {
                return state.coaches.items;
            }
            return null;
        },
        getApiUrl(state) {
            return state.apiUrl;
        },
        getTemporaryPlaylist(state) {
            return state.temporaryPlaylist;
        },
        getWorkoutSource(state) {
            return state.workoutSource;
        },
        getArticleMediaTypes(state) {
            if (state.articleMediaTypes) {
                return state.articleMediaTypes.items;
            }
            return null;
        },
        getArticleCategories(state) {
            if (state.articleCategories && state.articleCategories.items) {
                return state.articleCategories.items.filter(cat => cat.active);
            }
            return null;
        },
        getOnboardingData(state) {
            return state.onboardingData;
        },
        getPremiumStatus(state) {
            var email = "unknown@email.address";
            if (state.user && state.user.user) {
                email = state.user.user.email;
            }
            // return standard valid premium status until 1.1.2030
            return {
                "code": "DATE-WITH-IKEA",
                "email": email,
                "validTo": {
                    nanoseconds: 0,
                    seconds: 1893455999
                },
            }
            /*
            if (state.premiumStatus && Object.keys(state.premiumStatus).length !== 0) {
                let currentDateSeconds = new Date().getTime() / 1000;
                let endDate = state.premiumStatus.validTo.seconds;
                if (endDate > currentDateSeconds) {
                    return state.premiumStatus;
                }
            }
            return {}
            */
        },
        getUserEquipment(state) {
            return state.userEquipment;
        },
        getReadArticleList(state) {
            return state.readArticleList;
        },
        getDisplayedMotivationTextList(state) {
            return state.displayedMotivationTextList;
        },
        getSelectedFitnessPlanActivity(state) {
            return state.selectedFitnessPlanActivity;
        },
        getRecentFitnessPlanActivities(state) {
            return state.recentFitnessPlanActivities;
        },
        getRecentTrainingActivities(state) {
            return state.recentTrainingActivities;
        },
        getWorkoutIntensities(state) {
            return state.workoutIntensities;
        },
        getVersion(state) {
            return version;
        },
        getInstallationId(state) {
            return state.installationId;
        },
        getDeviceToken(state) {
            return state.deviceToken;
        },
        getUserEmailVerified(state) {
            return state.userEmailVerified;
        }
    },
    plugins: [vuexLocal.plugin]
})

<template>
    <div>
        <GeneralAlertModal @closeModal="closeModal" title="Geteiltes Workout" :message=cannotChangeMessage></GeneralAlertModal>

        <VModal :name="name" class="playlistedit-modal" width="100%" height="auto" :shiftY="1" :scrollable="true">
            <div class="vm-content">
                <div v-if="!restricted" class="list__item">
                    <div class="list__item__icon">
                        <img src="@/assets/ic_24_playlist_start.svg" alt="">
                    </div>
                    <div class="list__item__content">
                        <div @click="startUserPlaylist" class="list__item__label">
                            Workout starten
                        </div>
                    </div>
                </div>
                <div v-if="!restricted" class="list__item">
                    <div class="list__item__icon">
                        <img src="@/assets/ic_24_playlist_edit.svg" alt="">
                    </div>
                    <div class="list__item__content">
                        <div @click="editExistingPlaylist" class="list__item__label">
                            Bearbeiten
                        </div>
                    </div>
                </div>
                <div v-if="!restricted" class="list__item">
                    <div class="list__item__icon">
                        <img src="@/assets/ic_24_playlist_share.svg" alt="">
                    </div>
                    <div class="list__item__content">
                        <div @click="sharePlaylist" class="list__item__label">
                            Teilen
                        </div>
                    </div>
                </div>
                <div class="list__item">
                    <div class="list__item__icon">
                        <img src="@/assets/ic_24_playlist_delete.svg" alt="">
                    </div>
                    <div class="list__item__content">
                        <div @click="deleteUserPlaylist" class="list__item__label">
                            Löschen
                        </div>
                    </div>
                </div>
            </div>
        </VModal>
    </div>

</template>
<script>
import { sharePage } from '@/sportlerplus-helper';
import GeneralAlertModal from '@/components/GeneralAlertModal.vue';

export default {
    name: 'EditPlaylistItemModal',
    components: {GeneralAlertModal},
    props: {
        name: {
            type: String,
            default() {
                return "editPlaylistItemModal";
            }
        },
        userPlaylist: {
            type: Object,
            default() { return {}; } 
        },
        restricted: {
            type: Boolean,
            default() { return false; }
        }
    },
    computed: {
        cannotChangeMessage() {
            const mainMessageText = `Daher kannst du dieses Workout leider nicht bearbeiten. Du möchtest ein eigenes Workout erstellen, das du später auch jederzeit bearbeiten kannst? Erstelle <a href='/playlists/create/start'>HIER</a> ein eigenes Workout…`
            if (this.userPlaylist && this.userPlaylist._originalUserName) {
                return this.userPlaylist._originalUserName + " hat dieses Workout erstellt und es mit dir geteilt. " +
                mainMessageText;
            }
            return  "Dieses Workout wurde nicht von dir selbst erstellt, sondern mit dir geteilt. " +
                    mainMessageText;
        }
    },
    methods: {
        editExistingPlaylist() {
            if (this.userPlaylist._originalPlaylistUid) {
                //console.log("cannot edit shared playlist");
                this.$modal.hide("editPlaylistItemModal");
                this.$modal.show("generalAlert");
                return;
            }
            this.$store.dispatch('initTemporaryPlaylist', this.userPlaylist);
            this.$router.push("/playlists/create/start");
        },
        closeModal() {
        },
        startUserPlaylist() {
            this.$router.push("/playlist/start?user_playlist_id=" + this.userPlaylist.uid);
        },
        deleteUserPlaylist() {
            this.$store.dispatch('deleteUserPlaylist', this.userPlaylist).then(() => this.$emit("playlistDeleted", this.userPlaylist));
        },
        sharePlaylist() {
            let shareUrl = window.location.origin + "/sharing/?importPlaylist=" + this.userPlaylist.uid;
            let shareText = "Hey, ich habe gerade ein individuelles Workout in der SportlerPlus-App zusammengestellt: „{0}“.\n\nProbiere es direkt aus! 💪🚀".format(this.userPlaylist.name);
            sharePage(shareUrl, null, shareText, null);
        }
    }
}
</script>
<style lang="scss">
    .playlistedit-modal {
        .vm--modal {
            width: calc(100% - 32px) !important;
            margin: 0px auto 16px;
            transform: translateY(-16px);
            border-radius: 8px !important;
        }
        .vm-content {
            padding: 8px 16px;
        }
    }
</style>
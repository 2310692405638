<template>
    <div @click="handleWorkoutClick(workout)" v-bind:key="workout.id" class="workout__item noselect">

        <!-- Premium Badge -->
        <div v-if="showPremiumBadge" class="workout__item__premium">
            <img src="@/assets/ic_20_premium.svg" alt="">
        </div>
      
        <img max-width="100%" 
        :src="'https://app.sportlerplus.com/scaled/800/' + workout.preview_image_url" class="workout__item__image">
      
        <div class="workout__item__meta">

            <!-- Equipment -->
            <div v-if="workout.requires_equipment" class="workout__item__meta__icon">
                <img src="@/assets/ic_32_workout_item_equipment.svg" alt="" />
            </div>

            <!-- Intensität -->
            <div class="workout__item__meta__icon">
                <img v-if="workout.difficulty == 3" src="@/assets/ic_32_workout_item_intensity-high.svg" alt="" />
                <img v-else-if="workout.difficulty == 1" src="@/assets/ic_32_workout_item_intensity-low.svg" alt="" />
                <img v-else src="@/assets/ic_32_workout_item_intensity-medium.svg" alt="" />
            </div>

            <!-- Dauer -->
            <div class="workout__item__meta__icon">
                <span>{{ workout.duration }}'</span>
                <img src="@/assets/ic_32_workout_item_time.svg" alt="" />
            </div>

        </div>

        <h3 class="workout__item__title">
            <div class="workout__item__badge" v-if="workout.badge">{{ workout.badge }}</div>
            {{ workout.title }}
        </h3>

    </div>
</template>
<script>
export default {
    name: 'WorkoutItem',
    props: {
        workout: {
            type: Object,
            default() { return {}; } 
        },
        selectOnly: {
            type: Boolean,
        }
    },
    computed: {
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
        showPremiumBadge() {
            return (this.workout.premium_content && Object.keys(this.premiumStatus).length === 0);
        }
    },
    methods: {
        handleWorkoutClick(workout) {
            if (this.selectOnly) {
                this.$emit("selectWorkout", workout);
                return;
            }
        
            if (workout.premium_content && Object.keys(this.premiumStatus).length === 0) {
                this.$emit('showPremiumHint');
            }
            else {
                this.$router.push("/workout/" + workout.id);
            }
        }
    }
}
</script>

<template>
    <VModal name="purchaseSuccess" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
      <div class="vm-content">
        <img src="@/assets/notification/ic_notification_success.svg" alt="">
        <h3>
          Danke für dein Vertrauen!
        </h3>
        <p>
          Dein Premium-Abo wurde erfolgreich abgeschlossen. Deine Rabatt-Codes haben wir dir in deinem Profil unter „Premium-Mitgliedschaft“ abgelegt.
        </p>
        <div class="notification"><b>Hinweis:</b> Starte die App neu, falls die Premium-Inhalte noch nicht automatisch freigeschaltet sein sollten.</div>
        <v-btn type="button" class="button button--primary button--small" @click="dismissDialog">Alles klar</v-btn>
      </div>
    </VModal>
</template>

<script>
    export default {
        name: 'PurchaseSuccessModal',
        methods: {
            dismissDialog() {
                this.$modal.hide('purchaseSuccess');
            },
        }
    }
</script>
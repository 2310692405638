<template>
    <VModal name="athleteModal" class="large" width="100%" height="auto" :scrollable="true">
        <div class="vm-header">
            <div class="vm-header-left">
            </div>
            <div class="vm-header-center">
            </div>
            <div class="vm-header-right">
                <img src="@/assets/ic_nav_close.svg" alt="" @click="dismissDialog">
            </div>
        </div>
        <div class="vm-content" v-if="athlete">
            <img :src="'https://app.sportlerplus.com' + athlete.scaled_image_url" alt="">
            <small>{{ sportsTypeName(athlete) }}</small>
            <h2>{{ athlete.first_name }} {{ athlete.last_name }}</h2>
            <blockquote v-html="athlete.intro">
            </blockquote>
            <p v-html="athlete.description">
            </p>
            <a :href="athlete.social_link" target="_blank" class="athletes__item__social athletes__item__social--instagram">
                <img v-if="socialLinkType(athlete.social_link) == 'instagram'" src="@/assets/landingpage/ic_team_instagram.svg">
                <img v-else-if="socialLinkType(athlete.social_link) == 'tiktok'" src="@/assets/landingpage/ic_team_tiktok.svg">
                <img v-else-if="socialLinkType(athlete.social_link) == 'youtube'" src="@/assets/landingpage/ic_team_youtube.svg">
                <img v-else height="24" src="@/assets/landingpage/ic_team_website.svg">
                {{ socialLinkName }}
            </a>
        </div>
    </VModal>
</template>

<script>

export default {
    name: 'AthleteModal',
    methods: {
        dismissDialog() {
            this.$modal.hide('athleteModal');
        },
        socialLinkType(link) {
            if (link.includes('instagram')) {
                return 'instagram';
            } else if (link.includes('tiktok')) {
                return 'tiktok';
            } else if (link.includes('youtube')) {
                return 'youtube';
            }
        },
        sportsTypeName(athlete) {
			if (athlete.sports_type_alt_name) {
				return athlete.sports_type_alt_name;
			}
			switch(athlete.sports_type_icon) {
				case 1:
					return "Functional Fitness";
				case 2:
					return "Yoga";
				case 3:
					return "Handball";
				case 4:
					return "Fußball";
				case 5:
					return "Triathlon";
				case 6:
					return "Turnen";
				default:
					return "";
			}
		}
    },
    props: {
        athlete: {
            type: Object,
            default() { return {}; } 
        }
    },
    data() {
        return {

        }
    },
    computed: {
        socialLinkName() {
            // strip instagram host name from athlete.instagram_link
            if (this.athlete && this.athlete.social_link) {
                let t = this.socialLinkType(this.athlete.social_link);
                if (t == "instagram") {
                    return this.athlete.social_link.replace("https://www.instagram.com/", "");
                } else if (t == "tiktok") { 
                    return this.athlete.social_link.replace("https://www.tiktok.com/@", "");
                } else if (t == "youtube") {
                    return this.athlete.social_link.replace("https://www.youtube.com/channel/", "");
                }
                else {
                    return this.athlete.social_link.replace("https://", "");
                }
            }
            return "";
        }
    }
}
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 767px) {
        .vm--container {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .vm-header-right img {
        position: relative;
        z-index: 2;
        background: rgba($colorWhite, .48);
        border-radius: 50%;
        margin-right: 0;
        margin-top: 0;
        opacity: 1;
        transition: all .2s ease-in-out;

        @media screen and (max-width: 767px) {
            padding: 8px;
            margin: 0 0 -8px -8px;
            width: 36px;
            height: 36px;
        }

        &:hover {
            background: rgba($colorWhite, .64);
        }
    }

    .vm-content {
        display: flex;
        align-items: flex-start;
        padding-top: 0;
        text-align: left;

        @media screen and (max-width: 767px) {
            margin-bottom: 24px;
        }

        > img {
            position: relative;
            z-index: 0;
            object-fit: cover;
            max-height: 330px;
            width: calc(100% + 80px);
            margin: -58px -40px 19px;

            @media screen and (min-width: 768px) {
                margin: -58px -40px 35px;
            }
        }

        small {
            margin-bottom: 3px;
            color: $colorBlack;

            @media screen and (min-width: 768px) {
                margin-bottom: 6px;
            }
        }

        h2 {
            font-size: 30px;
            letter-spacing: -0.5px;
            font-weight: 900;
            color: $colorBlack;
            margin-bottom: 22px;

            @media screen and (min-width: 768px) {
                font-size: 38px;
                letter-spacing: -0.75px;
                margin-bottom: 30px;
            }
        }

        blockquote {
            font-size: 17px;
            line-height: 26px;
            font-weight: 700;
            color: $colorBlack;
            position: relative;
            padding-left: 16px;
            margin-bottom: 19px;

            @media screen and (min-width: 768px) {
                margin-bottom: 27px;
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 5px;
                width: 4px;
                height: calc(100% - 10px);
                background: $colorPrimary;
            }
        }

        p {
            line-height: 24px;
            color: $colorBlack;
            opacity: 1;
            margin-bottom: 16px;

            @media screen and (min-width: 768px) {
                font-size: 17px;
                line-height: 28px;
            }
        }
    }

</style>
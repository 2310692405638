<template>
    <div v-if="user" class="profile__detail">
        <v-text-field v-model="newUsername" placeholder="Vorname" type="text"></v-text-field>
        <v-text-field v-if="providerId == 'password'" v-model="newEmail" placeholder="E-Mail-Adresse" type="text"></v-text-field>
        <small v-else>Die Email-Adresse kann nicht geändert werden, da du über {{ providerId }} angemeldet bist.</small>
        <small v-if="providerId == 'password'"><router-link to="/password-change">Passwort ändern</router-link></small>
        <div class="list">
			<div v-if="providerId != 'password'" class="list__item">
				<div class="list__item__content">
					<div class="list__item__label list__item__value--light">
						Email
					</div>
				</div>
				<div class="list__item__value list__item__value--light">
					{{ oldEmail }}
				</div>
				<img @click="copyEmail(oldEmail)" src="@/assets/ic_16_copy.svg" style="margin-left: 8px;">
			</div>
			<div class="list__item">
				<div class="list__item__content">
					<div class="list__item__label list__item__value--light">
						ID
					</div>
				</div>
				<div class="list__item__value list__item__value--light">
					{{ user.uid }}
				</div>
				<img @click="copyId(user.uid)" src="@/assets/ic_16_copy.svg" style="margin-left: 8px;">
			</div>
            <div class="list__item">
				<div class="list__item__content">
					<div class="list__item__label list__item__value--light">
						Version
					</div>
				</div>
				<div class="list__item__value list__item__value--light">
					{{ appVersion }}
				</div>
			</div>
            <div v-if="showDebugInfo" class="list__item">
				<div class="list__item__content">
					<div class="list__item__label list__item__value--light">
						Installation Id
					</div>
				</div>
				<div class="list__item__value list__item__value--light">
					{{ installationId }}
				</div>
                <img @click="copyId(installationId)" src="@/assets/ic_16_copy.svg" style="margin-left: 8px;">
			</div>
            <div v-if="showDebugInfo" class="list__item">
				<div class="list__item__content">
					<div class="list__item__label list__item__value--light">
						Device token
					</div>
				</div>
				<div class="list__item__value list__item__value--light">
					{{ displayDeviceToken }}
				</div>
                <img @click="copyId(deviceToken)" src="@/assets/ic_16_copy.svg" style="margin-left: 8px;">
			</div>
        </div>
        <v-btn v-if="isIOS && showDebugInfo" class="button button--secondary" @click="clearCache" style="margin-bottom: 0">Cache leeren</v-btn>
        <v-btn class="button button--primary" @click="saveSettings">Übernehmen</v-btn>
    </div>
</template>
<script>
import router from '@/router';
import firebase from 'firebase';
import { copyTextToClipboard } from  '@/sportlerplus-helper';

export default {
    name: 'SettingsLogin',
    data() {
        return {
            oldUsername: null,
            oldEmail: null,
            newUsername: null,
            newEmail: null,
            user: null,
            providerId: null
        }
    },
   methods: {
        init(){
            this.user = firebase.auth().currentUser;
            if (this.user) {
                this.newUsername = this.user.displayName;
                this.newEmail = this.user.email;
                this.oldUsername = this.user.displayName;
                this.oldEmail = this.user.email;
                this.providerId = this.user.providerData[0].providerId
            }
        },
        copyEmail(text) {
            copyTextToClipboard(text, "Email-Adresse wurde kopiert!");
        },
        copyId(text) {
            copyTextToClipboard(text, "ID wurde in Zwischenablage kopiert!");
        },
        clearCache() {
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.clearCacheMessageHandler) {
                window.webkit.messageHandlers.clearCacheMessageHandler.postMessage({"bogus": "value"});
            }
        },
        saveSettings() {
            if (this.newUsername !== this.oldUsername) {
                this.user.updateProfile({
                    displayName: this.newUsername,
                }).then(function() {
                    // Update successful.
                }).catch(function(error) {
                    // An error happened.
                    console.log(error);
                });
            }

            if (this.newEmail !== this.oldEmail) {
                this.user.updateEmail(this.newEmail).then(function() {
                    // Update successful.
                }).catch(function(error) {
                    // An error happened.
                    console.log(error);
                });
            }

            router.go(-1);
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        appVersion() {
            return this.$store.getters.getVersion;
        },
        installationId() {
            return this.$store.getters.getInstallationId;
        },
        deviceToken() {
            return this.$store.getters.getDeviceToken;
        },
        displayDeviceToken() {
            let d = this.deviceToken;
            if (d && d.length > 20) {
                return d.slice(0, 20) + "…";
            }
            return "";
        },
        showDebugInfo() {
            if (!this.user) { return false; }
            if (this.user.email) { return this.user.email.endsWith("sportplatz-media.com"); }
            return false;
        },
        isIOS() {
            return (window.webkit && window.webkit.messageHandlers);
        }
    }
}
</script>

<style lang="scss">
    .profile__detail {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 17px;
        width: 100%;
        max-width: 480px;
        margin: 16px auto 0;

        .button {
            margin-top: auto;
            position: sticky;
            bottom: 16px;
        }
    }
</style>
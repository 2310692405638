<template>
    <!-- Filter Modal -->
    <VModal name="workoutFilter" width="100%" height="auto" :shiftY="1" :scrollable="true">

      <div class="vm-header">
        <div class="vm-header-left">
          <img v-if="filterActive" @click="resetFilter" src="@/assets/ic_nav_reset.svg" alt="">
        </div>
        <div class="vm-header-center">
          <span>Filter</span>
        </div>
        <div class="vm-header-right">
          <img src="@/assets/ic_nav_close.svg" alt="" @click="dismissWorkoutFilter">
        </div>
      </div>

      <div class="vm-content">

        <div class="filter filter--selected">

            <!-- Workout-Typ -->
            <div :class="filterClasses(FilterMode.WORKOUT_TYPE)">
                <div class="filter__item__header">
                    <div class="filter__item__header__title">
                        Workout-Typ
                    </div>
                    <div class="filter__item__header__button" @click="displayTypeFilter = !displayTypeFilter">
                        {{ filterDescription(FilterMode.WORKOUT_TYPE) }}
                    </div>
                </div>
                <div v-show="displayTypeFilter" class="filter__item__content">
                    <div class="selection-cards selection-cards--threecolumn">

                        <div class="selection-cards__item selection-cards__item--all">
                            <input @click="toggleCategories" id="type-all" name="type" type="checkbox" value="0" v-model="allCategoriesSelected">
                            <label for="type-all">
                                <div class="selection-cards__item__label">Alle</div>
                            </label>
                        </div>

                        <div v-for="cat in categories" v-bind:key="cat.id" class="selection-cards__item">
                            <input @change="checkSelections" :id="cat.name" :name="cat.name" v-model="cat._filter_by" type="checkbox" value="0">
                            <label :for="cat.name">
                                <img :src="categoryIcon(cat, false)" class="selection-cards__item__icon" alt="">
                                <div class="selection-cards__item__label">{{ cat.name }}</div>
                            </label>
                        </div>

                    </div>
                </div>
            </div>

            <!-- Dauer -->
            <div :class="filterClasses(FilterMode.DURATION)">
                <div class="filter__item__header">
                    <div class="filter__item__header__title">
                        Dauer
                    </div>
                    <div class="filter__item__header__button" @click="displayDurationFilter = !displayDurationFilter">
                        {{ filterDescription(FilterMode.DURATION) }}
                    </div>
                </div>
                <div v-show="displayDurationFilter" class="filter__item__content">

                    <div class="filter__slider">
                        <Slider v-model="durationValue" :max="durationRange[1]" :min="durationRange[0]" showTooltip="drag"></Slider>
                        <div class="filter__slider__value">
                            <span>{{ durationValue[0] }} Min</span>
                            <span>{{ durationValue[1] }} Min</span>
                        </div>
                    </div>

                </div>
            </div>

            <!-- Intenstität -->
            <div :class="filterClasses(FilterMode.INTENSITY)">
                <div class="filter__item__header">
                    <div class="filter__item__header__title">
                        Intensität
                    </div>
                    <div class="filter__item__header__button" @click="displayIntensityFilter = !displayIntensityFilter">
                        {{ filterDescription(FilterMode.INTENSITY) }}
                    </div>
                </div>
                <div v-show="displayIntensityFilter" class="filter__item__content">

                    <div class="selection-cards__item selection-cards__item--all">
                        <input @click="toggleIntensities" id="intensity-all" name="intensity" type="checkbox" value="0" v-model="allIntensitiesSelected">
                        <label for="intensity-all">
                            <div class="selection-cards__item__label">Alle</div>
                        </label>
                    </div>

                    <div class="selection-cards selection-cards--threecolumn">
                        <div v-for="intensity in workoutIntensities" v-bind:key="intensity.id" class="selection-cards__item">
                            <input @change="checkSelections" :id="intensity.name" :name="intensity.name" type="checkbox" v-model="intensity._filter_by" value="0">
                            <label :for="intensity.name">
                                <img :src="intensityIcon(intensity, false)" class="selection-cards__item__icon" alt="">
                                <div class="selection-cards__item__label">{{ intensity.name }}</div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Equipment -->
            <div :class="filterClasses(FilterMode.EQUIPMENT)">
                <div class="filter__item__header">
                    <div class="filter__item__header__title">
                        Equipment
                    </div>
                    <div class="filter__item__header__button" @click="displayEquipmentFilter = !displayEquipmentFilter">
                        {{ filterDescription(FilterMode.EQUIPMENT) }}
                    </div>
                </div>
                <div v-show="displayEquipmentFilter" class="filter__item__content">
                    <div class="selection-cards equipment">

                        <div class="selection-cards__item selection-cards__item--all">
                            <input @click="toggleEquipment" id="equipment-all" name="equipment-all" type="checkbox" value="0" v-model="allEquipmentSelected">
                            <label for="equipment-all">
                                <div class="selection-cards__item__label">Alle</div>
                            </label>
                        </div>

                        <div class="selection-cards__item selection-cards__item--all">
                            <input @change="noEquipment" id="equipment-none" name="equipment-none" type="checkbox" value="0" v-model="noEquipmentAvailable">
                            <label for="equipment-none">
                                <div class="selection-cards__item__label">Kein Equipment</div>
                            </label>
                        </div>

                        <div v-for="eq in equipmentList" v-bind:key="eq.id" class="selection-cards__item">
                            <input @change="checkSelections" :id="'equipment_' + eq.name" :name="'equipment_' + eq.name" type="checkbox" v-model="eq._filter_by" value="0">
                            <label :for="'equipment_' + eq.name">
                                <div class="selection-cards__item__label">{{ eq.name }}</div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Sportarten -->
            <div :class="filterClasses(FilterMode.SPORTS_TYPE)">
                <div class="filter__item__header">
                    <div class="filter__item__header__title">
                        Sportarten
                    </div>
                    <div class="filter__item__header__button" @click="displaySportsTypeFilter = !displaySportsTypeFilter">
                        {{ filterDescription(FilterMode.SPORTS_TYPE) }}
                    </div>
                </div>
                <div v-show="displaySportsTypeFilter" class="filter__item__content">
                    <div class="selection-cards sporttypes">

                        <div class="selection-cards__item selection-cards__item--all">
                            <input @click="toggleSportsTypes" id="sporttypes-all" name="sporttypes" type="checkbox" value="0" v-model="allSportsTypesSelected">
                            <label for="sporttypes-all">
                                <div class="selection-cards__item__label">Alle</div>
                            </label>
                        </div>

                        <div v-for="st in limitedSportsTypes" v-bind:key="st.id" class="selection-cards__item">
                            <input @change="checkSelections" :id="'sportstype_' + st.name" :name="'sportstype_' + st.name" v-model="st._filter_by" type="checkbox" default_desc value="0">
                            <label :for="'sportstype_' + st.name">
                                <div class="selection-cards__item__label">{{ st.name }}</div>
                            </label>
                        </div>
                        <button v-show="!showAllSportsTypes" @click="showAllSportsTypes=true" class="button button--secondary button--small button--fullwidth">Alle Sportarten anzeigen</button>
                    </div>
                </div>
            </div>

            <!-- Moderation -->
            <div :class="filterClasses(FilterMode.MODERATED)">
                <div class="filter__item__header">
                    <div class="filter__item__header__title">
                        Moderation
                    </div>
                    <div class="filter__item__header__button" @click="displayModerationFilter = !displayModerationFilter">
                        {{ filterDescription(FilterMode.MODERATED) }}
                    </div>
                </div>
                <div v-show="displayModerationFilter" class="filter__item__content">
                    <div class="filter__radio">
                        <input v-model="moderated" type="radio" name="moderation" value="dontcare"  id="dontcare" checked>
                        <label for="dontcare">Egal</label>
                        <input v-model="moderated" type="radio" name="moderation" value="yes" id="yes">
                        <label for="yes">Mit</label>
                        <input v-model="moderated" type="radio" name="moderation" value="no" id="no">
                        <label for="no">Ohne</label>
                    </div>
                </div>
            </div>
            
            <!-- Coaches -->
            <div :class="filterClasses(FilterMode.COACH)">
                <div class="filter__item__header">
                    <div class="filter__item__header__title">
                        Coach
                    </div>
                    <div class="filter__item__header__button" @click="displayCoachFilter = !displayCoachFilter">
                        {{ filterDescription(FilterMode.COACH) }}
                    </div>
                </div>
                <div v-show="displayCoachFilter" class="filter__item__content">

                    <div class="selection-cards__item selection-cards__item--all">
                        <input @click="toggleCoaches" id="coaches-all" name="coaches-all" type="checkbox" value="0" v-model="allCoachesSelected">
                        <label for="coaches-all">
                            <div class="selection-cards__item__label">Alle</div>
                        </label>
                    </div>

                    <div class="selection-cards coaches">
                        <div v-for="coach in coaches" v-bind:key="coach.id" class="selection-cards__item">
                            <input @change="checkSelections" :id="'coach_' + coach.name" :name="'coach_' + coach.name" v-model="coach._filter_by" type="checkbox" value="0">
                            <label :for="'coach_' + coach.name">
                                <img :src="'https://app.sportlerplus.com/scaled/48/' + coach.avatar_image_url" class="selection-cards__item__icon" alt="">
                                <div class="selection-cards__item__label">{{ coach.name }}</div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      </div>

      <div class="vm-footer">
        <v-btn @click="finaliseSelection" class="button button--primary filterButton">Auswahl bestätigen</v-btn>
      </div>
    
    </VModal>

</template>
<style scoped>

</style>
<script>
const FilterMode = {
    WORKOUT_TYPE: 1,
    EQUIPMENT: 2,
    SPORTS_TYPE: 3,
    COACH: 4,
    MODERATED: 5,
    DURATION: 6,
    INTENSITY: 7,
}

const durationRange = [1, 70];

import Slider from '@vueform/slider/dist/slider.vue2.js';
import '@vueform/slider/themes/default.css';

export default {
    name: 'WorkoutFilter',
    components: { Slider, },
    created() {
        this.FilterMode = FilterMode;
        this.durationRange = durationRange;
    },
    data() {
        return {
            filterActive: false,
            coachFilterActive: false,
            sportsTypeFilterActive: false,
            moderated: null,
            durationValue: [1, 70],
            displayTypeFilter: false,
            displayEquipmentFilter: false,
            displaySportsTypeFilter: false,
            displayCoachFilter: false,
            displayModerationFilter: false,
            displayDurationFilter: false,
            displayIntensityFilter: false,
            showAllSportsTypes: false,
            allCategoriesSelected: false,
            allCoachesSelected: false,
            allEquipmentSelected: false,
            allIntensitiesSelected: false,
            allSportsTypesSelected: false,
            noEquipmentAvailable: false,
        }
    },
    props: {
        workouts: {
            type: Array,
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            window._lastFilterSettings = window._lastFilterSettings || {};
            this.checkSelections();
            this.moderated = window._lastFilterSettings.moderated;
            this.durationValue = window._lastFilterSettings.durationValue || durationRange;
        },
        resetFilter() {
            window._lastFilterSettings = {};
            this.moderated = null;
            this.durationValue = durationRange;
            this.workoutIntensities.forEach(wi => wi._filter_by = false);
            this.coaches.forEach(c => c._filter_by = false);
            this.equipmentList.forEach(eq => eq._filter_by = false);
            this.categories.forEach(c => c._filter_by = false);
            this.sportsTypes.forEach(st => st._filter_by = false);
            this.allCategoriesSelected = 
            this.allCoachesSelected = 
            this.allEquipmentSelected = 
            this.allIntensitiesSelected = 
            this.allSportsTypesSelected = false;
            this.filterActive = false;
            this.coachFilterActive = false;
            this.sportsTypeFilterActive = false;
            this.noEquipmentAvailable = false;
            this.$emit("resetFilter");
        },
        setCategoryFilter(cat_id) {
            this.resetFilter();
            let cat = this.categories.find(c => c.id === cat_id);
            if (cat != null) {
                cat._filter_by = true;
                console.log("set filter to category: " + cat.name);
            }
        },
        collapse() {
            this.displayTypeFilter =
            this.displayEquipmentFilter =
            this.displaySportsTypeFilter =
            this.displayCoachFilter =
            this.displayModerationFilter =
            this.displayDurationFilter =
            this.displayIntensityFilter = false;
        },
        checkSelections() {
            let selectedCategories = this.categories.filter(c => c._filter_by);
            this.allCategoriesSelected = this.categories.length == selectedCategories.length;
            let selectedCoaches = this.coaches.filter(c => c._filter_by);
            this.allCoachesSelected = this.coaches.length == selectedCoaches.length;
            let selectedIntensities = this.workoutIntensities.filter(wi => wi._filter_by);
            this.allIntensitiesSelected = this.workoutIntensities.length == selectedIntensities.length;
            let selectedSportsTypes = this.sportsTypes.filter(st => st._filter_by);
            this.allSportsTypesSelected = this.sportsTypes.length == selectedSportsTypes.length;
            let selectedEquipment = this.equipmentList.filter(eq => eq._filter_by);
            this.allEquipmentSelected = this.equipmentList.length == selectedEquipment.length;
            if (selectedEquipment.length > 0) {
                this.noEquipmentAvailable = false;
            }
        },
        toggleCategories() {
            this.categories.forEach(c => c._filter_by = !this.allCategoriesSelected);
            this.allCategoriesSelected = !this.allCategoriesSelected;
        },
        toggleEquipment() {
            this.equipmentList.forEach(eq => eq._filter_by = !this.allEquipmentSelected);
            this.allEquipmentSelected = !this.allEquipmentSelected;
            this.noEquipmentAvailable = false;
        },
        noEquipment() {
            if (this.noEquipmentAvailable) {
                this.equipmentList.forEach(eq => eq._filter_by = false);
                this.allEquipmentSelected = false;
            }
        },
        toggleCoaches() {
            this.coaches.forEach(c => c._filter_by = !this.allCoachesSelected);
            this.allCoachesSelected = !this.allCoachesSelected;
        },
        toggleIntensities() {
            this.workoutIntensities.forEach(wi => wi._filter_by = !this.allIntensitiesSelected);
            this.allIntensitiesSelected = !this.allIntensitiesSelected;
        },
        toggleSportsTypes() {
            this.sportsTypes.forEach(st => st._filter_by = !this.allSportsTypesSelected);
            this.allSportsTypesSelected = !this.allSportsTypesSelected;
        },
        finaliseSelection() {
            let availableEquipment = this.equipmentList.filter(eq => eq._filter_by);
            if (availableEquipment.length > 0) {
                // TODO: check if this is desired behaviour
                this.$store.dispatch('updateUserEquipment', availableEquipment);
            }
            window._lastFilterSettings.moderated = this.moderated;
            window._lastFilterSettings.durationValue = this.durationValue;
            let filterResult = this.filterWorkouts();
            this.$emit("filterResult", filterResult);
            this.dismissWorkoutFilter();
        },
        dismissWorkoutFilter() {
            this.$emit("closeModal");
        },
        filterClasses(flt) {
            var classList = ["filter__item"];
            let active_item = "filter__item--active";
            let selected_item = "filter__item--selected";
            if (flt == FilterMode.WORKOUT_TYPE) {
                if (this.displayTypeFilter) { classList.push(active_item); }
                let selectedCategories = this.categories.filter(c => c._filter_by);
                if (selectedCategories.length > 0) { classList.push(selected_item); this.filterActive = true; }
            }
            else if (flt == FilterMode.EQUIPMENT) {
                if (this.displayEquipmentFilter) { classList.push(active_item); }
                let selectedEquipment = this.equipmentList.filter(eq => eq._filter_by);
                if (selectedEquipment.length > 0 || this.noEquipmentAvailable) { classList.push(selected_item); this.filterActive = true; }
            }
            else if (flt == FilterMode.SPORTS_TYPE) {
                if (this.displaySportsTypeFilter) { classList.push(active_item); }
                let selectedSportsTypes = this.sportsTypes.filter(st => st._filter_by);
                if (selectedSportsTypes.length > 0) { classList.push(selected_item); this.filterActive = true; this.sportsTypeFilterActive = true; }
                else { this.sportsTypeFilterActive = false; }
            }
            else if (flt == FilterMode.COACH) {
                if (this.displayCoachFilter) { classList.push(active_item); }
                let selectedCoaches = this.coaches.filter(c => c._filter_by);
                if (selectedCoaches.length > 0) { classList.push(selected_item); this.filterActive = true; this.coachFilterActive = true; }
                else { this.coachFilterActive = false; }
            }
            else if (flt == FilterMode.MODERATED) {
                if (this.displayModerationFilter) { classList.push(active_item); }
                if (this.moderated != null) { classList.push(selected_item); this.filterActive = true;}
            }
            else if (flt == FilterMode.DURATION) {
                if (this.displayDurationFilter) { classList.push(active_item); }
                if (this.durationRange[0] != this.durationValue[0] || this.durationRange[1] != this.durationValue[1]) { classList.push(selected_item); this.filterActive = true; }
            }
            else if (flt == FilterMode.INTENSITY) {
                if (this.displayIntensityFilter) { classList.push(active_item); }
                let selectedIntensities = this.workoutIntensities.filter(wi => wi._filter_by);
                if (selectedIntensities.length > 0) { classList.push(selected_item); this.filterActive = true; }
            }
            return classList.join(" ");
        },
        filterDescription(flt) {
            let default_desc = "Einstellen";
            let active_desc = "Fertig";
            if (flt == FilterMode.WORKOUT_TYPE) {
                if (this.displayTypeFilter) { return active_desc; }
                let selectedCategories = this.categories.filter(c => c._filter_by).map(c => c.name);
                if (selectedCategories.length == this.categories.length) {
                    return "Alle";
                }
                if (selectedCategories.length > 0) {
                    if (selectedCategories.length >= 4) {
                        let more = selectedCategories.length - 2;
                        return selectedCategories.slice(0, 2).join(", ") + " und " + more + " mehr";
                    }
                    return selectedCategories.join(", ")
                }
            }
            else if (flt == FilterMode.MODERATED) {
                if (this.displayModerationFilter) { return active_desc; }
                if (this.moderated == null)  { return default_desc; }
                let md = {"yes": "Mit", "no": "Ohne", "dontcare": "Egal"};
                return md[this.moderated];
            }
            else if (flt == FilterMode.SPORTS_TYPE) {
                if (this.displaySportsTypeFilter) { return active_desc; }
                let selectedSportsTypes = this.sportsTypes.filter(st => st._filter_by).map(st => st.name);
                if (selectedSportsTypes.length == this.sportsTypes.length) {
                    return "Alle";
                }
                if (selectedSportsTypes.length > 0) {
                    if (selectedSportsTypes.length >= 4) {
                        let more = selectedSportsTypes.length - 2;
                        return selectedSportsTypes.slice(0, 2).join(", ") + " und " + more + " mehr";
                    }
                    return selectedSportsTypes.join(", ");
                }
            }
            else if (flt == FilterMode.COACH) {
                if (this.displayCoachFilter) { return active_desc; }
                let selectedCoaches = this.coaches.filter(c => c._filter_by).map(c => c.name);
                if (selectedCoaches.length == this.coaches.length) {
                    return "Alle";
                }
                if (selectedCoaches.length > 0) {
                    if (selectedCoaches.length >= 4) {
                        let more = selectedCoaches.length - 2;
                        return selectedCoaches.slice(0, 2).join(", ") + " und " + more + " mehr";
                    }
                    return selectedCoaches.join(", ");
                }
            }
            else if (flt == FilterMode.EQUIPMENT) {
                if (this.displayEquipmentFilter) { return active_desc; }
                let selectedEquipment = this.equipmentList.filter(eq => eq._filter_by).map(eq => eq.name);
                if (selectedEquipment.length == this.equipmentList.length) {
                    return "Alle";
                }
                if (this.noEquipmentAvailable) {
                    return "Ohne";
                }
                if (selectedEquipment.length > 0) {
                    if (selectedEquipment.length >= 4) {
                        let more = selectedEquipment.length - 2;
                        return selectedEquipment.slice(0 ,2).join(", ") + " und " + more + " mehr";
                    }
                    return selectedEquipment.join(", ");
                }
            }
            else if (flt == FilterMode.DURATION) {
                if (this.displayDurationFilter) { return active_desc; }
                if (this.durationValue[0] == this.durationRange[0] && this.durationValue[1] == this.durationRange[1]) {
                    return default_desc;
                }
                return this.durationValue.join(" - ") + " Minuten";
            }
            else if (flt == FilterMode.INTENSITY) {
                if (this.displayIntensityFilter) { return active_desc; }
                let selectedIntensities = this.workoutIntensities.filter(wi => wi._filter_by).map(wi => wi.name);
                if (selectedIntensities.length == this.workoutIntensities.length) {
                    return "Alle";
                }
                if (selectedIntensities.length > 0) {
                    return selectedIntensities.join(", ");
                }
            }
            return default_desc;
        },
        intensityIcon(intensity, active) {
            if (intensity.name == "Leicht" && !active) {
                return require("@/assets/filter/filter-intensity-low.svg");
            }
            else if (intensity.name == "Leicht" && active) {
                return require("@/assets/filter/filter-intensity-low--active.svg");
            }
            else if (intensity.name == "Mittel" && !active) {
                return require("@/assets/filter/filter-intensity-middle.svg");
            }
            else if (intensity.name == "Mittel" && active) {
                return require("@/assets/filter/filter-intensity-middle--active.svg");
            }
            else if (intensity.name == "Schwer" && !active) {
                return require("@/assets/filter/filter-intensity-high.svg");
            }
            else if (intensity.name == "Schwer" && active) {
                return require("@/assets/filter/filter-intensity-high--active.svg");
            }
        },
        categoryIcon(cat, active) {
            if (cat.name == "Oberkörper" && !active) {
                return require("@/assets/filter/filter-type-oberkoerper.svg");
            }
            else if (cat.name == "Oberkörper" && active) {
                return require("@/assets/filter/filter-type-oberkoerper--active.svg")
            }
            else if (cat.name == "Brust" && !active) {
                return require("@/assets/filter/filter-type-chest.svg");
            }
            else if (cat.name == "Brust" && active) {
                return require("@/assets/filter/filter-type-chest--active.svg")
            }
            else if (cat.name == "Rücken" && !active) {
                return require("@/assets/filter/filter-type-back.svg");
            }
            else if (cat.name == "Rücken" && active) {
                return require("@/assets/filter/filter-type-back--active.svg")
            }
            else if (cat.name == "Unterkörper" && !active) {
                return require("@/assets/filter/filter-type-lowerbody.svg");
            }
            else if (cat.name == "Unterkörper" && active) {
                return require("@/assets/filter/filter-type-lowerbody--active.svg")
            }
            else if (cat.name == "Beine" && !active) {
                return require("@/assets/filter/filter-type-legs.svg");
            }
            else if (cat.name == "Beine" && active) {
                return require("@/assets/filter/filter-type-legs--active.svg")
            }
            else if (cat.name == "Po" && !active) {
                return require("@/assets/filter/filter-type-butt.svg");
            }
            else if (cat.name == "Po" && active) {
                return require("@/assets/filter/filter-type-butt--active.svg")
            }
            else if (cat.name == "Core" && !active) {
                return require("@/assets/filter/filter-type-core.svg");
            }
            else if (cat.name == "Core" && active) {
                return require("@/assets/filter/filter-type-core--active.svg")
            }
            else if (cat.name == "Mobility" && !active) {
                return require("@/assets/filter/filter-type-mobility.svg");
            }
            else if (cat.name == "Mobility" && active) {
                return require("@/assets/filter/filter-type-mobility--active.svg")
            }
            else if (cat.name == "Warm Up" && !active) {
                return require("@/assets/filter/filter-type-warmup.svg");
            }
            else if (cat.name == "Warm Up" && active) {
                return require("@/assets/filter/filter-type-warmup--active.svg")
            }
            else if (cat.name == "Cool Down" && !active) {
                return require("@/assets/filter/filter-type-cooldown.svg");
            }
            else if (cat.name == "Cool Down" && active) {
                return require("@/assets/filter/filter-type-cooldown--active.svg")
            }
            else if (cat.name == "Arme" && !active) {
                return require("@/assets/filter/filter-type-arms.svg");
            }
            else if (cat.name == "Arme" && active) {
                return require("@/assets/filter/filter-type-arms--active.svg")
            }
            else if (cat.name == "Fullbody" && !active) {
                return require("@/assets/filter/filter-type-fullbody.svg");
            }
            else if (cat.name == "Fullbody" && active) {
                return require("@/assets/filter/filter-type-fullbody--active.svg")
            }
            else if (cat.name == "Yoga" && !active) {
                return require("@/assets/filter/filter-type-yoga.svg");
            }
            else if (cat.name == "Yoga" && active) {
                return require("@/assets/filter/filter-type-yoga--active.svg")
            }
        },
        filterWorkouts() {
            let filtered = [];

            let searchCategories = this.categories.filter(c => c._filter_by).map(c => c.id);
            let searchIntensities = this.workoutIntensities.filter(wi => wi._filter_by).map(wi => wi.id);
            let searchSportsTypes = this.sportsTypes.filter(st => st._filter_by).map(st => st.id);
            let searchCoaches = this.coaches.filter(c => c._filter_by).map(c => c.id);
            let searchEquipment = this.equipmentList.filter(eq => eq._filter_by).map(eq => eq.id);
            let searchDuration = this.durationValue;
            let searchModerated = this.moderated;
            let noEquipment = this.noEquipmentAvailable;
            //console.log("categories: " + searchCategories);
            //console.log("intensities: " + searchIntensities);
            //console.log("sportsType: " + searchSportsTypes);
            //console.log("coaches: " + searchCoaches);
            //console.log("equipment: " + searchEquipment);
            //console.log("no equipment: " + noEquipment);
            //console.log("duration: " + searchDuration);
            //console.log("moderated: " + searchModerated);
            
            this.workouts.forEach((el) => {
                let categoryMatch = false, intensityMatch = false, coachMatch = false, sportsTypeMatch = false, durationMatch = false, equipmentMatch = false, moderatedMatch = false;

                // handle categories FIXME
                if (searchCategories.length == 0) {
                    categoryMatch = true;
                }
                else {
                    el.category_list.forEach( elcat => {
                        if (searchCategories.includes(elcat)) {
                            categoryMatch = true;
                        }
                    });
                    //categoryMatch = true;
                }

                // handle equipment
                if (searchEquipment.length == 0 && !noEquipment) {
                    equipmentMatch = true;
                }
                else if (noEquipment && !el.requires_equipment) {
                    equipmentMatch = true;
                }
                else if (searchEquipment.length > 0 && el.requires_equipment) {
                    equipmentMatch = true;
                    el.equipment_list.forEach( eq => {
                        if (!searchEquipment.includes(eq)) {
                            equipmentMatch = false;
                        }
                    });
                }

                // handle duration
                if (el.duration >= searchDuration[0] && el.duration <= searchDuration[1]) {
                    durationMatch = true
                }

                // handle sports types
                if (searchSportsTypes.length == 0) {
                    sportsTypeMatch = true;
                }
                else {
                    el.sports_types.forEach( elst => {
                        if (searchSportsTypes.includes(elst.id)) {
                            sportsTypeMatch = true;
                        }
                    });
                }

                // handle intensities
                if (searchIntensities.length == 0) {
                    intensityMatch = true;
                }
                else {
                    let currentIntensity = el.difficulty;
                    if (searchIntensities.includes(currentIntensity)) {
                        intensityMatch = true;
                    }
                }

                // handle coaches
                if (searchCoaches.length == 0) {
                    coachMatch = true;
                }
                else {
                    if (el.coach != null) { 
                        let currentCoach = el.coach.id;
                        if (searchCoaches.includes(currentCoach)) {
                            coachMatch = true;
                        }
                    }
                }

                // handle moderation
                if (searchModerated== null || searchModerated == "dontcare") {
                    moderatedMatch = true;
                }
                else {
                    if ((searchModerated == "yes" && el.meta.type == "workout.WorkoutPage") || (searchModerated == "no" && el.meta.type == "exercise.PlaylistPage")) {
                        moderatedMatch = true;
                    }
                }

                if ((!el.hide_from_overview || this.sportsTypeFilterActive || this.coachFilterActive) && categoryMatch && intensityMatch && coachMatch && sportsTypeMatch && durationMatch && moderatedMatch && equipmentMatch) {
                    filtered.push(el);
                }
            });

            console.log("filtered = " + filtered.length);
            return filtered;
        }
    },
    computed: {
        coaches() {
			return this.$store.getters.getCoaches;
		},
        sportsTypes() {
            return this.$store.getters.getSportsTypes;
        },
        limitedSportsTypes() {
            if (this.showAllSportsTypes) {
                return this.$store.getters.getSportsTypes;
            }
            return this.$store.getters.getSportsTypes.slice(0 ,4);
        },
        categories() {
            return this.$store.getters.getCategories;
        },
        equipmentList() {
            return this.$store.getters.getEquipmentList;
        },
        workoutIntensities() {
            return this.$store.getters.getWorkoutIntensities;
        },
        anyFilterSettingsMade() {
            let selectedCategories = this.categories.filter(c => c._filter_by);
            let selectedCoaches = this.coaches.filter(c => c._filter_by);
            let selectedSportsTypes = this.sportsTypes.filter(st => st._filter_by);
            let selectedIntensities = this.workoutIntensities.filter(wi => wi._filter_by);
            let selectedEquipment = this.equipmentList.filter(eq => eq._filter_by);

            return (this.durationRange[0] != this.durationValue[0] || this.durationRange[1] != this.durationValue[1]) ||
                this.moderated || selectedCategories.length > 0  ||
                selectedCoaches.length > 0 || selectedSportsTypes.length > 0 ||
                selectedIntensities.length > 0 || selectedEquipment.length > 0;
        }
    }
}
</script>

<style lang="scss" scoped>
    .selection-cards {
        .button--fullwidth {
            grid-column: 1 / -1;
        }
    }
</style>
<template>
  <v-app v-bind:class="appExtraClasses">
    <PremiumHintModal modalName="fitnessPlanPremiumHint" :noTrialButton="true" :infoArticle="'/articles/509'"></PremiumHintModal>

    <app-top-navigation @likeArticle=likeArticle @removeArticleLike=removeArticleLike @shareArticle=shareArticle v-if="!hideNavbars"></app-top-navigation>

    <v-main transition="slide-x-transition" v-bind:class="mainExtraClasses">
        <router-view @setCurrentArticle=setCurrentArticle></router-view>
    </v-main>

    <app-bottom-navigation v-if="isAuthenticated && !hideNavbars && !hideBottomNavbar" @showPremiumHint="showPremiumHint"></app-bottom-navigation>
    
  </v-app>
</template>

<script>
import AppTopNavigation from '@/components/AppTopNavigation';
import AppBottomNavigation from '@/components/AppBottomNavigation';
import { sharePage } from '@/sportlerplus-helper';
import PremiumHintModal from '@/components/PremiumHintModal.vue';

export default {
    name: 'App',
    metaInfo() {
        return { 
            title: "SportlerPlus",
            meta: [
                { vmid: 'description', name: 'description', content:  'Sports Nutrition, Homeworkouts & spannnendes Wissen rund um Training & Ernährung - SportlerPlus ist die ganzheitliche Fitness-Marke für alle Teamsportler:innen.'},
                { vmid: 'keywords', name: 'keywords', content: 'Sport App,Workout App,Homeworkouts,Workout zuhause,Workout Plan,Workout Übungen,Workout Bauch,Workout Brust (etc.),Ganzkörper workout,Ganzkörpertraining,Training ohne Geräte,Training zuhause,Fitness zuhause,Fitness zuhause ohne Geräte,Fitness zuhause app' },
                { vmid: 'og:title', property: 'og:title', content: "SportlerPlus"},
                { vmid: 'og:site_name', property: 'og:site_name', content: 'SportlerPlus'},
                { vmid: 'og:type', property: 'og:type', content: 'website'},    
                { vmid: 'robots', name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    components: {
        AppTopNavigation,
        AppBottomNavigation,
        PremiumHintModal
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters.isAuthenticated;
        },
        hideNavbars() {
            if (this.$route.meta.hideNavbars) { return this.$route.meta.hideNavbars; }
            if (!this.$store.getters.isAuthenticated && this.$route.meta.hideNavbarsForAnonUsers) {
                return true;
            }
            return false;
        },
        hideBottomNavbar() {
            if (this.$route.meta.hideBottomNavbar) { return this.$route.meta.hideBottomNavbar; }
            return false;
        },
        mainExtraClasses() {
            let class_names = [];
            if (this.isAuthenticated && !(this.$route.name == "welcome")) { class_names.push("loggedin"); }
            if (this.$route.meta.mainExtraClasses) { class_names.push(this.$route.meta.mainExtraClasses); } 
            if (this.$route.meta.hideBottomNavbar) { class_names.push("bottomnav-hidden"); }
            return class_names.join(" ");
        },
        appExtraClasses() {
            let class_names = [];
            if (this.$route.meta.whiteBackground) { class_names.push("bg-white"); }
            if (this.$route.meta.blackBackground) { class_names.push("bg-black"); }
            if (!this.$store.getters.isAuthenticated && this.$route.meta.hideNavbarsForAnonUsers) { class_names.push("home-loggedout"); }
            return class_names.join(" ");
        },
    },
    methods: {
        showPremiumHint() {
            this.$modal.show("fitnessPlanPremiumHint");
        },
        setCurrentArticle(article) {
            this.currentArticle = article;
        },
        likeArticle() {
            if (this.currentArticle != null) {
                this.$store.dispatch('likeArticle', this.currentArticle);
            }
        },
        removeArticleLike() {
            if (this.currentArticle != null) {
                this.$store.dispatch('removeArticleLike', this.currentArticle);
            }
        },
        shareArticle() {
            if (this.currentArticle == null) { return; }
            let shareText = "Hey, diesen Inhalt im SportlerPlus Magazin finde ich echt spannend: „{0}“\n\nDas solltest du dir unbedingt auch ansehen. 💪🚀".format(this.currentArticle.title);
            sharePage(document.location.href, null, shareText, null);
        }
    },
    data() {
        return {
            currentArticle: null,
        }
    }
};
</script>

<style lang="scss">
@import "@/scss/_global.scss";
@import "@/scss/_font.scss";
</style>
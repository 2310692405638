<template>
    <VModal :name="name" class="playlistedit-modal" width="100%" height="auto" :shiftY="1" :scrollable="true">
        <div class="vm-content">
            <div class="list__item">
                <div class="list__item__icon">
                    <img src="@/assets/ic_24_playlist_start.svg" alt="">
                </div>
                <div class="list__item__content">
                    <div @click="startFitnessPlanEvent" class="list__item__label">
                        {{ startMenuItemTitle }}
                    </div>
                </div>
            </div>
            <div v-if="isRepeatingEvent" class="list__item">
                <div class="list__item__icon">
                    <img src="@/assets/ic_24_playlist_edit.svg" alt="">
                </div>
                <div class="list__item__content">
                    <div @click="editFitnessPlanEvent(false)" class="list__item__label">
                      Einzeltermin aus Serie bearbeiten
                    </div>
                </div>
            </div>
            <div v-if="isRepeatingEvent" class="list__item">
                <div class="list__item__icon">
                    <img src="@/assets/ic_24_playlist_delete.svg" alt="">
                </div>
                <div class="list__item__content">
                    <div @click="deleteSingleWorkoutFromFitnessPlanEvent" class="list__item__label">
                      Einzeltermin aus Serie löschen
                    </div>
                </div>
            </div>
            <div v-if="!activityFinished" class="list__item">
                <div class="list__item__icon">
                    <img src="@/assets/ic_24_playlist_edit.svg" alt="">
                </div>
                <div class="list__item__content">
                    <div @click="editFitnessPlanEvent(true)" class="list__item__label">
                        {{ editMenuItemTitle }}
                    </div>
                </div>
            </div>
            <div v-if="isRepeatingEvent" class="list__item">
                <div class="list__item__icon">
                    <img src="@/assets/ic_24_playlist_delete.svg" alt="">
                </div>
                <div class="list__item__content">
                    <div @click="deleteFitnessPlanEvent" class="list__item__label">
                        Serientermin löschen
                    </div>
                </div>
            </div>
            <div v-else class="list__item">
                <div class="list__item__icon">
                    <img src="@/assets/ic_24_playlist_delete.svg" alt="">
                </div>
                <div class="list__item__content">
                    <div @click="deleteFitnessPlanEvent" class="list__item__label">
                        {{ deleteMenuItemTitle }}
                    </div>
                </div>
            </div>
        </div>
    </VModal>
</template>
<script>
export default {
    name: 'EditFitnessPlanItemModal',
    props: {
        name: {
            type: String,
            default() {
                return "editFitnessPlanItemModal";
            }
        },
        fitnessPlanEvent: {
            type: Object,
            default() { return {}; } 
        }
    },
    methods: {
        editFitnessPlanEvent(isSingleEvent) {
            window.localStorage.setItem('activityDate', this.fitnessPlanEvent.activityDate);
            if (this.fitnessPlanEvent.activityEndDate) {
                window.localStorage.setItem('activityEndDate', this.fitnessPlanEvent.activityEndDate);
            }
            window.localStorage.setItem('activityTime', this.fitnessPlanEvent.activityTime);
            window.localStorage.setItem('selectedRepeatInterval', this.fitnessPlanEvent.repeatInterval);
            window.localStorage.setItem('repeatActivity', this.fitnessPlanEvent.repeating);
            if(this.fitnessPlanEvent.uniqueChallengeId) {
                window.localStorage.setItem('uniqueChallengeId', this.fitnessPlanEvent.uniqueChallengeId);
            }
            this.$store.commit('setSelectedFitnessPlanActivity', this.fitnessPlanEvent.activity);

            if(isSingleEvent) {
            this.$router.push("/fitnessplan/create?guid=" + this.fitnessPlanEvent.guid + "&navbartitle=Training%20bearbeiten");
            }
            else {
                window.localStorage.setItem('repeatActivity', this.fitnessPlanEvent.repeating = undefined);
                window.localStorage.setItem('originalActivityDate', this.fitnessPlanEvent.activityDate);

                this.$router.push("/fitnessplan/create?guid=&related_guid=" + this.fitnessPlanEvent.guid + "&navbartitle=Training%20bearbeiten");
            }
        },
        startFitnessPlanEvent() {
            this.$emit("startFitnessPlanEvent", this.fitnessPlanEvent);
        },
        deleteFitnessPlanEvent() {
            if (this.fitnessPlanEvent.uniqueChallengeId && !this.fitnessPlanEvent.historicalEvent) {
                this.$modal.hide("editFitnessPlanItemModal")
                this.$emit("confirmDeleteChallenge");
            }
            else {
                this.$emit("deleteFitnessPlanEvent", this.fitnessPlanEvent);
            }
        },
        deleteSingleWorkoutFromFitnessPlanEvent() {
            this.$emit("deleteSingleWorkoutFromFitnessPlanEvent", this.fitnessPlanEvent)
        },
        activityType(activity) {
			if (!activity) { return null; }
			if (activity.meta === undefined) { return "Category"; }
			else { return activity.meta.type; }
		},
    },
    computed: {
        editMenuItemTitle() {
            if (this.fitnessPlanEvent && this.fitnessPlanEvent.repeating && !this.fitnessPlanEvent.uniqueChallengeId) {
                return "Serientermin bearbeiten";
            }
            else if (this.fitnessPlanEvent && this.fitnessPlanEvent.uniqueChallengeId && !this.fitnessPlanEvent.historicalEvent) {
                return "Challenge Einheit bearbeiten"
            }
            return "Bearbeiten";           
        },
        startMenuItemTitle() {
            if (this.fitnessPlanEvent && this.fitnessPlanEvent.activityState && this.fitnessPlanEvent.activityState == 'finished') {
                return "Daten ansehen";
            }
            else if (this.fitnessPlanEvent && this.activityType(this.fitnessPlanEvent.activity) == 'Custom/SportsType' && !this.fitnessPlanEvent.uniqueChallengeId) {
                return "Daten eingeben";
            }
            else if (this.fitnessPlanEvent && this.fitnessPlanEvent.uniqueChallengeId && !this.fitnessPlanEvent.historicalEvent) {
                return "Zur Challenge Einheit"
            }
            return "Zur Einheit";
        },
        deleteMenuItemTitle() {
            if (this.fitnessPlanEvent && this.fitnessPlanEvent.uniqueChallengeId && !this.fitnessPlanEvent.historicalEvent) {
                return "Gesamte Challenge löschen"
            }
            return "Löschen";
        },
        activityFinished() {
            if (this.fitnessPlanEvent && this.fitnessPlanEvent.activityState && this.fitnessPlanEvent.activityState == 'finished') {
                return true;
            }
            return false;
        },
        isRepeatingEvent() {
            return this.fitnessPlanEvent && this.fitnessPlanEvent.repeating;
        }
    }
}
</script>
<style lang="scss">
    .playlistedit-modal {
        .vm--modal {
            width: calc(100% - 32px) !important;
            margin: 0px auto 16px;
            transform: translateY(-16px);
            border-radius: 8px !important;
        }
        .vm-content {
            padding: 8px 16px;
        }
    }
</style>
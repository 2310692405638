<template>
    <VModal :name="modalName" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
        <div class="vm-header">
            <div class="vm-header-left">
            </div>
            <div class="vm-header-center">
            </div>
            <div class="vm-header-right">
                <img src="@/assets/ic_nav_close.svg" alt="" @click="dismissDialog">
            </div>
        </div>
        <div class="vm-content">
            <img src="@/assets/notification/ic_notification_premium.svg" alt="">
            <!-- TODO @Marco: Show this icon if user had a premium membership which has been expired -->
            <!--<img src="@/assets/notification/ic_notification_premium-inactive.svg" alt="">-->
            <h3>
                {{ dialogTitle }}
            </h3>
            <div v-if="badge" class="premium__badge">
                {{ badge }}
            </div>
            <p>
                {{ dialogMessage }}
            </p>
            <v-btn type="button" class="button button--primary button--small" @click="buyPremiumPackage">Premium-Mitglied werden</v-btn>
            <v-btn v-if="infoArticle" type="button" class="button button--secondary button--small" @click="openInfoArticle">Mehr Info</v-btn>
            <v-btn v-else-if="noTrialButton" type="button" class="button button--secondary button--small" @click="dismissDialog">Nein, jetzt nicht</v-btn>
            <v-btn v-else type="button" class="button button--secondary button--small" to="/probetraining">Kostenfreie Inhalte ansehen</v-btn>
        </div>
    </VModal>
</template>

<script>
export default {
    name: 'PremiumHintModal',
    props: {
        message: {
            type: String,
        },
        title: {
            type: String,
        },
        badge: {
            type: String,
        },
        noTrialButton: {
            type: Boolean,
        },
        infoArticle: {
            type: String,
        },
        modalName: {
            type: String,
            default: "premiumHint"
        }
    },
    computed: {
        dialogMessage() {
            if (this.message == null) {
                return "Diesen Inhalt kannst du nur als Premium-Mitglied nutzen. Alternativ kannst du dir alle kostenfreien Inhalt ansehen.";
            }
            else {
                return this.message;
            }
        },
        dialogTitle() {
            if (this.title == null) {
                return "Premium-Feature";
            }
            else {
                return this.title;
            }
        }
    },
    methods: {
        dismissDialog() {
            this.$modal.hide(this.modalName);
        },
        buyPremiumPackage() {
            this.$modal.hide(this.modalName);
            this.$router.push("/settings/premium");
        },
        openInfoArticle() {
            this.$modal.hide(this.modalName);
            this.$router.push(this.infoArticle);
        }
    }
}
</script>

<style lang="scss" scoped>
    .vm-content {
        padding-top: 0;

        img {
            margin-top: 0;
        }
    }

    .premium__badge {
        display: inline-flex;
        margin: -4px auto 19px;
        font-size: 11px;
        line-height: 11px;
        font-weight: 700;
        letter-spacing: -0.13px;
        padding: 4px 5px 3px 6px;
        border-radius: 2px;
        color: $colorWhite;
        background: $colorDanger;
    }
</style>
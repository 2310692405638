import Vue from 'vue'

function convertTrackingLinkApp(link) {
    return _convertTrackingLink(link, {
        "source": "app",
    })
}

function convertTrackingLinkWebsite(link) {
    return _convertTrackingLink(link, {
        "source": "website",
    })
}

function _convertTrackingLink(link, d) {
    return link.replaceAll("[TRACKING_SOURCE]", d.source);
}

function shuffleArray(array) {
    let counter = array.length;

    // While there are elements in the array
    while (counter > 0) {
        // Pick a random index
        let index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        let temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
    }

    return array;
}

function showToast(successInfo) {
    Vue.$toast.success(successInfo, {
        position: 'bottom-right',
        timeout: 3000
      })
}

function generateGuid() {
    var S4 = function() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function toIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
  
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
}

function showTextCopiedToast(message) {
    if (message == null) {
        message="Text wurde in die Zwischenablage kopiert!";
    }
    showToast(message);
}

function fallbackCopyTextToClipboard(text, successMessage) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
        showTextCopiedToast(successMessage);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

function copyTextToClipboard(text, successMessage) {
    if (window.AndroidJSInterface != undefined) {
        window.AndroidJSInterface.copyToClipboard(text);
        showTextCopiedToast(successMessage);
        return;
    }

    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text, successMessage);
        return;
    }
    navigator.clipboard.writeText(text).then(() => {
        console.log('Async: Copying to clipboard was successful!');
        showTextCopiedToast(successMessage);
    }, (err) => {
        console.error('Async: Could not copy text: ', err);
    });
}

function shareImage(image_url) {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.shareMessageHandler) {
        window.webkit.messageHandlers.shareMessageHandler.postMessage({image: image_url, text: "", url: "", title: ""})
    }
    else if (window.AndroidJSInterface != undefined) {
        window.AndroidJSInterface.shareImage(image_url);
    }
}

/* url may be null, uses current browser location then */
function sharePage(url, title, text, image_url) {
    var full_image_url = null;
    if (image_url != null && !image_url.startsWith("https://")) {
        full_image_url = 'https://app.sportlerplus.com/scaled/500/' + image_url;
    }
    else {
        full_image_url = image_url;
    }
    if (title == null) {
        // Default text
        title = "Diesen SportlerPlus-Inhalt möchte ich mit dir teilen..."; 
    }
    if (url == null) {
        if (window.location.search) {
            url = "{0}/sharing{1}?{2}".format(window.location.origin, window.location.pathname, window.location.search);
        }
        else {
            url = "{0}/sharing{1}".format(window.location.origin, window.location.pathname);
        }
        
    }
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.shareMessageHandler) {
        window.webkit.messageHandlers.shareMessageHandler.postMessage({text: text + "\n\n" + url, title: title, url: "",
            image: full_image_url});
    }
    else if (window.AndroidJSInterface != undefined) {
        window.AndroidJSInterface.shareTextUrl(title, text, url);
    }
    else if (navigator.canShare) {
        try {
            navigator.share({
                url: url,
                title: title,
                text: text,
              }).then(() => console.log("sharing successful"));
        }
        catch(err) {
            console.log(err);
        }
    }
}

export { addDays, toIsoString, sharePage, shareImage, generateGuid, showToast, copyTextToClipboard, shuffleArray, convertTrackingLinkApp, convertTrackingLinkWebsite };
<template>
    <div class="training">

        <!-- Workout CTA Button -->
        <cta-button v-if="!filterActive && (userPlaylists == null || userPlaylists.length == 0)"
                    path="/playlists/create/start" first-line="Erstelle aus 300+ Übungen"
                    second-line="dein eigenes Workout!" icon-source="ic_badge_plus.svg"></cta-button>

        <!-- Meine Workouts -->
        <div v-else-if="!filterActive" class="section">
            <div class="section__title">
                <h4>Meine Workouts<span class="section__title__indicator">{{ userPlaylists.length }}</span></h4>
                <a href="/playlists/personal/">Alle anzeigen</a>
            </div>

            <PlaylistPersonalItem v-for="(up, idx) in sortedSlicedUserWorkouts"
                                  :inactive="idx > 0 && !premiumStatus.validTo" :key="up.uid"
                                  @startPlaylist="startUserPlaylist(up.uid)"
                                  @editPlaylistPersonalItem="editItem(up.uid, false)"
                                  @editPlaylistPersonalItemRestricted="editItem(up.uid, true)" :title="up.name"
                                  :number="up.items.length"></PlaylistPersonalItem>

            <router-link class="contentItem contentItem--button" to="/playlists/create/start">
                <div class="contentItem__icon">
                    <img src="@/assets/contentitem/ic_contentitem_add.svg" alt=""/>
                </div>
                <div class="contentItem__content">
                    <div class="contentItem__title">
                        Workout erstellen
                    </div>
                </div>
            </router-link>
        </div>

        <!-- Workout-Kategorien -->
        <div v-if="!filterActive" class="section">
            <div class="section__title">
                <h4>Workout-Kategorien</h4>
            </div>
            <div class="workout__categories" v-bind:class="categoriesVisible ? 'workout__categories--visible' : ''">
                <div class="workout__categories__wrapper">
                    <div v-for="category in categories" v-bind:key="category.id" class="workout__categories__item">
                        <router-link :to='"/trainieren?category_id=" + category.id'>
                            <img :src="'https://app.sportlerplus.com' + category.square_tile_image_url" alt="">
                        </router-link>
                        <div class="workout__categories__item__title">
                            {{ category.name }}
                        </div>
                    </div>
                </div>

                <div @click="showCategories()" class="workout__categories__button">
                    <img src="@/assets/ic_workout_categories_arrow.svg" alt="">
                    Alle Kategorien anzeigen
                </div>
            </div>
        </div>

        <!-- Challenge CTA Button -->
        <cta-button v-if="!filterActive" path="/fitnessplan/challenges" first-line="Mehrwöchige Trainingspläne"
                    second-line="Starte deine Challenges!" icon-source="ic_challenge_cta.svg"
                    class="extra__margin__cta"></cta-button>

        <!-- Alle Workouts -->
        <div class="">
            <div v-if="!filterActive" class="section__title">
                <h4>Alle Workouts</h4>
            </div>
            <template v-for="category in resultCategories">
            <span v-bind:key="-category.id" @click="handleCategoryClick(category)">
                <div class="card">
                <div v-if="displayPremiumBadge(category)" class="card__premium"><img src="@/assets/ic_20_premium.svg"
                                                                                     alt=""></div>
                
                <div class="card__title">
                    <div class="workout__item__badge" v-if="category.badge">{{ category.badge }}</div>
                    <div>{{ category.name }} Mix</div>
                </div>
                    <!-- <div class="card__subtitle">{{ category.subtitle }}</div> -->
                <img class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + category.image_url" alt=""/>
                </div>
            </span>
            </template>
            <template v-for="ti in filterResult">
                <WorkoutItem v-if="activityType(ti) == 'workout.WorkoutPage'" :workout="ti" v-bind:key="ti.id"
                             @showPremiumHint=showPremiumHint></WorkoutItem>
                <PlaylistItem v-else-if="activityType(ti) == 'exercise.PlaylistPage'" :playlist="ti" v-bind:key="ti.id"
                              @showPremiumHint=showPremiumHint></PlaylistItem>
            </template>
        </div>

        <EditPlaylistItemModal @playlistDeleted=playlistDeleted :userPlaylist="currentUserPlaylist"
                               name="editPlaylistItemModal" :restricted="restrictedEditModal"></EditPlaylistItemModal>
        <PremiumHintModal></PremiumHintModal>

        <!-- Filter Button -->
        <div @click="openWorkoutFilter"
             :class="filterActive ? 'floatingbutton floatingbutton--active' : 'floatingbutton'"><!-- TODO active -->
            <div class="floatingbutton__icon">
                <img src="@/assets/ic_16_filter.svg" alt="Filter">
            </div>
            Filtern
        </div>

        <WorkoutFilter @closeModal="dismissWorkoutFilter" ref="workoutFilter" @resetFilter="resetFilterResult"
                       @filterResult="applyFilterResult" :workouts="trainingItems"></WorkoutFilter>

        <ModalRoute></ModalRoute>
    </div>
</template>

<script>
import ModalRoute from "@/components/ModalRoute.vue";
import WorkoutItem from "@/components/WorkoutItem.vue";
import PlaylistItem from '@/components/PlaylistItem.vue';
import PremiumHintModal from '@/components/PremiumHintModal.vue';
import {analytics} from '@/firebase/index.js';
import PlaylistPersonalItem from "@/components/PlaylistPersonalItem.vue";
import EditPlaylistItemModal from "@/components/EditPlaylistItemModal.vue";
import WorkoutFilter from "@/components/WorkoutFilter.vue";
import CtaButton from "@/components/CtaButton.vue";
//import { backButtonUsed } from '@/router';

export default {
    name: 'Trainieren',
    components: {
        CtaButton,
        PremiumHintModal,
        PlaylistPersonalItem,
        EditPlaylistItemModal,
        WorkoutItem,
        PlaylistItem,
        ModalRoute,
        WorkoutFilter,
    },
    methods: {
        async init() {
            this.$store.dispatch('fetchEquipmentList');
            this.$store.dispatch('fetchCategories').then(() =>
                this.categories = this.$store.getters.getCategories
            );
            await this.$store.dispatch('fetchPlaylists').then(() => {
                this.playlists = this.$store.getters.getPlaylists;
                this.filterResult = this.trainingItemsExceptHidden;
                this.$nextTick(() => {
                    if (this.$route.query.category_id) {
                        this.filterCategory(parseInt(this.$route.query.category_id));
                    }
                });
            });
            await this.$store.dispatch('fetchWorkouts').then(() => {
                this.workouts = this.$store.getters.getWorkouts;
                this.filterResult = this.trainingItemsExceptHidden;
                this.$nextTick(() => {
                    if (this.$route.query.category_id) {
                        this.filterCategory(parseInt(this.$route.query.category_id));
                    }
                });
            });
            this.$store.dispatch('fetchAllUserPlaylists').then((res) => {
                this.userPlaylists = res;
            });
            this.fetchUserSportsTypes();

            if (this.$route.query.warmup) {
                analytics.logEvent('view_warmup_workout_overview');
                this.$nextTick(() => {
                    this.filterCategory(11);
                });
            } else {
                analytics.logEvent('view_workout_overview');
            }

        },
        handleCategoryClick(category) {
            if (category.premium_content && Object.keys(this.premiumStatus).length === 0) {
                this.$modal.show('premiumHint');
            } else {
                this.$router.push("/playlist/start?category_id=" + category.id);
            }
        },
        filterCategory(cat_id) {
            this.$refs.workoutFilter.setCategoryFilter(cat_id);
            this.filterResult = this.$refs.workoutFilter.filterWorkouts();
            this.filterActive = true;
            this.resultCategories = this.selectedCategories();
        },
        applyFilterResult(filteredItems) {
            this.filterResult = filteredItems;
            this.filterActive = this.filterResult.length != this.trainingItemsExceptHidden.length;
            //console.log("show moderated: " + this.$refs.workoutFilter.moderated );
            var selectedCoaches = this.$store.getters.getCoaches.filter(c => c._filter_by);
            this.displayCategoryResults = (selectedCoaches.length == 0 && this.$refs.workoutFilter.moderated != "yes");
            this.resultCategories = this.selectedCategories();
            this.$forceUpdate();
            this.scrollToTop();
        },
        selectedCategories() {
            if (!this.displayCategoryResults) {
                return [];
            }
            var cat = this.categories.filter(c => c._filter_by && c.mix_available);
            return cat;
        },
        resetFilterResult() {
            this.filterResult = this.trainingItemsExceptHidden;
            this.filterActive = false;
            this.scrollToTop();
            this.displayCategoryResults = true;
        },
        showPremiumHint() {
            this.$modal.show("premiumHint");
        },
        activityType(activity) {
            if (!activity) {
                return null;
            }
            if (activity.meta === undefined) {
                return "Category";
            } else {
                return activity.meta.type;
            }
        },
        showCategories() {
            this.categoriesVisible = true;
        },
        async fetchUserSportsTypes() {
            await this.$store.dispatch('fetchSportsTypes').then(() => {
                this.sportsTypes = this.$store.getters.getSportsTypes;
            });
            await this.$store.dispatch('fetchUserInformation').then((res) => {
                if (res.length != 0) {
                    this.userInformation = res[0];

                    if (this.userInformation && this.userInformation.sports_types) {
                        this.userSportsTypes = [...this.userInformation.sports_types];
                    }
                }
            });
            this.sportsTypes.filter(st => !st.user_selectable).forEach(st => this.userSportsTypes.push(st));
            this.userSportsTypes.forEach(ust => {
                let tempST = this.sportsTypes.find(st => st.id == ust.id);
                if (tempST) {
                    ust.image_url = tempST.image_url;
                }
            });
        },
        editItem(uid, restricted) {
            this.restrictedEditModal = restricted;
            let userPlaylist = this.userPlaylists.find(up => up.uid == uid);
            this.currentUserPlaylist = userPlaylist;
            this.$modal.show("editPlaylistItemModal");
        },
        startUserPlaylist(uid) {
            this.$router.push("/playlist/start?user_playlist_id=" + uid);
        },
        playlistDeleted(playlist) {
            this.$modal.hide("editPlaylistItemModal");
            this.userPlaylists = this.userPlaylists.filter(i => i.uid != playlist.uid);
        },
        openWorkoutFilter() {
            this.$refs.workoutFilter.collapse();
            this.$modal.show("workoutFilter");
        },

        dismissWorkoutFilter() {
            this.$modal.hide('workoutFilter');
        },
        displayPremiumBadge(cat) {
            if (cat.premium_content && Object.keys(this.premiumStatus).length == 0) {
                return true;
            }
            return false;
        },
        scrollToTop() {
            window.scrollTo({top: 0, behavior: 'smooth'})
        },
    },
    data() {
        return {
            categories: [],
            resultCategories: [],
            playlists: [],
            workouts: [],
            sportsTypes: [],
            userSportsTypes: [],
            userPlaylists: [],
            currentUserPlaylist: null,
            premiumHintText: null,
            userInformation: null,
            restrictedEditModal: false,
            categoriesVisible: false,
            filterResult: null,
            filterActive: false,
            displayCategoryResults: true,
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        trainingItems() {       
            var trainingItems = this.playlists.concat(this.workouts);
            return trainingItems.sort((a, b) => {
                var d1 = new Date(a.go_live_at);
                var d2 = new Date(b.go_live_at);
                return d2 - d1;
            });
        },
        trainingItemsExceptHidden() {
            return this.trainingItems.filter(el => !el.hide_from_overview);
        },
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
        sortedSlicedUserWorkouts() {
            var userWorkouts = [...this.userPlaylists];
            return userWorkouts.sort((b, a) => (a.creationDate.localeCompare(b.creationDate))).slice(0, 3);
        }
    },
    beforeRouteUpdate(to, from, next) {
        //if (backButtonUsed) {
        //    console.log("detected back button, no category filter applied");
        //    next();
        //    return;
        //}
        if (to.query.warmup) {
            this.filterCategory(11);
        } else if (to.query.category_id) {
            this.filterCategory(parseInt(to.query.category_id));
        } else {
            this.$refs.workoutFilter.resetFilter();
            this.resetFilterResult();
        }
        next();
    }
}
</script>

<style lang="scss">
.extra__margin__cta {
  margin-top: 32px;
}

.workout {
  &__categories {
    &__wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, calc((100% - 24px) / 3));
      justify-content: space-between;
      max-height: 240px;
      overflow: hidden;

      @media screen and (min-width: 350px) {
        max-height: 264px;
      }

      @media screen and (min-width: 375px) {
        max-height: 280px;
      }

      @media screen and (min-width: 390px) {
        max-height: 288px;
      }

      @media screen and (min-width: 414px) {
        max-height: 304px;
      }

      @media screen and (min-width: 450px) {
        max-height: 328px;
      }

      @media screen and (min-width: 520px) {
        max-height: 376px;
      }

      &:after {
        content: '';
        flex: auto;
      }

      .workout__categories--visible & {
        max-height: inherit;
      }
    }

    &__item {
      display: flex;
      position: relative;
      border-radius: 4px;
      height: 84px;
      margin-top: 12px;
      background: rgba($colorBlack, .02);
      overflow: hidden;

      &:nth-child(-n+3) {
        margin-top: 0;
      }

      @media screen and (min-width: 350px) {
        height: 96px;
      }

      @media screen and (min-width: 375px) {
        height: 104px;
      }

      @media screen and (min-width: 390px) {
        height: 108px;
      }

      @media screen and (min-width: 414px) {
        height: 116px;
      }

      @media screen and (min-width: 450px) {
        height: 128px;
      }

      @media screen and (min-width: 520px) {
        height: 152px;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        background: #ccc;
      }

      &__title {
        position: relative;
        z-index: 1;
        font-size: 11px;
        font-weight: 800;
        color: $colorWhite;
        letter-spacing: -0.32px;
        text-align: center;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.32);
        width: 100%;
        margin-top: auto;
        margin-bottom: 16px;
      }
    }

    &__button {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      width: 100%;
      background-image: linear-gradient(180deg, rgba(249, 249, 249, 0.92) 0%, #F9F9F9 100%);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: .33px;
      color: $colorPrimary;
      padding: 13px 0 14px;
      margin-top: -48px;
      cursor: pointer;

      img {
        margin-right: 8px;
      }

      .workout__categories--visible & {
        display: none;
      }
    }
  }
}

.workouts {
  small {
    display: inline-block;
    line-height: 20px;
    margin-top: 4px;
    margin-bottom: 27px;
  }

  &__filter {
    padding-top: 0 !important;
  }
}

.workout__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 160px;
  border-radius: 4px;
  overflow: hidden;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__premium {
    position: absolute;
    z-index: 2;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    -webkit-transform: rotate(360deg);
    border-style: solid;
    border-width: 58px 58px 0 0;
    border-color: $colorPrimary transparent transparent transparent;

    img {
      position: absolute;
      top: -50px;
      left: 8px;
      width: 20px;
      height: 20px;
    }
  }

  &__meta {
    display: flex;
    justify-content: flex-end;
    margin: 16px 14px 16px auto;

    &__icon {
      position: relative;
      width: 32px;
      height: 32px;
      margin-left: 4px;

      span {
        position: absolute;
        left: 0;
        top: 6px;
        width: 100%;
        text-align: center;
        padding-left: 2px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        color: $colorPrimary;
        letter-spacing: -0.29px;
      }
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto 0 12px;
    padding: 0 16px;
    color: $colorWhite;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 24px;
    text-shadow: 0 2px 16px rgba($colorBlack, 0.48);
  }

  &__badge {
    display: inline-flex;
    font-size: 11px;
    line-height: 11px;
    font-weight: 700;
    letter-spacing: -0.13px;
    text-transform: uppercase;
    padding: 4px 5px 3px 6px;
    margin-bottom: 5px;
    border-radius: 2px;
    color: $colorWhite;
    background: $colorPrimary;
  }
}

/* Slider Coloring */
:root {
  --slider-bg: rgba(30, 29, 31, .08);
  --slider-connect-bg: #FF6900;
  --slider-tooltip-bg: #FF6900;
  --slider-height: 4px;
  --slider-handle-width: 24px;
  --slider-handle-height: 24px;
  --slider-handle-shadow: 0 8px 16px 0 rgba(30, 29, 31, .08), 0 0 0 2px rgba(30, 29, 31, .08);
  --slider-handle-shadow-active: 0 8px 16px 0 rgba(30, 29, 31, .08), 0 0 0 2px rgba(30, 29, 31, .16);
  --slider-handle-ring-width: 0;
}

</style>

<style lang="scss" scoped>

.filter .button {
  font-size: 14px !important;
  padding: 10px 8px !important;
  margin-bottom: 12px;
  font-weight: 600 !important;
}
</style>
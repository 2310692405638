<template>
    <v-app-bar app color="white" height="auto" flat class="noselect">
        
        <div class="v-toolbar__content__left">
            <v-btn v-if="showShopIcon" v-ripple="false" height="auto" text target="_blank" href="https://shop.sportlerplus.de/?utm_source=app&utm_medium=top-left&utm_campaign=sportlerplus+referrals"><img src="@/assets/ic_nav_shop.svg"></v-btn>
            <v-btn v-else-if="showBackButton" v-ripple="false" height="auto" text @click="goBack">
                <img v-if="isBlackBackground" src="@/assets/ic_nav_back_white.svg">
                <img v-else src="@/assets/ic_nav_back.svg">
            </v-btn>
        </div>
        
        <img v-if="useLogoAsTitle" class="v-toolbar__logo" src="@/assets/logo_ausgeschrieben.png" />
        <v-toolbar-title v-else>{{navBarTitle}}</v-toolbar-title>

        <div class="v-toolbar__content__right">
            <v-btn v-if="isAuthenticated && isArticle" v-ripple="false" height="auto" text >
                <img src="@/assets/ic_nav_share.svg" alt="" @click=shareArticle>
            </v-btn>
            <v-btn v-if="isAuthenticated && isArticle" v-ripple="false" height="auto" text >
                <img v-if="isFavourite" src="@/assets/ic_nav_star-active.svg" @click=removeArticleLike>
                <img v-else src="@/assets/ic_nav_star.svg" @click=likeArticle>
            </v-btn>
            <router-link v-if="skipRegister" class="skip" to="/onboarding-step4">Skip</router-link>
            <v-btn v-if="showProfileButton" v-ripple="false" height="auto" text href="/profile">
                <img src="@/assets/ic_nav_profile.svg">
            </v-btn>
        </div>

        <div v-if="progressBar" class="v-toolbar__progress">
            <div class="v-toolbar__progress__bar" :style="'width: ' + progressBar + '%'"></div>
        </div>
    </v-app-bar>
</template>

<script>
//import moment from 'moment';
import {toIsoString} from '@/sportlerplus-helper';

export default {
    name: 'AppTopNavigation',
    data() {
        return {
            appTitle: "SportlerPlus",
            isFavourite: false,
            category: null,
        };
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters.isAuthenticated;
        },
        useLogoAsTitle() {
            if (this.$route.meta.useLogoAsTitle) {
                return true;
            }
            return false;
        },
        navBarTitle() {
            if (this.$route.query.navbartitle) {
                return this.$route.query.navbartitle;
            }
            /*if (this.isFitnessPlan && this.$route.query.date) {
                let d = moment(this.$route.query.date).locale("de").format('D. MMMM YYYY');
                return d;
            }*/
            if (this.category) { return this.category.name; }
            return this.$route.meta.title || this.appTitle;
        },
        isArticle() {
            return (this.$route.path.startsWith("/articles/") && this.$route.path.length > 10);
        },
        showFitnessPlanCreateButton() {
            return (this.$route.path == "/fitnessplan") || (this.$route.path == "/fitnessplan/calendar");
        },
        isFitnessPlan() {
            return (this.$route.path.startsWith("/fitnessplan"));
        },
        showProfileButton() {
            return (this.$route.path == "/") || (this.$route.path == "/fitnessplan") || (this.$route.path == "/trainieren") || (this.$route.path == "/articles") || (this.$route.path == "/statistiken");
        },
        showBackButton() {
            return this.$route.meta.showBackButton || this.$route.query.category_id || false;
            //return this.$route.path.startsWith("/articles/") ||
            //    this.$route.path.startsWith("/playlists/category/");
        },
        showShopIcon() {
            return this.$route.name == "Home";
        },
        progressBar() {
            return this.$route.meta.progressBar || false;
        },
        skipRegister() {
            return this.$route.meta.skipRegister || false;
        },
        selectedDate() {
            if (this.$route.query.date) {
                return this.$route.query.date;
            }
            else {
                return toIsoString(new Date()).split("T")[0];
            }
        },
        isBlackBackground() {
            if (this.$route.meta.blackBackground) {
                return true;
            }
            return false;
        },
    },
    watch: {
		$route: {
		/* eslint-disable no-unused-vars */
		immediate: true,
        handler:
            function(newVal, oldVal) {
                this.category = null;
                if (newVal.path.startsWith("/articles/") && newVal.path.length > 10) {
                    let articleId = parseInt(newVal.path.substring(10));
                    if (!this.$store.getters.isAuthenticated) {
                        this.isFavourite = false;
                        return;
                    }
                    this.$store.dispatch('checkArticleFavourite', articleId).then((res) => {
                        this.isFavourite = res;
                    });  
                }
                else if (newVal.path.startsWith("/playlists/category/")) {
                    let categories = this.$store.getters.getCategories;
                    let categoryId = parseInt(newVal.path.substring(20));
                    this.category = categories.find(i => i.id == categoryId);
                }
            }
		}
	},
    methods: {
        shareArticle() {
            this.$emit('shareArticle')
        },
        likeArticle() {
            this.isFavourite = true;
            this.$emit('likeArticle');
        },
        removeArticleLike() {
            this.isFavourite = false;
            this.$emit('removeArticleLike');
        },
        goBack() {
            if (window.history.length > 1) {
                this.$router.go(-1);
            }
            else {
                /* user possibly clicked deep link to article */
                if (this.isArticle) {
                    this.$router.push("/magazine");
                }
                else {
                    this.$router.push("/");
                }
            }
        }
    },
};
</script>

<style lang="scss">
    header {
        position: relative;
        flex: 0 !important;

        .bg-white & {
            position: relative !important;
            margin-bottom: -48px;
        }

        .v-toolbar {

            &__content {
                display: flex;
                flex-direction: row;
                background: $colorWhite !important;
                padding: 12px 20px !important;

                .bg-black & {
                    background: $colorBlack !important;
                }

                &__left, &__right {
                    display: flex;
                    flex: 1;
                    min-width: -webkit-min-content; /* Workaround to Chrome bug */
                }

                &__right {
                    justify-content: flex-end;

                    img:last-child {
                        margin-left: 8px;
                    }
                }
            }
            
            &__title {
                text-align: center;
                font-size: 17px;
                font-weight: 700;
                color: $colorBlack;
                justify-content: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .bg-black & {
                    color: $colorWhite;
                }
            }

            &__logo {
                height: 30px;
                margin: -3px 0 -2px;
            }

            &__progress {
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 2px;
                background: rgba($colorBlack, .08);

                &__bar {
                    background: $colorPrimary;
                    height: 2px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        .skip {
            font-size: 14px;
            font-weight: 600;
            color: rgba($colorBlack, .48) !important;
            text-decoration: none !important;
        }

        .v-btn {
            padding: 0 !important;
            min-width: inherit !important;

            &:before {
                background: none !important;
            }

            img {
                width: 24px;
                height: 24px;
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 26px;
            border-radius: 50%;
            box-shadow: 0 0 16px 0 rgba(0,0,0,0.08);

            .bg-white &, .bg-black & {
                display: none;
            }
        }

    }
</style>

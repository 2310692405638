<template>
    <svg height="40px" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 385.6 75.1" style="enable-background:new 0 0 385.6 75.1;" xml:space="preserve">

    <g>
        <g>
            <polygon class="st0" points="323.5,32.8 333.6,32.8 333.6,22.6 339.3,19 339.3,9.7 339.3,9.7 314.3,24.1 314.3,38.5 319.8,38.5 		
                "/>
            <polygon class="st0" points="358.8,38.5 355.1,44.3 345,44.3 345,54.4 339.3,58 339.3,58 339.3,67.3 364.2,52.9 364.2,38.5 
                358.8,38.5 		"/>
        </g>
        <g>
            <polygon class="st0" points="339.3,9.7 339.3,19 339.3,19 345,22.6 345,32.8 355.1,32.8 358.8,38.5 364.2,38.5 364.2,24.1 
                339.3,9.7 339.3,9.7 		"/>
            <polygon class="st0" points="339.3,58 333.6,54.4 333.6,44.3 323.5,44.3 319.8,38.5 319.8,38.5 314.3,38.5 314.3,52.9 339.3,67.3 
                339.3,67.3 		"/>
        </g>
    </g>
    <g>
        <path class="st1" d="M23.9,52.7c-3,0-4.4-0.7-4.4-4.2v-4.4h4.8v2.8c0,1.2,0.2,1.2,1.2,1.2h7.8c1,0,1.2,0,1.2-1.2v-5.1
            c0-1.2-0.2-1.2-1.2-1.2h-9.5c-3,0-4.4-0.7-4.4-4.2v-8.3c0-3.6,1.4-4.2,4.4-4.2h11.1c3,0,4.4,0.7,4.4,4.2v4.4h-4.8v-2.8
            c0-1.2-0.2-1.2-1.2-1.2h-7.8c-1,0-1.2,0-1.2,1.2v5.2c0,1.2,0.2,1.2,1.2,1.2h9.5c3,0,4.4,0.7,4.4,4.2v8.2c0,3.6-1.4,4.2-4.4,4.2
            H23.9z"/>
        <path class="st1" d="M44.2,52.7V23.8h15.1c3,0,4.4,0.7,4.4,4.2v9.3c0,3.6-1.4,4.2-4.4,4.2H49v11.1H44.2z M58.9,29.6
            c0-1.2-0.2-1.2-1.2-1.2H49V37h8.7c1.1,0,1.2,0,1.2-1.2V29.6z"/>
        <path class="st1" d="M72.2,52.7c-3,0-4.4-0.7-4.4-4.2V28.1c0-3.6,1.4-4.2,4.4-4.2h12.3c3,0,4.4,0.7,4.4,4.2v20.4
            c0,3.6-1.4,4.2-4.4,4.2H72.2z M84.1,29.6c0-1.2-0.2-1.2-1.2-1.2h-9c-1,0-1.2,0-1.2,1.2v17.3c0,1.2,0.2,1.2,1.2,1.2h9
            c1.1,0,1.2,0,1.2-1.2V29.6z"/>
        <path class="st1" d="M108.5,52.7l-6.2-11.1h-3.4v11.1h-4.8V23.8h15.1c3,0,4.4,0.7,4.4,4.2v9.3c0,3.6-1.4,4.2-4.4,4.2h-1.9l6.2,11.1
            H108.5z M108.8,29.6c0-1.2-0.2-1.2-1.2-1.2h-8.7V37h8.7c1.1,0,1.2,0,1.2-1.2V29.6z"/>
        <path class="st1" d="M123.5,52.7V28.4H116v-4.6h19.8v4.6h-7.5v24.3H123.5z"/>
        <path class="st1" d="M138.9,52.7V23.8h4.8v24.3h12.4v4.6H138.9z"/>
        <path class="st1" d="M159.1,52.7V23.8h18v4.6h-13.2v7.5h10.6v4.6h-10.6v7.6h13.2v4.6H159.1z"/>
        <path class="st1" d="M195.6,52.7l-6.2-11.1H186v11.1h-4.8V23.8h15.1c3,0,4.4,0.7,4.4,4.2v9.3c0,3.6-1.4,4.2-4.4,4.2h-1.9l6.2,11.1
            H195.6z M195.9,29.6c0-1.2-0.2-1.2-1.2-1.2H186V37h8.7c1.1,0,1.2,0,1.2-1.2V29.6z"/>
    </g>
    <g>
        <path class="st1" d="M214.5,52.7V23.8h15.1c3,0,4.4,0.7,4.4,4.2v9.3c0,3.6-1.4,4.2-4.4,4.2h-10.3v11.1H214.5z M229.2,29.6
            c0-1.2-0.2-1.2-1.2-1.2h-8.7V37h8.7c1.1,0,1.2,0,1.2-1.2V29.6z"/>
        <path class="st1" d="M238.2,52.7V23.8h4.8v24.3h12.4v4.6H238.2z"/>
        <path class="st1" d="M262.8,52.7c-3,0-4.4-0.7-4.4-4.2V23.8h4.8v23.1c0,1.2,0.2,1.2,1.2,1.2h9c1.1,0,1.2,0,1.2-1.2V23.8h4.8v24.6
            c0,3.6-1.4,4.2-4.4,4.2H262.8z"/>
        <path class="st1" d="M288.7,52.7c-3,0-4.4-0.7-4.4-4.2v-4.4h4.8v2.8c0,1.2,0.2,1.2,1.2,1.2h7.8c1,0,1.2,0,1.2-1.2v-5.1
            c0-1.2-0.2-1.2-1.2-1.2h-9.5c-3,0-4.4-0.7-4.4-4.2v-8.3c0-3.6,1.4-4.2,4.4-4.2h11.1c3,0,4.4,0.7,4.4,4.2v4.4h-4.8v-2.8
            c0-1.2-0.2-1.2-1.2-1.2h-7.8c-1,0-1.2,0-1.2,1.2v5.2c0,1.2,0.2,1.2,1.2,1.2h9.5c3,0,4.4,0.7,4.4,4.2v8.2c0,3.6-1.4,4.2-4.4,4.2
            H288.7z"/>
    </g>
    </svg>
</template>

<script>
    export default {
        name: 'SportlerPlusLogo',
    }
</script>


<style lang="scss">
    .st0{fill:#FF6900;}
    .st1{
        fill: $colorBlack;
        @media screen and (min-width: 768px) {
            .landingpage--dark & {
                fill: $colorWhite;
            }
        }
    }
</style>
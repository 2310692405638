<template>
    <v-footer app color="white" class="noselect">
        <v-btn v-ripple="false" height="auto" text to="/"><img src="@/assets/ic_nav_home.svg">Home</v-btn>
		<v-btn v-if="premiumUser" v-ripple="false" height="auto" text to="/fitnessplan"><img src="@/assets/ic_nav_planen.svg">Planen</v-btn>
		<v-btn v-else v-ripple="false" height="auto" text @click=handleFitnessPlanClick><img src="@/assets/ic_nav_planen.svg">Planen</v-btn>
		<v-btn v-ripple="false" height="auto" text to="/trainieren"><img src="@/assets/ic_nav_trainieren.svg">Trainieren</v-btn>
		<v-btn v-ripple="false" height="auto" text to="/articles"><img src="@/assets/ic_nav_magazin.svg">Magazin</v-btn>
        <v-btn v-ripple="false" height="auto" text to="/statistiken"><img src="@/assets/ic_nav_activity.svg">Aktivität</v-btn>
    </v-footer>
</template>

<script>
export default {
    name: 'AppBottomNavigation',
	computed: {
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
		premiumUser() {
			return  Object.keys(this.premiumStatus).length !== 0;
		}
	},
	methods: {
		handleFitnessPlanClick() {
            if (Object.keys(this.premiumStatus).length === 0) {
				//this.$modal.show(this.modalTitle);
				this.$emit('showPremiumHint');
            }
            else {
				this.$router.push("/fitnessplan");
			}
		}
	}
};
</script>

<style lang="scss">
	.v-footer {
		box-shadow: 0 0 16px 0 rgba(0,0,0,0.08), inset 0 1px 0 0 rgba(233,233,233,0.50) !important;
		padding: 12px 0 !important;
		justify-content: center;

		.v-btn {
			font-size: 8.5px;
			letter-spacing: 0.4px;
			font-weight: 700;
			color: $colorBlack;
			opacity: .4;
			flex: 1;
			min-width: inherit !important;
			padding: 0 !important;

			&--active {
				opacity: 1;
			}

			&:before {
				background: none;
			}

			span {
				flex-direction: column;
			}

			img {
				width: 24px;
				height: 24px;
				margin-bottom: 4px;
			}
		}
	}
</style>
